import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const getParams = () => {
  const params = {};

  window.location.search.substr(1).split('&').forEach((item) => {
    const tmp = item.split('=');

    params[tmp[0]] = decodeURIComponent(tmp[1]);
  });
  return (params);
}

export { useQuery, getParams };
