import request from 'utils/request';
import auth from 'utils/auth';

const user = auth.getUser();

const getWithoutAuth = (customParam, handleSuccess, handleFailure) => {
  request.getDataInline(
    `${request.API_ENDPOINT_URL}/clients`,
    { ...customParam },
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getByAuthorId = (authorId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/clients`,
    { authorId },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getById = (clientId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/clients/${clientId}`,
    {},
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const Client = {
  getByAuthorId,
  getWithoutAuth,
  getById
};

export default Client;
