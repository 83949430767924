/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react';

/**
 * Handle's the Photo object
 * @param {Number} index - Picture's index in the pictureList
 * @param {Object} pictureSize - Picture's height and width
 * @param {Object} photo - Photo object
 * @param {Callback} onPhotoUpdate - Callback to update photo on crop
 * @param {Callback} onClick - Callback to trigger the Edit Menu
 */
const PhotoObject = (props) => {

  const drawImage = () => {
    const source = {
      offset: {
        y: props.photo.cropInfo.croppedAreaPixels.y,
        x: props.photo.cropInfo.croppedAreaPixels.x
      },
      size: {
        height: props.photo.cropInfo.croppedAreaPixels.height,
        width: props.photo.cropInfo.croppedAreaPixels.width
      }
    };
    const destination = {
      offset: {
        y: 0,
        x: props.photo.effects?.isMirrored ? props.pictureSize.width * -1 : 0
      },
      size: {
        height: props.pictureSize.height,
        width: props.pictureSize.width
      }
    };

    if (props.photo.effects?.isMirrored) {
      props.photo.drawingContext.save();
      props.photo.drawingContext.scale(-1, 1);
    }
    props.photo.drawingContext.drawImage(
      props.photo.drawing,
      source.offset.x,
      source.offset.y,
      source.size.width,
      source.size.height,
      destination.offset.x,
      destination.offset.y,
      destination.size.width,
      destination.size.height
    );
    if (props.photo.effects?.isMirrored) {
      props.photo.drawingContext.restore();
    }
  }

  const createDrawing = (imageRef) => {
    if (!props.photo.drawing) {
      props.photo.drawing = imageRef;
      props.onPhotoUpdate(props.photo);
    }
  };

  const createDrawingContext = (canvas) => {
    if (canvas) {
      props.photo.drawingContext = canvas.getContext('2d');
    }
  };

  useEffect(() => {
    if (props.photo.cropInfo) {
      drawImage();
    }
  });

  return (
    <div style={{
      height: props.pictureSize.height,
      width: props.pictureSize.width,
      position: 'absolute',
      overflow: 'hidden',
      zIndex: '1'
    }}>
      <img
        id={`photo-${props.index}`}
        ref={imageRef => createDrawing(imageRef)}
        src={props.photo.photo}
        alt={`photo-${props.index}`}
        style={{ display: 'none' }}
      />
      <canvas
        id={`photo-${props.index}`}
        ref={(canvas) => createDrawingContext(canvas)}
        height={props.pictureSize.height}
        width={props.pictureSize.width}
        onClick={props.onClick}
      />
    </div>
  );
};

export default PhotoObject;
