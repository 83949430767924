import React, { useState } from 'react'
import { Card, Row, Col, Alert } from 'antd';
import { Form, Input } from 'antd';
import Button from 'components/Button';
import { THEME_COLOR } from 'configs/AppConfig';
import auth from 'utils/auth';
import ForgottenPassword from '../ForgottenPassword';

const backgroundStyle = {
  backgroundColor: THEME_COLOR.lightest
};

/**
 * Login
 * @param {Callback} onLogin - Callback to handle user login
 */
const Login = (props) => {
  const [alert, setAlert] = useState({ show: false, type: 'info', message: '' });
  const [loading, setLoading] = useState(false);
  const [pwdIsForgotten, setPwdIsForgotten] = useState(false);
  const alertStyle = { display: `${ alert.show ? '' : 'none' }` };

  if (pwdIsForgotten) {
    return (<ForgottenPassword onComeback={() => setPwdIsForgotten(false)} />);
  }

  const handleLoginSuccess = (result) => {
    const { jwt, user } = result;

    setLoading(false);
    props.onLogin(jwt, user);
    return (true);
  };

  const handleLoginFailure = (error) => {
    const message = {
      DEFAULT: 'Mot de passe incorrect ou adresse email non-validée'
    };

    setAlert({ show: true, type: 'error', message: message.DEFAULT });
    setTimeout(() => setAlert({ show: false, type: 'info', message: '' }), 5000);
    setLoading(false);
    return (false);
  }

  const verifyLogin = (data) => {
    setLoading(true);
    auth.login(data.email, data.password, handleLoginSuccess, handleLoginFailure);
  };

  return (
    <div className='h-100' style={backgroundStyle}>
      <div className='container d-flex flex-column justify-content-center h-100'>
        <Row justify='center'>
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className='my-4'>
                <Alert message={alert.message} type='error' showIcon style={alertStyle} />
                <div className='text-center'>
                  <img className='img-fluid' src='/img/logo.png' alt='' style={{ maxWidth: '100px' }} />
                  <p>Pas encore de compte ? <a href='/auth/signup'>inscrivez-vous</a></p>
                </div>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Form layout='vertical' name='login-form' onFinish={verifyLogin}>
                      <Form.Item name='email' label='Email'>
                        <Input placeholder='prenom.nom@email.com' />
                      </Form.Item>
                      <Form.Item name='password' label={'Mot de passe'}>
                        <Input.Password placeholder='*****' />
                      </Form.Item>
                      <p style={{ color: THEME_COLOR.default, cursor: 'pointer' }} onClick={() => setPwdIsForgotten(true)}>Mot de passe oublié ?</p>
                      <Form.Item>
                        <Button type='primary' htmlType='submit' loading={loading} block>
                          Se connecter
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
