import React, { useState, useEffect } from 'react';
import { Card, Spin, Modal } from 'antd';
import ContextUploadLink from 'components/ContextUploadLink';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import ShootingList from './ShootingList';
import ShootingAdd from './ShootingAdd';
import ShootingView from './ShootingView';
import { LoadingContainer, Header, LeftHeader } from './ClientView.module.css';

const state = {
  LIST: 'LIST',
  VIEW: 'VIEW',
  ADD: 'ADD'
};

/**
 * ClientHeader
 * @param {Object} client - Current client
 * @param {Integer} shootingId - Id of the current shooting
 * @param {Enum} currentState - Current state
 * @param {Callback} onModify - Callback to handle client modification
 * @param {Callback} onShootingCreation - Callback to handle creation of a shooting
 * @param {Callback} onBack - Callback to handle the 'revenir au client' click
 * @param {Callback} onClientDelete - Callback to handle client deletion
 * @param {Callback} onShootingDelete - Callback to handle shooting deletion
 */
const ClientHeader = (props) => {
  return (
    <div>
      <div className={Header}>
        <div className={LeftHeader}>
          <h1>{props.client.name}</h1>
          <span style={{ width: '16px' }}></span>
        </div>
        <div>
          {
            (props.shootingId === null && props.currentState !== state.ADD) &&
              <>
                <Button
                  type='link'
                  style={{
                    marginRight: '8px',
                    color: '#F47174'
                  }}
                  onClick={() => props.onClientDelete()}
                >
                  Supprimer
                </Button>
                <Button
                  type='default'
                  style={{
                    marginRight: '8px'
                  }}
                  onClick={props.onModify}
                >
                  Modifier
                </Button>
              </>
          }
          {
            props.currentState !== state.ADD &&
              (
                props.shootingId === null ?
                  <Button type='primary' onClick={props.onShootingCreation}>Ajouter une projection</Button>
                :
                  <>
                    <Button type='link' style={{ color: '#F47174' }} onClick={() => props.onShootingDelete()}>Supprimer</Button>
                    <Button type='link' onClick={props.onBack}>Revenir au client</Button>
                  </>
              )
          }
        </div>
      </div>
      <div>
        <p>
          {
            props.client.contact?.phone &&
              <>
                { props.client.contact?.phone }
                <br />
              </>
          }
          {
            props.client.contact?.email &&
              <>
                { props.client.contact?.email }
                <br />
              </>
          }
          <ContextUploadLink client={props.client} />
          <br />
        </p>
      </div>
    </div>
  );
};

/**
 * ClientView
 * @param {Object} user - Current user
 * @param {String} clientId - Id of the client to view
 * @param {Callback} onModify - Callback to handle client modification
 * @param {Callback} onDelete - Callback to handle client deletion
 */
const ClientView = (props) => {
  const [currentState, setCurrentState] = useState(state.LIST);
  const [shootingId, setShootingId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState({ id: props.clientId });

  const backToClient = () => {
    setShootingId(null);
    setCurrentState(state.LIST);
  };

  const handleImageAccessSuccess = (result) => {
    const imageListId = result.map((item) => item.id);

    imageListId.forEach((imageId) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/images/${imageId}`, props.user.jwt, () => {}, () => {});
    });
  };

  const handleImageAccessFailure = (error) => {
    appLogs.logError({ function: 'handleImageAccessFailure', component: 'ClientView', error });
  };

  const handleOrderAccessSuccess = (result) => {
    const orderListId = result.map((item) => item.id);

    orderListId.forEach((orderId) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/orders/${orderId}`, props.user.jwt, () => {}, () => {});
    });
  };

  const handleOrderAccessFailure = (error) => {
    appLogs.logError({ function: 'handleOrderAccessFailure', component: 'ClientView', error });
  };

  const handleSceneAccessSuccess = (result) => {
    const sceneListId = result.map((item) => item.id);

    sceneListId.forEach((sceneId) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/scenes/${sceneId}`, props.user.jwt, () => {}, () => {});
    });
  };

  const handleSceneAccessFailure = (error) => {
    appLogs.logError({ function: 'handleSceneAccessFailure', component: 'ClientView', error });
  };

  const handleShootingDeleteSuccess = () => {
    notification.success('Félicitations !', 'La projection a bien été supprimée');
    backToClient();
  };

  const handleShootingDeleteFailure = () => {
    notification.error('Impossible de supprimer le shooting', 'Veuillez ré-essayer plus tard');
  };

  const deleteShooting = () => {
    request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/shootings/${shootingId}`, props.user.jwt, handleShootingDeleteSuccess, handleShootingDeleteFailure);
    request.getDataWithAuthInline(`${request.API_ENDPOINT_URL}/scenes`, { shootingId }, props.user.jwt, handleSceneAccessSuccess, handleSceneAccessFailure);
    request.getDataWithAuthInline(`${request.API_ENDPOINT_URL}/orders`, { shootingId }, props.user.jwt, handleOrderAccessSuccess, handleOrderAccessFailure);
    request.getDataWithAuthInline(`${request.API_ENDPOINT_URL}/images`, { shootingId }, props.user.jwt, handleImageAccessSuccess, handleImageAccessFailure);
  };

  const confirmShootingDelete = () => {
    Modal.confirm({
      title: 'Supprimer cette projection',
      content: 'Etes-vous sûr de vouloir supprimer cette projection, ainsi que toutes ses commandes associées ?',
      okText: 'Oui, supprimer',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        deleteShooting();
      }
    });
  };

  const onShootingSelectionFromList = (shootingId) => {
    window.location.pathname = `/mes-clients/${props.clientId}/${shootingId}`;
  };

  const returnToShootingList = () => {
    window.location.pathname = `/mes-clients/${props.clientId}`;
  };

  const onShootingCreation = () => {
    setCurrentState(state.ADD);
  }

  const handleShootingAccessSuccess = (result) => {
    if (result.clientId === props.clientId) {
      setShootingId(result.id);
      setCurrentState(state.VIEW);
    }
    setLoading(false);
  };

  const handleShootingAccessFailure = (error) => {
    appLogs.logError({ function: 'handleShootingAccessFailure', error });
    returnToShootingList();
  };

  const onShootingSelection = (shootingId) => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/shootings/${shootingId}`, {}, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleShootingAccessFailure(result));
        }
        return (handleShootingAccessSuccess(result));
      })
      .catch((error) => handleShootingAccessFailure(error));
  };

  const handleClientAccessSuccess = (result) => {
    setClient(result);
  };

  const handleClientAccessFailure = (error) => {
    appLogs.logError({ function: 'handleClientAccessFailure', error });
    setLoading(false);
  };

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const step = {
      PAGE_NAME: 1,
      CLIENT_ID: 2,
      SHOOTING_ID: 3
    };
    let shootingId = 0;

    setLoading(true);
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/clients/${props.clientId}`, {}, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientAccessFailure(result));
        }
        return (handleClientAccessSuccess(result));
      })
      .catch((error) => handleClientAccessFailure(error));
    if (path[step.PAGE_NAME] === 'mes-clients' && path[step.SHOOTING_ID]) {
      shootingId = parseInt(path[step.SHOOTING_ID]);
      if (isNaN(shootingId)) {
        return (returnToShootingList());
      }
      return (onShootingSelection(shootingId));
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className={LoadingContainer}>
        <Spin />
      </div>
    );
  }

  return (
    <Card>
      <ClientHeader
        client={client}
        shootingId={shootingId}
        currentState={currentState}
        onModify={() => props.onModify(props.clientId)}
        onShootingCreation={onShootingCreation}
        onBack={() => backToClient()}
        onClientDelete={() => props.onDelete(props.clientId)}
        onShootingDelete={() => confirmShootingDelete()}
      />
      {
        currentState === state.LIST &&
          <ShootingList
            user={props.user}
            client={client}
            onShootingCreation={onShootingCreation}
            onShootingSelection={(shootingId) => onShootingSelectionFromList(shootingId)}
          />
      }
      {
        currentState === state.ADD &&
          <ShootingAdd
            user={props.user}
            clientId={props.clientId}
            onShootingCreation={() => setCurrentState(state.LIST)}
            onCancel={() => setCurrentState(state.LIST)}
          />
      }
      {
        currentState === state.VIEW &&
          <ShootingView
            user={props.user}
            clientId={props.clientId}
            shootingId={shootingId}
            client={client}
            onDelete={() => backToClient()}
          />
      }
    </Card>
  );
};

export default ClientView;
