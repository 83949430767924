import React from 'react';
import { Form, Row, Col } from 'antd';

const Custom = (props) => {
  return (
    <Row>
      <Col span={8}>
        <Form.Item label='Description'>
          <p>{ props.product.specs.description }</p>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={`Prix d'achat`}>
          <p>{ props.product.specs.price.buy } €</p>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label='Prix de vente'>
          <p>{ props.product.specs.price.sell } €</p>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Custom;
