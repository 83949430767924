const event = {
  DRAG_START: 'dragStart',
  DRAG_STOP: 'dragStop'
};

const dataType = {
  CONTEXT: 'context',
  FRAME: 'frame',
	COLLECTION: 'collection',
  PHOTO: 'photo'
};

const setData = (event, dataType, data) => {
  event.dataTransfer.setData(dataType, JSON.stringify(data));
};

const getData = (event, dataType) => {
  let data = {};

  try {
    data = JSON.parse(event.dataTransfer.getData(dataType));
  } catch (error) {
    data = null;
  }
  return (data);
};

const allowDrop = (event) => {
  event.preventDefault();
}

const drag = { event, dataType, setData, getData, allowDrop };

export default drag;
