import React, { useState } from 'react';
import { Upload, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import Music from 'utils/Music';

/**
 * MusicUploader
 * @param {Callback} onMusicUpload - Callback to handle music upload
 */
const MusicUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const increaseProgress = (value, duration) => {
    const newProgress = value + 1;

    setProgress(newProgress);
    if (newProgress < 100) {
      setTimeout(
        () => increaseProgress(newProgress, duration),
        duration * 5
      );
    }
  };

  const launchProgress = (duration) => {
    setProgress(0);
    increaseProgress(0, duration);
  };

  const handleMusicUploadSuccess = (result, fileName, duration) => {
    setLoading(false);
    props.onMusicUpload(result.data[0].url, fileName, duration);
  };

  const handleMusicUploadFailure = (error) => {
    setLoading(false);
    appLogs.logError({ function: 'handleMusicUploadFailure', error });
    notification.error('Impossible d\'ajouter votre musique', 'Veuillez réessayer avec un autre fichier');
  };

  const uploadMusic = async (file) => {
    const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
    const duration = await Music.getAudioDuration(file);

    console.log('> file : ', file);
    console.log('> duration : ', duration);
    setLoading(true);
    launchProgress(duration);
    Music.uploadMusic(
      file,
      (result) => handleMusicUploadSuccess(result, fileName, duration),
      (error) => handleMusicUploadFailure(error)
    );
  };

  return (
    <Upload
      name='file'
      showUploadList={false}
      beforeUpload={(file) => uploadMusic(file)}
    >
      <Button
        type='default'
        icon={<UploadOutlined />}
        loading={loading}
      >
        Ajouter ma Musique
      </Button>
      {
        loading &&
          <Progress percent={progress} status="active" />
      }
    </Upload>
  );
};

export default MusicUploader;
