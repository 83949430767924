import request from 'utils/request';
import auth from 'utils/auth';

const get = (handleSuccess, handleFailure) => {
  const user = auth.getUser();

  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/subscriptions`,
    { email: user.usr.email },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const subscription = { get };

export default subscription;
