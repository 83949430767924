import React, { useState } from 'react';
import { Modal, Row, Col, InputNumber, Alert } from 'antd';
import Button from 'components/Button';
import Profile from 'controllers/Profile';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';

const defaultMargin = {
  Laminamarc: 3,
  OneLabPro: 3
};

/**
 * MarginConfigLine
 * @param {Integer} currentMargin - Current margin for this profile
 * @param {String} supplierName - Current supplier
 * @param {Callback} onMarginUpdate - Callback to handle margin update
 */
const MarginConfigLine = (props) => {
  const [currentMargin, setCurrentMargin] = useState(props.currentMargin ? props.currentMargin : defaultMargin[props.supplierName]);

  const handleMarginChange = (value) => {
    if (isNaN(value)) {
      return (false);
    }
    setCurrentMargin(value);
    props.onMarginUpdate(value);
  };

  return (
    <div>
      <p><b>Quel est votre marge sur les produits { props.supplierName } ?</b></p>
      <Row gutter={16}>
        <Col span={8}>
          <p>Prix d'achat</p>
          <InputNumber value={100} disabled={true} />
        </Col>
        <Col span={8}>
          <p>Marge</p>
          <InputNumber value={currentMargin} onChange={(value) => handleMarginChange(value)} />
        </Col>
        <Col span={8}>
          <p>Prix de Vente</p>
          <InputNumber value={100 * currentMargin} disabled={true} />
        </Col>
      </Row>
    </div>
  );
};

/**
 * MarginConfig
 * @param {Boolean} show - Weather or not to display the modal
 * @param {Object} profile - Profile config for current user
 * @param {Callback} onOk - Callback to handle 'ok' click
 * @param {Callback} onCancel - Callback to handle 'cancel' click
 */
const MarginConfig = (props) => {
  const [margin, setMargin] = useState(props.profile.margin);
  const [displayError, setDisplayError] = useState(false);
  const [loading, setLoading] = useState(false);
  const supplierName = {
    LAMINAMARC: 'Laminamarc',
    ONELABPRO: 'OneLabPro'
  };

  const handleMarginChange = (value, supplier) => {
    if (isNaN(value)) {
      return (false);
    }
    margin[supplier] = value;
    setMargin(margin);
  };

  const handleProfileUpdateSuccess = () => {
    notification.success('Félicitations !', 'Votre marge a bien été enregistrée');
    setLoading(false);
    props.onOk();
  };

  const handleProfileUpdateFailure = (error) => {
    appLogs.logError({
      function: 'handleProfileUpdateFailure',
      component: 'MarginConfig',
      error
    });
    notification.error('Impossible de mettre à jour votre marge', 'Veuillez ré-essayer plus tard');
    setLoading(false);
  };

  const handleSave = () => {
    if (!margin[supplierName.LAMINAMARC]) {
      margin[supplierName.LAMINAMARC] = defaultMargin.Laminamarc;
    }
    if (!margin[supplierName.ONELABPRO]) {
      margin[supplierName.ONELABPRO] = defaultMargin.OneLabPro;
    }
    if (isNaN(margin[supplierName.LAMINAMARC]) || isNaN(margin[supplierName.ONELABPRO])) {
      setDisplayError(true);
      return (false);
    }
    setDisplayError(false);
    setLoading(true);
    props.profile.margin = margin;
    Profile.update(props.profile, () => handleProfileUpdateSuccess(), (error) => handleProfileUpdateFailure(error));
  };

  return (
    <Modal
      visible={props.show}
      width='40%'
      title='Configurer mes marges'
      footer={[
        <Button key='Annuler' onClick={() => props.onCancel()}>
          Annuler
        </Button>,
        <Button
          key='Enregistrer'
          type='primary'
          loading={loading}
          onClick={() => handleSave()}
        >
          Enregistrer
        </Button>,
      ]}
      onOk={() => props.onOk()}
      onCancel={() => props.onCancel()}
    >
      { displayError && <Alert message='Valeurs incorrectes' type='error' showIcon={displayError} /> }
      <MarginConfigLine
        currentMargin={margin[supplierName.LAMINAMARC]}
        supplierName={supplierName.LAMINAMARC}
        onMarginUpdate={(value) => handleMarginChange(value, supplierName.LAMINAMARC)}
      />
      <br /><br />
      <MarginConfigLine
        currentMargin={margin[supplierName.ONELABPRO]}
        supplierName={supplierName.ONELABPRO}
        onMarginUpdate={(value) => handleMarginChange(value, supplierName.ONELABPRO)}
      />
    </Modal>
  );
};

export default MarginConfig;
