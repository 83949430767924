import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import Image from 'controllers/Image';

const { Meta } = Card;

/**
 * ShootingCard
 * @param {Object} shooting - Shooting to display infos
 * @param {Callback} onShootingSelection - Callback to handle shooting selection
 * @param {Callback} onPlancheContactSelection - Callback to handle planche contact selection
 */
const ShootingCard = (props) => {
  const defaultBannerPhoto = '/img/shooting-preview.png';
  const [bannerPhoto, setBannerPhoto] = useState(defaultBannerPhoto);

  const handleImageAccessSuccess = (result) => {
    if (result.length > 0) {
      setBannerPhoto(result[0].photo);
    }
  };

  const handleImageAccessFailure = (error) => {
    console.error(error);
  };

  useEffect(() => {
    Image.getImageByShootingId(
      props.shooting.id,
      (result) => handleImageAccessSuccess(result),
      (error) => handleImageAccessFailure(error)
    );
  }, []);

  return (
    <Card
      style={{
        width: 320,
        margin: '16px',
        cursor: 'pointer'
      }}
      cover={
        <img
          alt='banner'
          src={bannerPhoto}
          style={{
            width: '100%',
            height: '160px',
            objectFit: 'cover'
          }}
          onClick={() => props.onShootingSelection(props.shooting.id)}
        />
      }
      actions={[
        <span onClick={() => props.onShootingSelection(props.shooting.id)}>Voir la projection</span>,
        <span onClick={() => props.onPlancheContactSelection(props.shooting.id)}>Planche Contact</span>
      ]}
    >
      <Meta
        title={`Projection du ${props.shooting.projectionDate}`}
        description={props.shooting.projectionDate}
      />
    </Card>
  );
};

export default ShootingCard;
