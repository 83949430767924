import React from 'react';
import { Card, Form, Row, Col } from 'antd';
import Button from 'components/Button';
import photo from 'utils/photo';
import Frame from './Frame';
import Album from './Album';
import Custom from './Custom';

const View = (props) => {
  return (
    <Card>
      <Row justify='space-between'>
        <h2>Détail du produit</h2>
        <div>
          <Button type='link' onClick={props.onCancel}>Revenir à la liste</Button>
          <Button type='primary' onClick={props.onEdit}>Modifier le produit</Button>
        </div>
      </Row>
      <br /><br />
      <Form
        name='view'
        layout='vertical'
      >
        <Row>
          <Col span={8}>
            <Form.Item label='Nom du produit'>
              <p>{ props.product.name }</p>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Photo'>
              <img src={props.product.photo ? props.product.photo : photo.NO_PHOTO_URL} aria-hidden alt='Photo du produit' style={{ width: '100px' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label='Fournisseur'>
              <p>{ props.product.supplier }</p>
            </Form.Item>
          </Col>
        </Row>
        { props.product.type === props.type.FRAME && <Frame product={props.product} /> }
        { props.product.type === props.type.ALBUM && <Album product={props.product} /> }
        { props.product.type === props.type.CUSTOM && <Custom product={props.product} /> }
      </Form>
    </Card>
  );
};

export default View;
