import Compress from 'compress.js';
import request from 'utils/request';

const NO_PHOTO_URL = 'https://lumina-images.s3.eu-west-3.amazonaws.com/thumbnail_unnamed_f9c2a5d3c1.png';

const compress = new Compress();

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const getImageName = (image) => {
  const url = image.photo;
  const lastSlashIndex = url.lastIndexOf('/');
  const lastUnderscoreIndex = url.lastIndexOf('_');
  const lastDotIndex = url.lastIndexOf('.');
  const imageName = url.substring(lastSlashIndex + 1, lastUnderscoreIndex);
  const extension = url.substring(lastDotIndex);
  const fullName = imageName + extension;

  return (fullName);
};

const handlePreview = async file => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj);
  }

  this.setState({
    previewImage: file.url || file.preview,
    previewVisible: true,
  });
};

const compressPhoto = (file, handleSuccess, handleFailure) => {
  const filename = file.name;
  const config = {
    size: 1,
    quality: 0.75,
    maxWidth: 1920,
    maxHeight: 1920,
    resize: true
  };

  compress.compress([ file ], config)
    .then((data) => {
      const img1 = data[0];
      const base64str = img1.data;
      const imgExt = img1.ext;
      const newBlob = Compress.convertBase64ToFile(base64str, imgExt);
      const newFile = new File([ newBlob ], filename);

      handleSuccess(newFile);
    })
    .catch((error) => handleFailure(error, filename));
};

const uploadPhoto = (file, jwt, handleSucces, handleFailure) => {
  const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg'];
  const formData = new FormData();

  if (!validImageTypes.includes(file.type)) {
    handleFailure({ typeIsIncorrect: true, file });
    return (false);
  }
  compressPhoto(file,
    (newFile) => {
      formData.append('files', newFile);
      request.postMediaWithAuth(`${request.API_ENDPOINT_URL}/upload`, formData, jwt)
        .then(
          (result) => {
            if (result.statusCode === 400) {
              return (handleFailure(result));
            }
            handleSucces(result);
          },
          (error) => handleFailure(error)
        );
    },
    (error) => handleFailure(error)
  );
  return (false);
};

const uploadPhotoWithoutAuth = (file, handleSuccess, handleFailure) => {
  const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg'];
  const formData = new FormData();

  if (!validImageTypes.includes(file.type)) {
    handleFailure({ typeIsIncorrect: true, file });
    return (false);
  }
  compressPhoto(file,
    (newFile) => {
      formData.append('files', newFile);
      request.postMedia(
        `${request.API_ENDPOINT_URL}/upload`,
        formData,
        (result) => handleSuccess(result),
        (error) => handleFailure(error)
      );
    }
  );
  return (false);
};

const uploadLogo = (file, jwt, handleSucces, handleFailure) => {
  const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg'];
  const formData = new FormData();

  if (!validImageTypes.includes(file.type)) {
    handleFailure({ typeIsIncorrect: true, file });
    return (false);
  }
  formData.append('files', file);
  request.postMediaWithAuth(`${request.API_ENDPOINT_URL}/upload`, formData, jwt)
    .then(
      (result) => {
        if (result.statusCode === 400) {
          return (handleFailure(result));
        }
        handleSucces(result);
      },
      (error) => handleFailure(error)
    );
  return (false);
};

const photo = {
  NO_PHOTO_URL,
  handlePreview,
  uploadPhotoWithoutAuth,
  uploadPhoto,
  uploadLogo,
  compressPhoto,
  getImageName
};

export default photo;
