import React from 'react';
import { Form, Row, Col, Input } from 'antd';

const Album = () => {
  return (
    <Row gutter={16}>
      <Col span={8}>
        <Form.Item label='Nombre de pages'>
          <Form.Item name='size'>
            <Input size='small' />
          </Form.Item>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label={`Prix d'Achat`}>
          <Form.Item name='price-buy'>
            <Input addonAfter='€' />
          </Form.Item>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label='Prix de Vente'>
          <Form.Item name='price-sell'>
            <Input addonAfter='€' />
          </Form.Item>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Album;
