import React, { useState, useEffect } from 'react';
import { Alert, Card, Tabs } from 'antd';
import { useQuery } from 'utils/url';
import VideoModal from 'components/VideoModal';
import Profile from 'controllers/Profile';
import appLogs from 'utils/appLogs';
import auth from 'utils/auth';
import GeneralInformations from './GeneralInformations';
import PurchaseOrderSettings from './PurchaseOrderSettings';
import VisualIdentitities from './VisualIdentities';

const { TabPane } = Tabs;

/**
 * Handle the Profile view and edit
 */
const ProfileView = () => {
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: 'info', message: '' });
  const user = auth.getUser();
  const query = useQuery();
  const state = {
    VIEW: 'view',
    EDIT: 'edit',
    ADD: 'add'
  };
  const tab = {
    'info': '1',
    'billing': '2',
    'identity': '3'
  };
  const activeTab = tab[query.get('tab')];

  const handleProfileCreationSuccess = (result) => {
    setProfile(result);
    setLoading(false);
  };

  const handleProfileCreationFailure = (error) => {
    const message = {
      DEFAULT: 'Une erreur vient de se produire, veuillez réessayer plus tard'
    };

    setAlert({ show: true, type: 'error', message: message.DEFAULT });
    setTimeout(() => setAlert({ show: false, type: 'info', message: '' }), 5000);
    appLogs.logError({ function: 'handleProfileUpdateFailure', error });
  }

  const handleProfileAccessSuccess = (result) => {
    const tmpProfile = {
      email: user.usr.email,
      user: user.usr.id,
      billing: {},
      visualIdentities: [],
      margin: {},
      info: {}
    };

    if (result) {
      if (result.info && !result.info.onboardingStep) {
        setShowTutorial(true);
      }
      setProfile(result);
      setLoading(false);
      return (true);
    }
    setShowTutorial(true);
    Profile.create(tmpProfile, (result) => handleProfileCreationSuccess(result), (error) => handleProfileCreationFailure(error));
  };

  const handleProfileAccessFailure = (msg) => {
    const message = {
      DEFAULT: 'Une erreur vient de se produire, veuillez réessayer plus tard'
    };

    setAlert({ show: true, type: 'error', message: message.DEFAULT });
    setTimeout(() => setAlert({ show: false, type: 'info', message: '' }), 5000);
    appLogs.logError({ function: 'handleProfileUpdateFailure', msg });
  };

  useEffect(() => {
    Profile.get(
      (result) => handleProfileAccessSuccess(result),
      (error) => handleProfileAccessFailure(error)
    );
  }, []);

  return (
    <div>
      <Alert
        message={alert.message}
        type={alert.type}
        showIcon
        style={
          alert.show ?
              {
                marginLeft: 'calc(50vw - 125px)',
                marginBottom: '20px',
                width: '250px',
                display: ''
              }
            :
              {
                display: 'none'
              }
        }
      />
      <Card>
        <h1>Nom du studio</h1>
        <VideoModal
          show={!loading && showTutorial}
          onboardingStep={0}
          onOk={() => setShowTutorial(false)}
          onCancel={() => setShowTutorial(false)}
        />
        <Tabs defaultActiveKey={activeTab}>
          <TabPane tab='Informations generales' key='1'>
            <GeneralInformations
              state={state}
              user={user}
              profile={{
                'get': () => profile,
                'set': setProfile
              }}
              loading={loading}
              setAlert={setAlert}
            />
          </TabPane>
          <TabPane tab='Parametre "Bon de Commande"' key='2'>
            <PurchaseOrderSettings
              state={state}
              user={user}
              profile={{
                'get': () => profile,
                'set': setProfile
              }}
              loading={loading}
              setAlert={setAlert}
            />
          </TabPane>
          <TabPane tab='Identités visuelles' key='3'>
            <VisualIdentitities
              state={state}
              user={user}
              profile={{
                'get': () => profile,
                'set': setProfile
              }}
              loading={loading}
              setAlert={setAlert}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default ProfileView;
