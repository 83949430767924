import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import Button from 'components/Button';
import Profile from 'controllers/Profile';
import Product from 'controllers/Product';
import Client from 'controllers/Client';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import AllVideosModal from './AllVideosModal';
import { OnboardingStepsContainer, OnboardingSteps, OnboardingContent } from './OnboardingScreen.module.css';

const { Step } = Steps;

/**
 * OnboardingScreen
 * @param {Object} user - Current user
 */
const OnboardingScreen = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(0);

  const handleProfileUpdateSuccess = (result) => {
    console.log('> result : ', result);
  };

  const handleProfileUpdateFailure = (error) => {
    console.log('> error : ', error);
  };

  const handleClientAccessSuccess = (result, profile) => {
    if (!profile.info) {
      profile.info = {};
    }
    if (!result.length) {
      setStep(2);
      profile.info.onboardingStep = 2;
    } else {
      setStep(3);
      profile.info.onboardingStep = 3;
    }
    Profile.update(profile, (result) => handleProfileUpdateSuccess(result), (error) => handleProfileUpdateFailure(error));
  };

  const handleClientAccessFailure = (error, profile) => {
    setStep(2);
    notification.error('Impossible de récupérer les données de vos clients', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleClientAccessFailure', profile, error });
  };

  const checkIfUserHasClients = (profile) => {
    Client.getByAuthorId(profile.authorId, (result) => handleClientAccessSuccess(result, profile), (error) => handleClientAccessFailure(error, profile));
  };

  const handleProductAccessSuccess = (result, profile) => {
    if (!result.length) {
      setStep(1);
      if (!profile.info) {
        profile.info = {};
      }
      profile.info.onboardingStep = 1;
      Profile.update(profile, (result) => handleProfileUpdateSuccess(result), (error) => handleProfileUpdateFailure(error));
      return (false);
    }
    checkIfUserHasClients(profile);
  };

  const handleProductAccessFailure = (error, profile) => {
    setStep(1);
    notification.error('Impossible de récupérer les données de vos produits', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleProductAccessFailure', profile, error });
  };

  const checkIfUserHasProducts = (profile) => {
    Product.getByAuthorId(profile.authorId, (result) => handleProductAccessSuccess(result, profile), (error) => handleProductAccessFailure(error, profile));
  };

  const checkIfUserHasVisualIdentities = (profile) => {

    if (!profile) {
      setStep(0);
      return (false);
    }
    if (!profile.visualIdentities?.length) {
      setStep(0);
      if (!profile.info) {
        profile.info = {};
      }
      profile.info.onboardingStep = 0;
      Profile.update(profile, (result) => handleProfileUpdateSuccess(result), (error) => handleProfileUpdateFailure(error));
      return (false);
    }
    checkIfUserHasProducts(profile);
  }

  const handleProfileAccessSuccess = (result) => {
    const profile = result;

    if (profile && profile.info && profile.info.onboardingStep) {
      setStep(profile.info.onboardingStep);
      if (profile.info.onboardingStep === 1) {
        checkIfUserHasProducts(profile);
      } else if (profile.info.onboardingStep === 2) {
        checkIfUserHasClients(profile);
      } else if (profile.info.onboardingStep === 3) {
        return (true);
      }
    }
    checkIfUserHasVisualIdentities(profile);
  };

  const handleProfileAccessFailure = (error) => {
    setStep(0);
    notification.error('Impossible de récupérer les données de votre profil', 'Veuillez réessayer plus tard');
    appLogs.logError({ function: 'handleProfileAccessFailure', error });
  };

  const defineOnboardingStep = () => {
    Profile.get(
      (result) => handleProfileAccessSuccess(result),
      (error) => handleProfileAccessFailure(error)
    );
  };

  useEffect(() => {
    defineOnboardingStep();
  }, []);

  return (
    <div>
      <AllVideosModal
        show={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      />
      <div className={OnboardingStepsContainer}>
        <Steps current={step} className={OnboardingSteps}>
          <Step title='Complétez votre profil' />
          <Step title='Importez vos produits' />
          <Step title='Créez votre première projection' />
        </Steps>
        {
          step === 0 &&
            <div className={OnboardingContent}>
              <p><b>Renseignez le nom de votre studio et uploadez votre logo</b></p>
              <p>(temps estimé : 2 min)</p>
              <Button
                type='primary'
                onClick={() => window.location = '/mon-profil'}
              >
                Compléter mon profil
              </Button>
            </div>
        }
        {
          step === 1 &&
            <div className={OnboardingContent}>
              <p><b>
                Importez les cadres que vous utiliserez dans l'éditeurs<br />
                et les produits que vous voulez pouvoir ajouter aux bons de commandes
              </b></p>
              <p>(temps estimé : 6 min)</p>
              <Button
                type='primary'
                onClick={() => window.location = '/mes-produits'}
              >
                Importer mes produits
              </Button>
            </div>
        }
        {
          step === 2 &&
            <div className={OnboardingContent}>
              <p><b>
                Vous êtes prêt(e) à utiliser Lumina !<br />
                Il ne vous reste plus qu'à créer votre 1er client et sa projection
              </b></p>
              <p>(temps estimé : 1 min)</p>
              <Button
                type='primary'
                onClick={() => window.location = '/mes-clients'}
              >
                Créer mon 1er client
              </Button>
            </div>
        }
        {
          step === 3 &&
          <div className={OnboardingContent}>
            <p><b>
              Félicitations !<br />
              vous pouvez maintenant utiliser toutes les fonctionalités de Lumina !
            </b></p>
            <Button
              type='default'
              onClick={() => setShowModal(true)}
            >
              Voir les vidéos tutoriel
            </Button>
          </div>
        }
      </div>
    </div>
  );
};

export default OnboardingScreen;
