import React, { useState } from 'react';
import { EDITOR } from 'configs/AppConfig';
import drawing from 'utils/drawing';

/**
 * CanvaDrawing
 * @param {Array} points - List of calibration points
 * @param {Callback} onNewPoint - Callback to handle point creation
 */
const CanvaDrawing = (props) => {
  const [canvasContext, setCanvasContext] = useState();

  const getCursorPosition = (event) => {
    let x;
    let y;

    if (event.pageX !== undefined && event.pageY !== undefined) {
      x = event.pageX;
      y = event.pageY;
    } else {
      x = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      y = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    return ({ x, y });
  }

  const correctPoint = (point) => {
    if (!props.points.length) {
      return (point);
    }
    return ({
      x: point.x,
      y: props.points[0].y
    });
  };

  const handleClick = (event) => {
    const click = {
      x: getCursorPosition(event).x - EDITOR.LEFT_PANEL_WIDTH.PIXEL_FORMAT,
      y: getCursorPosition(event).y - EDITOR.TOP_NAV_HEIGHT.PIXEL_FORMAT
    };
    const point = correctPoint(click, drawing.computeCanva());

    if (props.points.length >= 2) {
      return (false);
    }
    drawing.drawPoint(canvasContext, point);
    drawing.drawHorizontal(canvasContext, point, drawing.computeCanva());
    props.onNewPoint(point);
    if (props.points.length === 2) {
      drawing.clearCanvas(canvasContext, drawing.computeCanva());
      drawing.drawPoint(canvasContext, props.points[0]);
      drawing.drawPoint(canvasContext, props.points[1]);
      drawing.drawLine(canvasContext, props.points[0], props.points[1]);
    }
  }

  const createDrawingContext = (canvas) => {
    if (canvas) {
      setCanvasContext(canvas.getContext('2d'));
    }
  };

  return (
    <canvas
      id='canvas'
      width={drawing.computeCanva().width - 6}
      height={drawing.computeCanva().height - 6}
      ref={(canvas) => createDrawingContext(canvas)}
      onClick={(event) => handleClick(event)}
      style={{
        position: 'absolute',
      }}
    >
    </canvas>
  );
};

export default CanvaDrawing;
