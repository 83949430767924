import axios from 'axios';
import { API_ENDPOINT_URL } from 'configs/AppConfig';

const method = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const replacer = (key, value) => {
  if (key === 'drawing') {
    return (undefined);
  } else if (key === 'drawingContext') {
    return (undefined);
  }
  return (value);
};

async function doRequest(methodType, url, content, jwt) {
  const headers = { 'Content-Type': 'application/json' };
  const params = ( methodType === method.GET ? { params: JSON.stringify(content, replacer) } : { body: JSON.stringify(content, replacer) });

  if (jwt) {
    headers['Authorization'] = `Bearer ${jwt}`
  }
  const response = await fetch(url, {
    method: methodType,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    ...params
  });
  return (response.json());
};

async function getData(url = '', data = {}) {
  Object.keys(data).forEach((key, index) => {
    if (index === 0) {
      url += `?${key}=${data[key]}`;
    } else {
      url += `&${key}=${data[key]}`;
    }
  });
 return (doRequest(method.GET, url, data));
};

async function getDataWithAuth(url = '', data = {}, jwt) {
  Object.keys(data).forEach((key, index) => {
    if (index === 0) {
      url += `?${key}=${data[key]}`;
    } else {
      url += `&${key}=${data[key]}`;
    }
  });
  return (doRequest(method.GET, url, data, jwt));
};

async function getDataInline(url = '', data = {}, handleSuccess, handleFailure) {
  Object.keys(data).forEach((key, index) => {
    if (index === 0) {
      url += `?${key}=${data[key]}`;
    } else {
      url += `&${key}=${data[key]}`;
    }
  });
  doRequest(method.GET, url, data)
    .then((result) => {
      if (result.statusCode === 400) {
        return (handleFailure(result));
      }
      return (handleSuccess(result));
    })
    .catch((error) => handleFailure(error));
};

async function getDataWithAuthInline(url = '', data = {}, jwt, handleSuccess, handleFailure) {
  Object.keys(data).forEach((key, index) => {
    if (index === 0) {
      url += `?${key}=${data[key]}`;
    } else {
      url += `&${key}=${data[key]}`;
    }
  });
  doRequest(method.GET, url, data, jwt)
    .then((result) => {
      if (result.statusCode === 400) {
        return (handleFailure(result));
      }
      return (handleSuccess(result));
    })
    .catch((error) => handleFailure(error));
};

async function postData(url = '', data = {}) {
  return (doRequest(method.POST, url, data));
};

async function postDataInline(url = '', data = {}, handleSuccess, handleFailure) {
  doRequest(method.POST, url, data)
    .then((result) => {
      if (result.statusCode === 400) {
        return (handleFailure(result));
      }
      return (handleSuccess(result));
    })
    .catch((error) => handleFailure(error));
};

async function postDataWithAuth(url = '', data = {}, jwt) {
  return (doRequest(method.POST, url, data, jwt));
};

async function postDataWithAuthInline(url = '', data = {}, jwt, handleSuccess, handleFailure) {
  doRequest(method.POST, url, data, jwt)
    .then((result) => {
      if (result.statusCode === 400) {
        return (handleFailure(result));
      }
      return (handleSuccess(result));
    })
    .catch((error) => handleFailure(error));
};


async function postMedia(url = '', media = {}, handleSuccess, handleFailure) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  axios.post(url, media, { headers })
    .then((result) => {
      if (result.statusCode === 400) {
        return (handleFailure(result));
      }
      return (handleSuccess(result));
    })
    .catch((error) => handleFailure(error));
};

async function postMediaWithAuth(url = '', media = {}, jwt) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${jwt}`
  };

  return (axios.post(url, media, { headers }));
};

async function postMediaWithAuthInline(url = '', media = {}, jwt, handleSuccess, handleFailure) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${jwt}`
  };

  axios.post(url, media, { headers })
    .then((result) => {
      if (result.statusCode === 400) {
        return (handleFailure(result));
      }
      return (handleSuccess(result));
    })
    .catch((error) => handleFailure(error));
};

async function putData(url = '', data = {}) {
  return (doRequest(method.PUT, url, data));
};

async function putDataWithAuth(url = '', data = {}, jwt) {
  return (doRequest(method.PUT, url, data, jwt));
};

async function putDataWithAuthInline(url = '', data = {}, jwt, handleSuccess, handleFailure) {
  doRequest(method.PUT, url, data, jwt)
    .then((result) => {
      if (result.statusCode === 400) {
        return (handleFailure(result));
      }
      return (handleSuccess(result));
    })
    .catch((error) => handleFailure(error));
};

async function deleteData(url = '') {
  return (doRequest(method.DELETE, url, {}));
};

async function deleteDataWithAuthInline(url = '', jwt, handleSuccess, handleFailure) {
  doRequest(method.DELETE, url, {}, jwt)
    .then((result) => {
      if (result.statusCode === 400) {
        return (handleFailure(result));
      }
      return (handleSuccess(result));
    })
    .catch((error) => handleFailure(error));
};

const request = {
  API_ENDPOINT_URL,
  getData,
  getDataWithAuth,
  getDataInline,
  getDataWithAuthInline,
  postData,
  postDataInline,
  postDataWithAuth,
  postDataWithAuthInline,
  postMedia,
  postMediaWithAuth,
  postMediaWithAuthInline,
  putData,
  putDataWithAuth,
  putDataWithAuthInline,
  deleteData,
  deleteDataWithAuthInline
};

export default request;
