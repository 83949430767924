import React from 'react';
import { Card } from 'antd';

const { Meta } = Card;

/**
 * ContextCard
 * @param {Object} context - Context to be calibrated
 */
const ContextCard = (props) => {
	const openCalibrator = () => {
		window.open(`/editor/calibrate/${props.context.clientId}/${props.context.id}`, '_blank');
	}

	return (
		<Card
      style={{
        width: 320,
        margin: '16px',
        cursor: 'pointer'
      }}
      cover={
        <img
          alt='banner'
          src={props.context.url}
          style={{
            width: '100%',
            height: '160px',
            objectFit: 'cover'
          }}
          onClick={() => openCalibrator()}
        />
      }
      actions={[
        <span onClick={() => openCalibrator()}>Calibrer l'intérieur</span>
      ]}
    >
      <Meta title={`Envoyé le ${props.context.created_at.split('T')[0]}`} />
    </Card>
	);
};

export default ContextCard;
