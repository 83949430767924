import request from 'utils/request';
import auth from 'utils/auth';

const user = auth.getUser();

const create = (profile, handleSuccess, handleFailure) => {
  request.postDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/profiles`,
    profile,
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const get = (handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/profiles`,
    { email: user.usr.email },
    user.jwt,
    (result) => handleSuccess(result[0]),
    (error) => handleFailure(error)
  );
};

const update = (profile, handleSuccess, handleFailure) => {
  request.putDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/profiles/${profile.id}`,
    profile,
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const Profile = { create, get, update };

export default Profile;
