import React from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { APP_ENDPOINT_URL } from 'configs/AppConfig';
import { message } from 'antd';

const getContextUploadLink = (client) => {
  const contextUploadLink = `/upload/${client.id}-${client.userId}-${client.authorId}`;

  return (contextUploadLink);
};

/**
 * ContextUploadLink
 * @param {Object} client - Current client
 */
const ContextUploadLink = (props) => {
  const uploadLink = `${APP_ENDPOINT_URL}/upload/${props.client.id}-${props.client.userId}-${props.client.authorId}`;
  const lineBreak = '%0D%0A';
  const email = {
    to: props.client.contact?.email ? props.client.contact?.email : '',
    subject: 'Envoyez les photos de votre intérieur',
    body: `Bonjour,
      ${lineBreak}
      ${lineBreak}Envoyez les photos de votre intérieur à votre photographe via ce lien :
      ${lineBreak}
      ${lineBreak}${uploadLink}
      ${lineBreak}
      ${lineBreak}Vous pouvez ouvrir ce lien sur votre téléphone et prendre directement la photo depuis le lien.
      ${lineBreak}
      ${lineBreak}Powered by Lumina
    `
  };

  const handleCopyLinkSuccess = () => {
    message.success('Lien copié dans le presse-papier');
  }

  const handleCopyLinkError = () => {
    message.error('Erreur lors de la copie du lien');
  }

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(uploadLink)
      .then(() => handleCopyLinkSuccess())
      .catch(() => handleCopyLinkError());
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item onClick={() => copyLinkToClipboard()}>
            <span>Copier le lien</span>
          </Menu.Item>
          <Menu.Item>
            <a href={`mailto:${email.to}?subject=${email.subject}&body=${email.body}`}>
              Envoyer le lien par email
            </a>
          </Menu.Item>
        </Menu>
      }
    >
      <span style={{ color: 'blue' }}>
        Lien d'upload d'intérieur du client <DownOutlined />
      </span>
    </Dropdown>
  );
};

export default ContextUploadLink;
export { getContextUploadLink };
