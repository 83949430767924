import React, { useState } from 'react';
import ImageRow from 'components/ImageRow';
import CompareModeHeader from './CompareModeHeader';

/**
 * CompareMode
 * @param {Array} selectedImages - List of selected images
 * @param {Callback} onImageUpdate - Callback to handle image update
 * @param {Callback} onImageUnselection - Callback to handle image unselection
 * @param {Callback} onModeSwitch - Callback to handle mode switch
 */
const CompareMode = (props) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [images, setImages] = useState(props.selectedImages);
  const [isOverview, setIsOverview] = useState(false);

  const handleImageSelection = (image) => {
    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter(selectedImage => selectedImage.id !== image.id));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  }

  const handleImageLike = () => {
    let i = 0;

    while (i < selectedImages.length) {
      selectedImages[i].isLiked = !selectedImages[i].isLiked;
      props.onImageUpdate(selectedImages[i]);
      i += 1;
    }
    setSelectedImages([]);
  };

  const handleImageWithdrawal = () => {
    setImages(images.filter(image => !selectedImages.includes(image)));
    setSelectedImages([]);
  };

  const handleModeSwitch = () => {
    props.onImageUnselection();
    props.onModeSwitch();
  };

  return (
    <div>
      <CompareModeHeader
        selectedImages={selectedImages}
        onImageLike={() => handleImageLike()}
        onImageWithdrawal={() => handleImageWithdrawal()}
        onModeSwitch={() => handleModeSwitch()}
        onOverviewSwitch={() => setIsOverview(!isOverview)}
      />
      <br />
      <ImageRow
        mode={isOverview ? 'overview' : 'compare'}
        showLikedImages={true}
        imageList={images}
        selectedImages={selectedImages}
        onImageSelection={(image) => handleImageSelection(image)}
      />
    </div>
  );
};

export default CompareMode;
