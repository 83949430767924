import React, { useEffect, useState } from 'react';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import AppViews from 'Views/app-views';
import { Layout, Grid } from 'antd';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP, DIR_RTL, DIR_LTR } from 'constants/ThemeConstant';
import ConfirmAccountModal from 'components/ConfirmAccountModal';
import Subscription from 'controllers/Subscription';
import appLogs from 'utils/appLogs';
import auth from 'utils/auth';
import utils from 'utils';

const { Content } = Layout;
const { useBreakpoint } = Grid;

/**
 * AppLayout
 * @param {Boolean} navCollapsed - is nav collapsed
 * @param {Object} navType - nav type
 * @param {String} direction - direction
 * @param {Callback} logout - logout callback
 */
export const AppLayout = ({ navCollapsed, navType, direction, logout }) => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavTop = navType === NAV_TYPE_TOP;
  const user = auth.getUser();

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return (0);
    }
    return (navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH);
  };

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return ({ paddingLeft: getLayoutGutter() });
    }
    if (direction === DIR_RTL) {
      return ({ paddingRight: getLayoutGutter() });
    }
    return ({ paddingLeft: getLayoutGutter() });
  };

  const handleSubscriptionAccessSuccess = (result) => {
    const subscription = result[0];

    if (!subscription) {
      window.location = '/checkout';
    } else if (!subscription.isActive) {
      window.location = '/checkout?subscription_expired=true';
    }
  };

  const handleSubscriptionAccessFailure = (error) => {
    appLogs.logError({ function: 'handleSubscriptionAccessFailure', error });
  };

  const handleDemoExpired = () => {
    window.location = '/checkout?demo_expired=true';
  };

  const handleUserUpdateCompletion = (newUser) => {
    if (!newUser.usr.isDemo) {
      handleDemoExpired();
    }
  };

  useEffect(() => {
    if (auth.isConfirmed() && !auth.isDemo()) {
      Subscription.get(
        (result) => handleSubscriptionAccessSuccess(result),
        (error) => handleSubscriptionAccessFailure(error)
      );
    } else if (auth.isDemo()) {
      auth.updateUser((newUser) => handleUserUpdateCompletion(newUser));
    }
  }, []);

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Layout className='app-container'>
        <Layout className='app-layout' style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <Content>
              {!auth.isConfirmed() && <ConfirmAccountModal user={user} />}
              <AppViews logout={logout} />
            </Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

export default AppLayout;
