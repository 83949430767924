import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Context from 'controllers/Context';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import drawing from 'utils/drawing';
import LeftPanel from './LeftPanel';
import Editor from './Editor';
import { Container, LeftPart, RightPart } from './Calibrator.module.css';

const Calibrator = () => {
  const [refresh, setRefresh] = useState(false);
  const [context, setContext] = useState({});
  const [points, setPoints] = useState([]);
  const [virtualWidth, setVirtualWidth] = useState(0);
  const { clientId, contextId } = useParams();

  const computeContextSize = async (realWidth) => {
    const coefficient = realWidth / virtualWidth;
    const contextSize = {
      virtual: await drawing.getImageSize(context.url, drawing.computeCanva()),
      real: {
        height: 0,
        width: 0
      }
    };

    contextSize.real.width = contextSize.virtual.width * coefficient;
    contextSize.real.height = contextSize.virtual.height * coefficient;
    context.info.url = context.url;
    context.info.size = contextSize.real;
    setContext(context);
    return (contextSize);
  };

  const handleContextUpdateSuccess = (result) => {
    notification.success('Félicitations !', 'La calibration a bien été effectuée');
  };

  const handleContextUpdateFailure = (error) => {
    notification.error('Impossible d\'enregistrer la calibration', 'Veuillez ré-essayer plus tard');
    appLogs.logError({ function: 'handleContextUpdateFailure', component: 'Calibrator', error });
  };

  const handleCalibrationValidation = () => {
    Context.update(
      context,
      (result) => handleContextUpdateSuccess(result),
      (error) => handleContextUpdateFailure(error)
    );
  };

  const handleObjectDefinition = async (width) => {
    computeContextSize(width);
  };

  const handlePointsConfirmation = () => {
    setVirtualWidth(Math.abs(points[0].x - points[1].x));
  };

  const handlePointCreation = (point) => {
    points.push(point);
    setPoints(points);
    setRefresh(!refresh);
  };

  const handleStartOver = () => {
    setPoints([]);
    setVirtualWidth(0);
    setRefresh(!refresh);
  };

  const handleContextAccessSuccess = (result) => {
    setContext(result);
  };

  const handleContextAccessFailure = (error) => {
    notification.error('Impossible de charger la photo', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleContextAccessFailure', component: 'Calibrator', error });
  };

  useEffect(() => {
    Context.getById(
      contextId,
      (result) => handleContextAccessSuccess(result),
      (error) => handleContextAccessFailure(error)
    );
  }, []);

  return (
    <div className={Container}>
      <div className={LeftPart}>
        <LeftPanel
          clientId={clientId}
          points={points}
          context={context}
          onPointsConfirmation={() => handlePointsConfirmation()}
          onObjectDefinition={(width) => handleObjectDefinition(width)}
          onCalibrationValidation={() => handleCalibrationValidation()}
          onStartOver={() => handleStartOver()}
        />
      </div>
      <div className={RightPart}>
        <Editor
          context={context}
          points={points}
          onNewPoint={(point) => handlePointCreation(point)}
        />
      </div>
    </div>
  );
};

export default Calibrator;
