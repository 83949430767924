import React, { useState, useEffect } from 'react';
import { Spin, Tabs } from 'antd';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import { getParams } from 'utils/url';
import ShootingPhotos from './ShootingPhotos';
import Orders from './Orders';
import PurchaseOrder from './PurchaseOrder';
import { LoadingContainer } from './Shooting.module.css';

const { TabPane } = Tabs;

const tab = {
  SHOOTING_PHOTOS: '1',
  ORDERS: '2',
  PURCHASE_ORDER: '3'
};

/**
 * View
 * @param {Object} user - Current user
 * @param {String} clientId - Id of the client to view
 * @param {String} shootingId - Id of the shooting to view
 * @param {Object} client - Current client
 */
const Shooting = (props) => {
  const [shooting, setShooting] = useState({
    imageList: [],
    sceneList: [],
    orderList: [],
    extraList: [],
    clientInfo: {}
  });
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [currentTab, setCurrentTab] = useState(tab.SHOOTING_PHOTOS);

  const handleTabChange = (key) => {
    setRefresh(!refresh);
  }

  const handleClientInfoAccessSuccess = (result, imageList, sceneList, orderList, extraList) => {
    setShooting({
      imageList: imageList,
      sceneList: sceneList,
      orderList: orderList,
      extraList: extraList,
      clientInfo: result[0]
    });
    setLoading(false);
  };

  const handleClientInfoAccessFailure = (error, from) => {
    notification.error('Impossible de récupérer les infos du client', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleClientInfoAccessFailure', error, from });
    setLoading(false);
  };

  const getClientInfoFromDb = (imageList, sceneList, orderList, extraList) => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/client-infos`, { shootingId: props.shootingId }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientInfoAccessFailure(result, 'getClientInfoFromDb-1'));
        }
        return (handleClientInfoAccessSuccess(result, imageList, sceneList, orderList, extraList));
      })
      .catch((error) => handleClientInfoAccessFailure(error, 'getClientInfoFromDb-2'));
  }

  const handleExtraAccessSuccess = (result, imageList, sceneList, orderList) => {
    getClientInfoFromDb(imageList, sceneList, orderList, result);
  };

  const handleExtraAccessFailure = (error) => {
    notification.error('Impossible de récupérer les extras du shooting', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleExtraAccessFailure', error });
    setLoading(false);
  };

  const getExtrasFromDb = (imageList, sceneList, orderList) => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/extras`, { shootingId: props.shootingId }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleExtraAccessFailure(result));
        }
        return (handleExtraAccessSuccess(result, imageList, sceneList, orderList));
      })
      .catch((error) => handleExtraAccessFailure(error));
  };

  const handleOrderAccessSuccess = (result, imageList, sceneList) => {
    getExtrasFromDb(imageList, sceneList, result);
  };

  const handleOrderAccessFailure = (error) => {
    notification.error('Impossible de récupérer les commandes du shooting', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleOrderAccessFailure', error });
    setLoading(false);
  };

  const getOrdersFromDb = (imageList, sceneList) => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/orders`, { shootingId: props.shootingId }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleOrderAccessFailure(result));
        }
        return (handleOrderAccessSuccess(result, imageList, sceneList));
      })
      .catch((error) => handleOrderAccessFailure(error));
  };

  const handleSceneAccessSuccess = (result, imageList) => {
    getOrdersFromDb(imageList, result);
  };

  const handleSceneAccessFailure = (error) => {
    notification.error('Impossible de récupérer les scenes du shooting', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleSceneAccessFailure', error });
    setLoading(false);
  };

  const getScenesFromDb = (imageList) => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/scenes`, { shootingId: props.shootingId }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleSceneAccessFailure(result));
        }
        return (handleSceneAccessSuccess(result, imageList));
      })
      .catch((error) => handleSceneAccessFailure(error));
  };

  const handleImageListAccessSuccess = (result) => {
    getScenesFromDb(result);
  };

  const handleImageListAccessFailure = (error) => {
    notification.error('Impossible de récupérer les photos du shooting', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleImageListAccessFailure', error });
    setLoading(false);
  };

  useEffect(() => {
    const params = getParams();

    setLoading(true);
    if (params.tab && params.tab === tab.ORDERS) {
      setCurrentTab(tab.ORDERS);
    }
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/images`, { shootingId: props.shootingId }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleImageListAccessFailure(result));
        }
        handleImageListAccessSuccess(result);
      })
      .catch((error) => handleImageListAccessFailure(error));
  }, []);

  if (loading) {
    return (
      <div className={LoadingContainer}>
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <Tabs defaultActiveKey={currentTab} onChange={handleTabChange}>
        <TabPane tab='Photos de la projection' key='1'>
          <ShootingPhotos
            user={props.user}
						client={props.client}
            shootingId={props.shootingId}
            shooting={shooting}
          />
        </TabPane>
        <TabPane tab='Mes commandes' key='2'>
          <Orders
            user={props.user}
            clientId={props.clientId}
            shootingId={props.shootingId}
            shooting={shooting}
          />
        </TabPane>
        <TabPane tab='Bon de commande' key='3'>
          <PurchaseOrder
            user={props.user}
            client={props.client}
            shootingId={props.shootingId}
            shooting={shooting}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Shooting;
