import React, { useState } from 'react';
import { Card } from 'antd';
import ClientForm from 'components/ClientForm';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import regex from 'utils/regex';
import request from 'utils/request';

/**
 * ClientAdd
 * @param {Object} user - Current user
 * @param {Callback} onCreation - Callback to handle creation completion
 * @param {Callback} onCancel - Callback to handle creation cancel
 */
const ClientAdd = (props) => {
  const [loading, setLoading] = useState(false);

  const dataIsCorrect = (data) => {
    if (!data.name) {
      notification.error('Pas de nom du client', 'Veuillez renseigner un nom pour le client');
      return (false);
    } else if (data.email && !regex.emailIsCorrect(data.email)) {
      notification.error('Email invalide', 'Veuillez renseigner un email correct');
      return (false);
    }
    return (true);
  };

  const handleClientCreationSuccess = (result) => {
    notification.success('Félicitations !', 'Le client a bien été créé');
    setLoading(false);
    props.onCreation();
  };

  const handleClientCreationFailure = (error) => {
    notification.error('Impossible de créer le client', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleClientCreationFailure', error });
    setLoading(false);
  };

  const handleFormSubmit = (data) => {
    const client = {
      userId: props.user.usr.id,
      name: data.name,
      number: data.number,
      shootingIdList: [],
      contact: {
        email: data.email,
        phone: data.phone
      }
    };

    if (!dataIsCorrect(data)) {
      return (false);
    }
    setLoading(true);
    request.postDataWithAuth(`${request.API_ENDPOINT_URL}/clients`, client, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientCreationFailure(result));
        }
        return (handleClientCreationSuccess(result));
      })
      .catch((error) => handleClientCreationFailure(error));
  };

  return (
    <Card>
      <ClientForm
        title='Ajouter un client'
        loading={loading}
        onCancel={props.onCancel}
        onFinish={handleFormSubmit}
      />
    </Card>
  );
};

export default ClientAdd;
