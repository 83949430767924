import React from 'react';
import { Steps } from 'antd';
import PhotoUploader from 'components/PhotoUploader';
import Button from 'components/Button';
import { Container, StepsStyle, ButtonWrapper } from './Timeline.module.css';

const { Step } = Steps;

/**
 * Timeline
 * @param {Integer} step - Current step
 * @param {Callback} onNextStep - Callback to handle step completion
 */
const Timeline = (props) => {
  const actionList = [
    <Button
      type='primary'
      className={ButtonWrapper}
      onClick={() => props.onNextStep()}
    >
      J'ai bien mis la feuille sur le mur
    </Button>,
    <PhotoUploader
      text='Prendre / choisir la photo'
      onPhotoUpload={(photoUrl) => props.onNextStep(photoUrl)}
    />
  ];

  return (
    <div className={Container}>
      <Steps
        direction='vertical'
        current={props.step}
        className={StepsStyle}
      >
        <Step
          title='Préparation'
          description='Mettez une feuille A4 sur votre mur'
        />
        <Step
          title='Photo'
          description='Prenez votre mur en photo maintenant ou choisissez une image depuis la galerie'
        />
        <Step
          title='Envoie'
          description='Confirmez et envoyez votre photo'
        />
      </Steps>
      { actionList[props.step] }
    </div>
  );
};

export default Timeline;
