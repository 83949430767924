const isDateInThePast = (date) => {
	const now = new Date().setHours(0,0,0,0);
	const dateToCompare = new Date(date).setHours(0,0,0,0);

	return dateToCompare < now;
}

const time = {
	isDateInThePast,
};

export default time;
