import React from 'react';
import Button from 'components/Button';
import { Example, ButtonWrapper, ExampleImage } from './PointsCreation.module.css';

/**
 * PointsCreation
 * @param {Array} points - List of calibration points
 * @param {Callback} onPointsConfirmation - Callback to handle points confirmation
 * @param {Callback} onStartOver - Callback to handle start over
 */
const PointsCreation = (props) => {
  return (
    <div>
      <p>Cliquez sur les deux coins en haut de l'objet à calibrer</p>
      <Button
        type={props.points.length === 2 ? 'primary' : 'default'}
        disabled={props.points.length === 2 ? false : true}
        onClick={() => props.onPointsConfirmation()}
        className={ButtonWrapper}
      >
        C'est fait
      </Button>
      <Button
        type='link'
        onClick={() => props.onStartOver()}
        className={ButtonWrapper}
      >
        Recommencer
      </Button>
      <div className={Example}>
        <p>Exemple :</p>
        <img
          src='/img/example/calibration-01.png'
          alt='exemple'
          className={ExampleImage}
        />
      </div>
    </div>
  );
};

export default PointsCreation;
