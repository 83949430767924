import React, { useState } from 'react';
import { Modal, Row, Col, Input, InputNumber } from 'antd';
import Button from 'components/Button';
import MusicUploader from 'components/MusicUploader';
import { Center, Option, OptionValue } from './DiaporamaSettingsEditor.module.css';

/**
 * FooterButtons
 * @param {Boolean} canLaunch - Whether or not can launch the diaporama
 * @param {Callback} onLaunch - Callback to handle diaporama launch
 * @param {Callback} onClose - Callback to handle close in modal
 */
const FooterButtons = (props) => {
  return (
    <div>
      <Button type='default' onClick={() => props.onClose()}>
        Annuler
      </Button>
      <Button type='primary' disabled={!props.canLaunch} onClick={() => props.onLaunch()}>
        Lancer
      </Button>
    </div>
  );
};

/**
 * MusicOptions
 * @param {Object} settings - Settings of the diaporama
 * @param {Callback} onMusicUpdate - Callback to handle music update
 */
const MusicOptions = (props) => {
  const [refresh, setRefresh] = useState(false);
  const musicOptions = [
    {
      name: 'Piano Classique',
      url: 'https://lumina-images.s3.eu-west-3.amazonaws.com/bensound_memories_1_e9fc15e6c5.mp3',
      duration: 230.26
    },
    {
      name: 'Musique Pop',
      url: 'https://lumina-images.s3.eu-west-3.amazonaws.com/bensound_sweet_8139e93885.mp3',
      duration: 307.67
    },
    {
      name: 'Musique Rock',
      url: 'https://lumina-images.s3.eu-west-3.amazonaws.com/bensound_happyrock_2956860393.mp3',
      duration: 105.82
    }
  ];

  const onMusicUpload = (url, fileName, duration) => {
    const newMusic = {
      name: fileName,
      url: url,
      duration: duration
    };

    console.log('> newMusic : ', newMusic);
    props.onMusicUpdate({
      ...newMusic,
      option: 3
    });
    setRefresh(!refresh);
  };

  return (
    <div>
      <h2>Musique</h2>
      <Row gutter={16}>
        {
          musicOptions.map((music, index) => (
            <Col>
              <Button
                type={index === props.settings.music?.option ? 'primary' : 'default'}
                onClick={() => props.onMusicUpdate({ ...music, option: index })}
              >
                { music.name }
              </Button>
            </Col>
          ))
        }
        {
          props.settings.music?.option === 3 &&
            <Col>
              <Button
                type='primary'
                onClick={() => props.onMusicUpdate({ ...props.settings.music, option: 3 })}
              >
                { props.settings.music?.name }
              </Button>
            </Col>
        }
        <Col>
          <MusicUploader
            onMusicUpload={(url, fileName, duration) => onMusicUpload(url, fileName, duration)}
          />
        </Col>
      </Row>
    </div>
  );
};

/**
 * TimingOptions
 * @param {Object} settings - Settings of the diaporama
 * @param {Integer} nbImage - Nb of images in diaporama
 * @param {Callback} onTimingUpdate - Callback to handle timing update
 */
const TimingOptions = (props) => {
  const [customTiming, setCustomTiming] = useState(3);
  const timingOptions = [
    {
      name: '6 sec',
      value: 6
    },
    {
      name: '8 sec',
      value: 8
    },
    {
      name: '10 sec',
      value: 10
    },
    {
      name: '12 sec',
      value: 12
    },
  ];

  const getAutoTiming = () => {
    const duration = props.settings.music?.duration;
    let nbLap = 1;
    let timing = (nbLap * duration) / props.nbImage;

    while (timing < 6) {
      nbLap++;
      timing = (nbLap * duration) / props.nbImage;
    }
    return (timing);
  };

  const selectAutoTiming = () => {
    props.onTimingUpdate({
      option: 0,
      name: 'Automatique',
      value: getAutoTiming()
    });
  };

  const onCustomTimingUpdate = (value) => {
    setCustomTiming(value);
    props.onTimingUpdate({
      ...props.settings.timing,
      option: -1
    });
  }

  const onCustomTimingSelection = () => {
    props.onTimingUpdate({
      option: 5,
      name: 'Personnalisé',
      value: customTiming
    });
  };

  return (
    <div>
      <h2>Temps d'apparition</h2>
      <Row gutter={16}>
        <Col>
          <Button
            type={props.settings.timing?.option === 0 ? 'primary' : 'default'}
            disabled={!props.settings.music?.duration}
            onClick={() => selectAutoTiming()}
          >
            Automatique {props.settings.music?.duration && `: ${Math.round(getAutoTiming())} sec`}
          </Button>
        </Col>
        {
          timingOptions.map((timing, index) => (
            <Col>
              <Button
                type={index + 1 === props.settings.timing?.option ? 'primary' : 'default'}
                onClick={() => props.onTimingUpdate({ ...timing, option: index + 1 })}
              >
                { timing.name }
              </Button>
            </Col>
          ))
        }
        <Col>
          <InputNumber
            min={6}
            max={20}
            defaultValue={6}
            onChange={(value) => onCustomTimingUpdate(value)}
          />
          <Button
            type={props.settings.timing?.option === 5 ? 'primary' : 'default'}
            onClick={() => onCustomTimingSelection()}
          >
            Choisir
          </Button>
        </Col>
      </Row>
      <br />
      <p>En mode 'automatique' le defilement des images prendra le temps de la musique</p>
    </div>
  );
};

/**
 * WelcomeScreenOptions
 * @param {Object} client - Current client
 * @param {Object} settings - Settings of the diaporama
 * @param {Callback} onWelcomeScreenUpdate - Callback to handle welcomeScreen update
 */
const WelcomeScreenOptions = (props) => {
	
	const handleTitleChange = (event) => {
		props.onWelcomeScreenUpdate({
			title: event.target.value,
			description: props.settings?.welcomeScreen?.description
		})
	};

	const handleDescriptionChange = (event) => {
		props.onWelcomeScreenUpdate({
			title: props.settings?.welcomeScreen?.title,
			description: event.target.value
		});
	};

	return (
		<div>
			<h2>Ecran d'Accueil</h2>
			<Row gutter={16}>
				<Col span={12}>
					<Input placeholder={`Bonjour ${props.client.name}`} style={{ 'width': '100%' }} onChange={(event) => handleTitleChange(event)} />
				</Col>
			</Row>
			<br />
			<Row gutter={16}>
				<Col span={12}>
					<Input placeholder='Installez-vous et préparez-vous pour le visionnage de votre séance' onChange={(event) => handleDescriptionChange(event)} />
				</Col>
			</Row>
		</div>
	);
};

/**
 * SettingRecap
 * @param {Object} client - Current client
 * @param {Object} settings - Settings of the diaporama
 */
const SettingRecap = (props) => {
  return (
    <div className={Center}>
      <h2>Paramètres du Diaporama</h2>
      <Row justify='center' gutter={16}>
        <Col>
          <span className={Option}>
            Musique :
            <span className={OptionValue}>
              { props.settings.music?.name ? props.settings.music.name : 'Aucune' }
            </span>
          </span>
        </Col>
        <Col>
          <span className={Option}>
            Temps d'apparition :
            <span className={OptionValue}>
              { !props.settings.timing ? '/' : Math.round(props.settings.timing?.value) + ' sec' }
            </span>
          </span>
        </Col>
			</Row>
			<Row justify='center' gutter={16}>
				<Col>
					<Row justify='center'>
						<span className={Option}>Ecran d'accueil :</span>
					</Row>
					<Row>
						<span className={Option}>
							<span className={OptionValue}>
								{ props.settings.welcomeScreen?.title ? props.settings.welcomeScreen?.title : `Bonjour ${props.client.name}` }
								<br />
								{ props.settings.welcomeScreen?.description ? props.settings.welcomeScreen?.description : 'Installez-vous et préparez-vous pour le visionnage de votre séance' }
							</span>
						</span>
					</Row>
					{/* </span> */}
				</Col>
			</Row>
    </div>
  );
};

/**
 * DiaporamaSettingsEditor
 * @param {Boolean} show - Weather or not the modal is visible
 * @param {Object} client - Current client
 * @param {Object} settings - Diaporama settings about music and timing
 * @param {Integer} nbImage - Nb of images in diaporama
 * @param {Callback} onUpdateSettings - Callback to handle setting update
 * @param {Callback} onLaunch - Callback to handle diaporama launch
 * @param {Callback} onClose - Callback to handle close in modal
 */
const DiaporamaSettingsEditor = (props) => {
  return (
    <Modal
      visible={props.show}
      title='Paramètres du Diaporama'
      footer={
        <FooterButtons
          canLaunch={props.settings.music && props.settings.timing}
          onLaunch={() => props.onLaunch()}
          onClose={() => props.onClose()}
        />
      }
      width={'800px'}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
    >
      <MusicOptions
        settings={props.settings}
        onMusicUpdate={(music) => props.onUpdateSettings({ ...props.settings, music })}
      />
      <br /><br />
      <TimingOptions
        settings={props.settings}
        nbImage={props.nbImage}
        onTimingUpdate={(timing) => props.onUpdateSettings({ ...props.settings, timing })}
      />
			<br />
      <WelcomeScreenOptions
				client={props.client}
        settings={props.settings}
        onWelcomeScreenUpdate={(welcomeScreen) => props.onUpdateSettings({ ...props.settings, welcomeScreen })}
      />
      <br />
      <SettingRecap client={props.client} settings={props.settings} />
    </Modal>
  );
};

export default DiaporamaSettingsEditor;
