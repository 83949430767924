import React from 'react';
import { Switch } from 'antd';
import StickyButtonContainer from 'components/StickyButtonContainer';
import Button from 'components/Button';
import { ButtonItem } from './CompareModeHeader.module.css';

/**
 * CompareModeHeader
 * @param {Array} selectedImages - List of selected images
 * @param {Callback} onImageLike - Callback to handle image like
 * @param {Callback} onImageWithdrawal - Callback to handle image withdrawal
 * @param {Callback} onModeSwitch - Callback to handle mode switch 
 * @param {Callback} onOverviewSwitch - Callback to handle overview switch
 */
 const CompareModeHeader = (props) => {
  return (
    <div>
      <StickyButtonContainer title='CompareModeHeader'>
        <Button
          type='default'
          className={ButtonItem}
          onClick={() => props.onModeSwitch()}
        >
          Retour
        </Button>
        <Button
          type={props.selectedImages.length && props.selectedImages[0].isLiked ? 'primary' : 'default'}
          className={ButtonItem}
          onClick={() => props.onImageLike()}
        >
          { !props.selectedImages[0]?.isLiked ? 'J\'aime' : 'Je n\'aime plus' }
        </Button>
        <Button
          type={props.selectedImages.length && !props.selectedImages[0].isLiked ? 'primary' : 'default'}
          className={ButtonItem}
          onClick={() => props.onImageWithdrawal()}
        >
          Retirer
        </Button>
      </StickyButtonContainer>
      <Switch
        checkedChildren="Vue d'ensemble"
        unCheckedChildren="Vue d'ensemble"
        style={{ marginTop: '16px' }}
        onChange={() => props.onOverviewSwitch()}
      >
        Vue d'ensemble
      </Switch>
    </div>
  );
};

export default CompareModeHeader;

