import React, { useState } from 'react';
import request from 'utils/request';
import DefaultMode from './DefaultMode';
import CompareMode from './CompareMode';

/**
 * ShootingPhotos
 * @param {Object} user - Current user
 * @param {Object} client - Current client
 * @param {Integer} shootingId - Current shooting id
 * @param {Object} shooting - Current shooting
 */
const ShootingPhotos = (props) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [mode, setMode] = useState('default');

  const switchToDefaultMode = () => {
    setMode('default');
  };

  const switchToCompareMode = () => {
    setMode('compare');
  };

  const updateImageInDb = (image) => {
    request.putDataWithAuth(
      `${request.API_ENDPOINT_URL}/images/${image.id}`,
      image,
      props.user.jwt
    );
  };

  return (
    <div>
      {
        mode === 'default' ?
            <DefaultMode
              user={props.user}
              client={props.client}
              shootingId={props.shootingId}
              shooting={props.shooting}
              selectedImages={selectedImages}
              onImageUpdate={(image) => updateImageInDb(image)}
              onImageSelection={(newSelection) => setSelectedImages(newSelection)}
              onImageUnselection={() => setSelectedImages([])}
              onModeSwitch={() => switchToCompareMode()}
            />
          :
            <CompareMode
              selectedImages={selectedImages}
              onImageUpdate={(image) => updateImageInDb(image)}
              onImageUnselection={() => setSelectedImages([])}
              onModeSwitch={() => switchToDefaultMode()}
            />
      }
    </div>
  );
}

export default ShootingPhotos;
