import React, { useState } from 'react';
import { Card, Row, Col, Form, Input, Modal } from 'antd';
import Button from 'components/Button';
import notification from 'utils/notification';
import request from 'utils/request';
import appLogs from 'utils/appLogs';
import { Container } from './ExtraOrder.module.css';

const sourceType = {
  QTE: 'qte',
  TTC: 'ttc',
  TTL: 'ttl'
};

/**
 * ExtraLine
 * @param {Object} user - Current user
 * @param {Integer} shootingId - Current shootingId
 * @param {Object} shooting - Current shooting
 * @param {Callback} onExtraCreation - Callback to handle extra creation
 */
const ExtraLine = (props) => {
  const [price, setPrice] = useState({ qte: 1, ttc: 0, ttl: 0 });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleExtraCreationSuccess = (result) => {
    notification.success('Félicitations !', 'L\'extra a bien été créé');
    props.onExtraCreation(result);
    setLoading(false);
  };

  const handleExtraCreationFailure = (error) => {
    notification.error('Impossible de créer un extra', 'Veuillez ré-essayer plus tard');
    appLogs.logError({ function: 'handleExtraCreationFailure', error });
    setLoading(false);
  };

  const handleFormSubmit = (data) => {
    const extra = {
      shootingId: props.shootingId,
      description: data.description,
      quantity: data[sourceType.QTE],
      price: {
        ttc: data[sourceType.TTC],
        ttl: data[sourceType.TTC] * data[sourceType.QTE]
      }
    };

    setLoading(true);
    request.postDataWithAuthInline(`${request.API_ENDPOINT_URL}/extras`, extra, props.user.jwt, handleExtraCreationSuccess, handleExtraCreationFailure);
  };

  const updatePrice = (source, event) => {
    price[source] = parseFloat(event.target.value);
    if (isNaN(price[source])) {
      price[source] = 0;
    }

    if (source === sourceType.QTE) {
      price.ttl = price.ttc * price.qte;
    } else if (source === sourceType.TTC) {
      price.ttl = price.ttc * price.qte;
    } else if (source === sourceType.TTL) {
      price.ttc = Math.round((price.ttl / price.qte));
    }
    setPrice(price);
    form.setFieldsValue(price);
  };

  return (
    <Card className={Container}>
      <Form
        name='extra-line'
        layout='vertical'
        form={form}
        initialValues={price}
        onFinish={handleFormSubmit}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Description'>
              <Form.Item name='description'>
                <Input placeholder='Description' suffix=' ' />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label='Quantitée'>
              <Form.Item name={sourceType.QTE}>
                <Input
                  placeholder='1'
                  suffix=' '
                  onChange={(event) => updatePrice(sourceType.QTE, event)}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label='Prix'>
              <Form.Item name={sourceType.TTC}>
                <Input
                  value={price.ttc}
                  placeholder='100'
                  suffix='€'
                  onChange={(value) => updatePrice(sourceType.TTC, value)}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label='Prix Total'>
              <Form.Item name={sourceType.TTL}>
                <Input
                  value={price.ttl}
                  placeholder='100'
                  suffix='€'
                  onChange={(value) => updatePrice(sourceType.TTL, value)}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label='Ajouter'>
              <Button htmlType='submit' type={'default'} loading={loading}>Ajouter</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

/**
 * ExtraList
 * @param {Object} shooting - Current shooting
 * @param {Callback} onDelete - Callback to handle extra deletion
 */
const ExtraList = (props) => {

  const confirmDeleteExtra = (extra) => {
    Modal.confirm({
      title: 'Supprimer cet extra',
      content: 'Etes-vous sûr de vouloir supprimer cet extra ?',
      okText: 'Oui, supprimer',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        props.onDelete(extra);
      }
    });
  };

  if (!props.shooting.extraList.length) {
    return (
      <div></div>
    );
  };

  return (
    <Card className={Container}>
      <h3>Les extras</h3>
      <Row gutter={16}>
        <Col span={8}>
          <p><b>Description</b></p>
        </Col>
        <Col span={2}>
          <p><b>Quantitée</b></p>
        </Col>
        <Col span={2}>
          <p><b>Prix</b></p>
        </Col>
        <Col span={2}>
          <p><b>Prix Total</b></p>
        </Col>
        <Col span={2}>
          <p><b>Action</b></p>
        </Col>
      </Row>
      {
        props.shooting.extraList.map((extra) => (
          <Row gutter={16}>
            <Col span={8}>
              <p>{ extra.description }</p>
            </Col>
            <Col span={2}>
              <p>{ extra.quantity }</p>
            </Col>
            <Col span={2}>
              <p>{ extra.price.ttc } €</p>
            </Col>
            <Col span={2}>
              <p>{ extra.price.ttl } €</p>
            </Col>
            <Col span={2}>
              <p style={{ cursor: 'pointer', color: '#F47174' }} onClick={() => confirmDeleteExtra(extra)}>Supprimer</p>
            </Col>
          </Row>
        ))
      }
    </Card>
  );
};

/**
 * ExtraOrder
 * @param {Object} user - Current user
 * @param {Integer} shootingId - Current shootingId
 * @param {Object} shooting - Current shooting
 * @param {Callback} onExtraCreation - Callback to handle extra creation
 */
const ExtraOrder = (props) => {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleExtraDeleteSuccess = (result) => {
    notification.success('Félicitations !', 'L\'extra a bien été supprimé');
  };

  const handleExtraDeleteFailure = (error) => {
    appLogs.logError({ function: 'handleExtraDeleteFailure', error });
  };

  const onExtraDelete = (extra) => {
    console.log(extra);
    request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/extras/${extra.id}`, props.user.jwt, handleExtraDeleteSuccess, handleExtraDeleteFailure);
    props.shooting.extraList = props.shooting.extraList.filter((item) => item.id !== extra.id);
    setRefresh(!refresh);
  };

  const onExtraCreation = (extra) => {
    props.shooting.extraList.push(extra);
    setShow(false);
    props.onExtraCreation();
  };

  return (
    <div>
      <ExtraList
        shooting={props.shooting}
        onDelete={(extra) => onExtraDelete(extra)}
      />
      {
        show &&
          <ExtraLine
            user={props.user}
            shootingId={props.shootingId}
            shooting={props.shooting}
            onExtraCreation={(extra) => onExtraCreation(extra)}
          />
      }
      <Button type='default' onClick={() => setShow(!show)}>+ Ajouter une ligne</Button>
    </div>
  );
};

export default ExtraOrder;
