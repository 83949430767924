import React, { useState } from 'react';
import { Divider, InputNumber } from 'antd';
import Button from 'components/Button';
import { ButtonWrapper } from './ObjectDefinition.module.css';

/**
 * CustomSizeForm
 * @param {Callback} onObjectDefinition - Callback to handle object definition
 */
const CustomSizeForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(0);

  const handleButtonClick = (width) => {
    setLoading(true);
    props.onObjectDefinition(width);
    setTimeout(() => setLoading(false), 1000);
  };

  const onChange = (value) => {
    setWidth(value);
  };

  return (
    <div style={{ width: '100%' }}>
      <InputNumber
        min={1}
        max={1000}
        defaultValue={29.7}
        onChange={(value) => onChange(value)}
        style={{ width: '100%' }}
      />
      <Button
        type='default'
        loading={loading}
        onClick={() => handleButtonClick(width)}
        className={ButtonWrapper}
      >
        Calibrer (en cm)
      </Button>
    </div>
  );
};

/**
 * ObjectDefinition
 * @param {Callback} onObjectDefinition - Callback to handle object definition
 * @param {Callback} onStartOver - Callback to handle start over
 */
const ObjectDefinition = (props) => {
  const [loading, setLoading] = useState(false);
  const A4sheetWidth = 29.7;

  const handleButtonClick = (width) => {
    setLoading(true);
    props.onObjectDefinition(width);
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <div style={{ width: '100%' }}>
      <p>Définir la taille de l'objet</p>
      <Button
        type='primary'
        loading={loading}
        onClick={() => handleButtonClick(A4sheetWidth)}
        className={ButtonWrapper}
      >
        Feuille A4
      </Button>
      <Divider />
      <CustomSizeForm
        onObjectDefinition={(value) => props.onObjectDefinition(value)}
      />
      <Divider />
      <Button
        type='link'
        onClick={() => props.onStartOver()}
        className={ButtonWrapper}
      >
        Recommencer
      </Button>
    </div>
  );
};

export default ObjectDefinition;
