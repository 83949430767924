import React from 'react';
import Button from 'components/Button';
import { EstimationWrapper, ButtonWrapper } from './CalibrationValidation.module.css';

/**
 * CalibrationValidation
 * @param {Object} context - Current context
 * @param {Callback} onCalibrationValidation - Callback to handle calibration validation
 * @param {Callback} onStartOver - Callback to handle start over
 */
const CalibrationValidation = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <p>Résultat de la calibration</p>
      <div className={EstimationWrapper}>
        <p>
          Estimation de la taille du mur<br />
          <b>{ Math.round(props.context.info.size.width) / 100 } m x { Math.round(props.context.info.size.height) / 100 } m</b>
        </p>
      </div>
      <Button
        type='primary'
        onClick={() => props.onCalibrationValidation()}
        className={ButtonWrapper}
      >
        Valider
      </Button>
      <Button
        type='link'
        onClick={() => props.onStartOver()}
        className={ButtonWrapper}
      >
        Recommencer
      </Button>
    </div>
  );
};

export default CalibrationValidation;
