import React, { useState } from 'react';
import { Checkbox } from 'antd';
import StickyButtonContainer from 'components/StickyButtonContainer';
import Button from 'components/Button';
import { ButtonContainer, ButtonItem } from './OrdersHeader.module.css';

/**
 * OrdersHeader
 * @param {Integer} clientId - Current client id
 * @param {Integer} shootingId - Current shooting id
 * @param {Enumerator} productType - Types of products
 * @param {Array} selectedImages - List of selected images
 * @param {Array} selectedScenes - List of selected scenes
 * @param {Callback} setShowOrderModal - Callback to handle 'Commander' click
 * @param {Callback} onshowPhotoNumberChange - Callback to handle showPhotoNumber change
 * @param {Callback} onOrder - Callback to handle order creation
 */
 const OrdersHeader = (props) => {
  const [showPhotoNumber, setShowPhotoNumber] = useState(false);

  const handleOrder = () => {
    if (props.selectedImages.length) {
      props.setShowOrderModal(true);
    } else if (props.selectedScenes.length) {
      props.selectedScenes.forEach((scene) => {
        if (!scene.config.pictureList.length) {
          return (false);
        }
        scene.config.pictureList.forEach((picture) => {
          const choosenProduct = { type: props.productType.FRAME, ...picture.frame };
          const frameConfigIndex = picture.frame.currentDimension;
          const imageList = [ picture.photo ];

          if (!picture.show || !picture.photo || !picture.photo.photo) {
            return (false);
          }
          props.onOrder(choosenProduct, frameConfigIndex, imageList);
        });
      });
    };
  }

  const selectionIsCorrect = () => {
    if (!props.selectedImages.length && !props.selectedScenes.length) {
      return (false);
    }
    if (props.selectedImages.length && props.selectedScenes.length) {
      return (false);
    }
    return (true);
  };

  const handleShowPhotoNumberchange = () => {
    setShowPhotoNumber(!showPhotoNumber);
    props.onshowPhotoNumberChange(!showPhotoNumber);
  };

  return (
    <div>
      <h2>Mes commandes</h2>
      <p> Composez vos commandes ici !</p>
      <StickyButtonContainer title='OrdersHeader'>
        <Button
          type={!props.selectedImages.length && !props.selectedScenes.length ? 'primary' : 'default'}
          className={ButtonItem}
          onClick={() => window.location = `/editor/${props.clientId}/${props.shootingId}`}
        >
          Créer une scène
        </Button>
        <Button
          type={!selectionIsCorrect() ? 'default' : 'primary'}
          className={ButtonItem}
          disabled={!selectionIsCorrect()}
          onClick={handleOrder}
        >
          Commander
        </Button>
        <Button
          type={'default'}
          className={ButtonItem}
          disabled={!selectionIsCorrect() || props.selectedScenes.length > 1}
          onClick={() => window.location = `/editor/${props.clientId}/${props.shootingId}/${props.selectedScenes[0].id}`}
        >
          Ouvrir dans l'editeur
        </Button>
      </StickyButtonContainer>
      <br />
      <div className={ButtonContainer}>
        <Checkbox
          onChange={() => handleShowPhotoNumberchange()}
        >
          Afficher le numéro de photo
        </Checkbox>
      </div>
    </div>
  );
};

export default OrdersHeader;
