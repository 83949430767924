import React from 'react';
import { Modal } from 'antd';
import Button from 'components/Button';

const computeScreenSize = () => {
  const width = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )
  const height = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  )

  return ({ height, width});
};

/**
 * VideoModal
 * @param {Boolean} show - Wether or not to display the modal
 * @param {Integer} onboardingStep - Step to which the user is in the onboarding process
 * @param {Callback} onOk - Callback to handle 'ok' click
 * @param {Callback} onCancel - Callback to handle 'cancel' click
 */
const VideoModal = (props) => {
  const ratio = 1920 / 1080;
  const videoSize = {
    height: computeScreenSize().height / 2,
    width: computeScreenSize().height / 2 * ratio
  };
  const titleList = [
    'Regardez cette vidéo pour vous découvrir votre page Profil (~1 min)',
    'Regardez cette vidéo pour vous découvrir votre page Produit (~2 min)',
    'Regardez cette vidéo pour vous découvrir votre page Client (~5 min)'
  ];
  const videoList = [
    // 'https://player.vimeo.com/video/602720706?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e2cd805a0b',
    'https://www.loom.com/embed/c9623ae6b14942d6ad3401f9bdea61d9',
    // 'https://player.vimeo.com/video/602722467?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=0bc7e08fdc',
    'https://www.loom.com/embed/60347e9394974687af6c45a85d0960f8',
    // 'https://player.vimeo.com/video/602729103?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=0c3b29289d'
    'https://www.loom.com/embed/84d1a707fa4c446c9867bed736cf3b4e'
  ];


  return (
    <Modal
      title={titleList[props.onboardingStep]}
      visible={props.show}
      width={videoSize.width + 64}
      onOk={() => props.onOk()}
      onCancel={() => props.onCancel()}
    >
      <div>
        <iframe
          src={videoList[props.onboardingStep]}
          width={videoSize.width}
          height={videoSize.height}
          frameborder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowfullscreen
          title='Cr&amp;eacute;ation de mon profil'
        >
        </iframe>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          type='link'
          onClick={() => props.onOk()}
        >
          Passer la vidéo de présentation
        </Button>
      </div>
    </Modal>
  );
};

export default VideoModal;
