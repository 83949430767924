import React, { useState } from 'react';
import { Card, Row, Col, Alert } from 'antd';
import { Form, Input } from 'antd';
import Button from 'components/Button';
import { THEME_COLOR } from 'configs/AppConfig';
import auth from 'utils/auth';

const backgroundStyle = {
  backgroundColor: THEME_COLOR.lightest
};

/**
 * Signup
 * @param {Callback} onSignup - Callback to handle account creation
 */
const Signup = (props) => {
  const [alert, setAlert] = useState({ show: false, message: '' });
  const [loading, setLoading] = useState(false);
  const alertStyle = { display: `${ alert.show ? '' : 'none' }` };

  const handleSignupSuccess = (result) => {
    const { jwt, user } = result;

    props.onSignup(jwt, user);
    return (true);
  };

  const handleSignupFailure = (result) => {
    const error = {
      EMAIL_TAKEN: 'Auth.form.error.email.taken',
      EMAIL_INVALID: 'Auth.form.error.email.format'
    };
    const message = {
      EMAIL_TAKEN: 'Un compte existe déjà avec cette adresse email',
      EMAIL_INVALID: 'Veuillez rentrer un email valide',
      DEFAULT: 'Une erreur s\'est produite pendant la création de votre compte, merci de ré-essayer'
    };
    const errorCode = result?.data[0]?.messages[0]?.id;

    if (errorCode === error.EMAIL_TAKEN) {
      setAlert({ show: true, message: message.EMAIL_TAKEN });
    } else if (errorCode === error.EMAIL_INVALID) {
      setAlert({ show: true, message: message.EMAIL_INVALID });
    } else {
      setAlert({ show: true, message: message.DEFAULT });
    }
    setLoading(false);
    setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    return (false);
  };

  const passwordIsCorrect = (data) => {
    const message = {
      PWD_DIFFER: 'La confirmation du mot de passe doit être identique',
      PWD_TOO_SHORT: 'Le mot de passe doit faire au moins 6 charactères'
    };

    if (data.password !== data.confirm) {
      setAlert({ show: true, message: message.PWD_DIFFER });
      setTimeout(() => setAlert({ show: false, message: '' }), 5000);
      return (false);
    }
    if (data.password.length < 6) {
      setAlert({ show: true, message: message.PWD_TOO_SHORT });
      setTimeout(() => setAlert({ show: false, message: '' }), 5000);
      return (false);
    }
    return (true);
  };

  const verifySignup = (data) => {
    if (!passwordIsCorrect(data)) {
      return (false);
    }
    setLoading(true);
    auth.setLocal('pwd', data.password);
    auth.register(data.email, data.password, handleSignupSuccess, handleSignupFailure);
    return (true);
  };

  return (
    <div className='h-100' style={backgroundStyle}>
      <div className='container d-flex flex-column justify-content-center h-100'>
        <Row justify='center'>
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className='my-4'>
                <Alert message={alert.message} type='error' showIcon style={alertStyle} />
                <div className='text-center'>
                  <img className='img-fluid' src='/img/logo.png' alt='' style={{ maxWidth: '100px' }} />
                  <p>Vous avez déjà un compte ? <a href='/auth/login'>connectez-vous</a></p>
                </div>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Form layout='vertical' name='signup-form' onFinish={verifySignup}>
                      <Form.Item name='email' label='Email'>
                        <Input placeholder='prenom.nom@mail.com' />
                      </Form.Item>
                      <Form.Item name='password' label={'Mot de passe'}>
                        <Input.Password placeholder='*****' />
                      </Form.Item>
                      <Form.Item name='confirm' label={'Confirmation'}>
                        <Input.Password placeholder='*****' />
                      </Form.Item>
                      <p style={{ fontSize: 'x-small' }}>
                        En cliquant sur "Créer un compte" vous acceptez les <a href='/doc/cgv.pdf' target='_blank'>conditions générales de vente</a>
                      </p>
                      <Form.Item>
                        <Button type='primary' htmlType='submit' loading={loading} block>
                          Créer un compte
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Signup;
