import React from 'react';
import { Switch, Route, } from 'react-router-dom';
import UploadViews from 'Views/upload-views';

const UploadLayout = () => {
  return (
    <div className='upload-container'>
      <Switch>
        <Route path='/upload/:uploadCode'>
          <UploadViews />
        </Route>
      </Switch>
    </div>
  );
};

export default UploadLayout;
