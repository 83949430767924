import { EDITOR } from 'configs/AppConfig';

const computeCanva = () => {
  const width = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  ) - EDITOR.LEFT_PANEL_WIDTH.PIXEL_FORMAT;
  const height = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  ) - EDITOR.TOP_NAV_HEIGHT.PIXEL_FORMAT;

  return ({ height, width });
};

const getImageSize = (photoUrl, canvasSize) => {
  const photo = new Image();
  const canvas = {
    ratio: canvasSize.width / canvasSize.height,
    size: canvasSize
  };

  photo.src = photoUrl;
  return (
    new Promise((resolve) => {
      photo.onload = () => {
        const ratio = photo.naturalWidth / photo.naturalHeight;
        const size = {
          height: 0,
          width: 0
        };
        let scale = 1;

        if (ratio >= canvas.ratio) {
          scale = canvas.size.width / photo.naturalWidth;
        } else {
          scale = canvas.size.height / photo.naturalHeight;
        }
        size.height = photo.naturalHeight * scale;
        size.width = photo.naturalWidth * scale;
        resolve(size);
      };
    })
  );
};

const drawLine = (context, from, to) => {
  context.beginPath();
  context.moveTo(from.x, from.y);
  context.lineTo(to.x, to.y, 6);
  context.strokeStyle = '#000000';
  context.stroke();
};

const drawPoint = (context, point) => {
  const length = 3;

  context.beginPath();
  context.moveTo(point.x - length, point.y - length);
  context.lineTo(point.x + length, point.y + length);
  context.moveTo(point.x - length, point.y + length);
  context.lineTo(point.x + length, point.y - length);
  context.strokeStyle = '#000000';
  context.stroke();
};

const drawHorizontal = (context, point, canvasSize) => {
  const from = {
    x: 0,
    y: point.y
  };
  const to = {
    x: canvasSize.width,
    y: point.y
  };

  drawLine(context, from, to);
};

const clearCanvas = (context, canvasSize) => {
  context.clearRect(0, 0, canvasSize.width, canvasSize.height);
};

const drawing = {
  computeCanva,
  getImageSize,
  drawLine,
  drawPoint,
  drawHorizontal,
  clearCanvas
};

export default drawing;
