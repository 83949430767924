const emailIsCorrect = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (!emailRegex.test(email)) {
    return (false);
  }
  return (true);
};

const regex = { emailIsCorrect };
export default regex;
