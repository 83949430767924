import React from 'react';
import { Redirect } from 'react-router-dom';
import auth from 'utils/auth';

/**
 * Confirmed
 * @param {Callback} onLogin - Callback to handle user ligon
 */
const Confirmed = (props) => {
  const user = auth.getUser();
  const pwd = auth.getLocal('pwd');

  const handleSuccess = (result) => {
    const { jwt, user } = result;

    auth.removeLocal('pwd');
    props.onLogin(jwt, user);
    return (<Redirect to='/' />);
  };

  const handleFailure = () => {
    return (<Redirect to='/auth/login' />);
  };

  auth.login(user.usr?.email, pwd, handleSuccess, handleFailure);
  return (
    <Redirect to='/' />
  );
}

export default Confirmed;
