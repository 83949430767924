import React, { useState } from 'react';
import { Button } from 'antd';
import { THEME_COLOR } from 'configs/AppConfig';

const MyButton = (props) => {
  const [hover, setHover] = useState(false);
  const type = props.type ? props.type : 'default';
  const style = {
    primary: {
      backgroundColor: THEME_COLOR.default,
      borderColor: THEME_COLOR.default,
      color: 'white'
    },
    default: {
      backgroundColor: 'white',
      borderColor: THEME_COLOR.lightest,
      color: THEME_COLOR.default
    },
    link: {
      backgroundColor: 'white',
      borderColor: 'white',
      color: THEME_COLOR.default
    }
  };
  const hoverStyle = {
    primary: {
      backgroundColor: THEME_COLOR.lighter,
      borderColor: THEME_COLOR.lighter,
      color: 'white'
    },
    default: {
      backgroundColor: `${THEME_COLOR.lightest}50`,
      borderColor: `${THEME_COLOR.lightest}50`,
      color: THEME_COLOR.default
    },
    link: {
      backgroundColor: THEME_COLOR.lightest,
      borderColor: THEME_COLOR.lightest,
      color: THEME_COLOR.default
    }
  };

  return (
    <Button
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={ hover ? { ...hoverStyle[type], ...props.style } : { ...style[type], ...props.style } }
    >
      {props.children}
    </Button>
  );
};

export default MyButton;
