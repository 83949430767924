import React, { useState } from 'react';
import { Card, Form, Row, Col, Input, Upload, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import photo from 'utils/photo';
import Frame from '../Edit/Frame';
import Album from '../Edit/Album';
import Custom from '../Edit/Custom';
import request from 'utils/request';

const { Option } = Select;

const Add = (props) => {
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState('default');

  const handleLogoUploadSuccess = (result) => {
    setImage(result.data[0].url);
    setLoading(false);
  };

  const handleLogoUploadFailure = (error) => {
    if (error.typeIsIncorrect) {
      notification.error('Format de photo incorrect', 'Vérifiez que le format est bien .png, .jpg ou .jpeg');
    } else {
      notification.error('Impossible d\'upload la photo', 'Veuillez réessayer');
    }
    setLoading(false);
    appLogs.logError({ function: 'handleLogoUploadFailure', error });
  };

  const uploadLogo = (image) => {
    setLoading(true);
    photo.uploadPhoto(image, props.user.jwt, handleLogoUploadSuccess, handleLogoUploadFailure);
  }

  const handleProductCreationSuccess = (result) => {
    const message = {
      DEFAULT: 'Le produit a bien été créé'
    };

    setLoading(false);
    props.triggerAlert({ show: true, type: 'success', message: message.DEFAULT });
    props.onSave();
  }

  const handleProductCreationFailure = (error) => {
    const message = {
      DEFAULT: 'Impossible de créer le produit'
    };

    setLoading(false);
    props.triggerAlert({ show: true, type: 'error', message: message.DEFAULT });
    appLogs.logError({ function: 'handleLogoUploadFailure', error });
  }

  const productIsCorrect = (product) => {
    const message = {
      NO_NAME: 'Veuillez renseigner un nom',
      NO_SUPPLIER: 'Veuillez renseigner un fournisseur',
      NO_TYPE: 'Veuillez renseigner un type',
      NO_DIMENSIONS: 'Veuillez ajouter des dimensions'
    };

    if (!product.name) {
      props.triggerAlert({ show: true, type: 'error', message: message.NO_NAME });
      return (false);
    } else if (!product.supplier) {
      props.triggerAlert({ show: true, type: 'error', message: message.NO_SUPPLIER });
      return (false);
    } else if (product.type === 'default') {
      props.triggerAlert({ show: true, type: 'error', message: message.NO_TYPE });
      return (false);
    } else if (product.type === props.type.FRAME && !product.specs.dimensions.length) {
      props.triggerAlert({ show: true, type: 'error', message: message.NO_DIMENSIONS });
      return (false);
    }
    return (true);
  }

  const handleFormSubmit = (values) => {
    const product = {
      name: values.name,
      photo: image,
      type: values.type,
      supplier: values.supplier,
      specs: {},
      email: props.user.usr.email
    };

    if (product.type === props.type.FRAME) {
      product.specs = { dimensions: [] };
      values.dimensions?.forEach((dimension) => {
        product.specs.dimensions.push({
          size: {
            width: dimension['size-width'],
            height: dimension['size-height']
          },
          price: {
            buy: dimension['price-buy'],
            sell: dimension['price-sell']
          }
        });
      });
    } else if (product.type === props.type.ALBUM) {
      product.specs['size'] = values.size;
      product.specs['price'] = {
        buy: values['price-buy'],
        sell: values['price-sell']
      }
    } else if (product.type === props.type.CUSTOM) {
      product.specs['description'] = values.description;
      product.specs['price'] = {
        buy: values['price-buy'],
        sell: values['price-sell']
      }
    }
    if (!productIsCorrect(product)) {
      return (false);
    }
    setLoading(true);
    request.postDataWithAuth(`${request.API_ENDPOINT_URL}/products`, product, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleLogoUploadFailure(result));
          }
          return (handleProductCreationSuccess(result));
        },
        (error) => handleProductCreationFailure(error)
      );
  };

  return (
    <Card>
      <Form
        name='add'
        layout='vertical'
        initialValues={{
          type: 'default'
        }}
        onFinish={handleFormSubmit}
      >
        <Row justify='space-between'>
          <h2>Ajout du produit</h2>
          <div>
            <Button type='link' onClick={props.onCancel}>Annuler</Button>
            <Button type='primary' htmlType='submit' loading={loading}>Enregistrer</Button>
          </div>
        </Row>
        <br /><br />
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Nom du produit'>
              <Form.Item name='name'>
                <Input />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Photo'>
            <Upload
              name='avatar'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              beforeUpload={uploadLogo}
              onPreview={photo.handlePreview}
            >
              {
                image !== '' ?
                  <img src={image} alt='avatar' style={{ width: '100px' }} />
                :
                  <div>
                    <PlusOutlined />
                    <div className='ant-upload-text'>Upload</div>
                  </div>
              }
            </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Fournisseur'>
              <Form.Item name='supplier'>
                <Input />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Form.Item label='Type de produit'>
            <Form.Item name='type'>
              <Select style={{ width: 200 }} onChange={(value) => setProductType(value)}>
                <Option value={'default'} disabled>Choisir un type</Option>
                <Option value={props.type.FRAME}>Agrandissement</Option>
                <Option value={props.type.ALBUM}>Album</Option>
                <Option value={props.type.CUSTOM}>Personnalisé</Option>
              </Select>
            </Form.Item>
          </Form.Item>
        </Row>
        { productType === props.type.FRAME && <Frame /> }
        { productType === props.type.ALBUM && <Album /> }
        { productType === props.type.CUSTOM && <Custom /> }
      </Form>
    </Card>
  );
};

export default Add;
