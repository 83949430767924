import React from 'react';
import { Form, Row, Col, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/Button';

const Frame = () => {
  return (
    <Form.List name='dimensions'>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <Row key={field.key} gutter={16}>
                <Col span={8}>
                  <Form.Item label='Dimensions'>
                    <Row gutter={16}>
                      <Col span={11}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'size-width']}
                          fieldKey={[field.fieldKey, 'size-width']}
                        >
                          <Input addonAfter='cm' />
                        </Form.Item>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={11}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'size-height']}
                          fieldKey={[field.fieldKey, 'size-height']}
                        >
                          <Input addonAfter='cm' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={`Prix d'achat`}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'price-buy']}
                      fieldKey={[field.fieldKey, 'price-buy']}
                    >
                      <Input addonAfter='€' />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Prix de Vente'>
                    <Form.Item
                      {...field}
                      name={[field.name, 'price-sell']}
                      fieldKey={[field.fieldKey, 'price-sell']}
                    >
                      <Input addonAfter='€' />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label='Action'>
                    <Form.Item
                      {...field}
                      name={[field.name, `deleteButton-${index}`]}
                      fieldKey={[field.fieldKey, `deleteButton-${index}`]}
                    >
                      <Button type='link' onClick={() => remove(field.name)}>Supprimer</Button>
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => { add() }} className="w-100">
                <PlusOutlined /> Ajouter une dimension
              </Button>
            </Form.Item>
          </div>
        )
      }}
    </Form.List>
  );
};

export default Frame;
