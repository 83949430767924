import React from 'react';
import { Card } from 'antd';

const { Meta } = Card;

/**
 * ClientCard
 * @param {Object} client - Client to display infos
 * @param {Callback} onClientSelection - Callback to handle client selection
 */
const ClientCard = (props) => {
  return (
    <Card
      style={{
        width: 320,
        margin: '16px',
        cursor: 'pointer'
      }}
      actions={[
        <span onClick={() => props.onClientSelection(props.client.id)}>Voir le client</span>,
      ]}
      onClick={() => props.onClientSelection(props.client.id)}
    >
      <Meta
        title={props.client.name}
        description={(props.client.number ? props.client.number : '--')}
      />
    </Card>
  );
};

export default ClientCard;
