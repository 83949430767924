/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import HighlightModal from './HighlightModal';
import OverviewRow from './OverviewRow';
import CompareRow from './CompareRow';
import ListRow from './ListRow';

const mode = {
  DEFAUlT: 'default',
  COMPARE: 'compare',
  OVERVIEW: 'overview'
};

/**
 * ImageRow
 * @param {String} mode - Whether 'list', 'compare' or 'overview'
 * @param {Boolean} showLikedImages - Wether or not to display heart icon (only works in 'compare' mode)
 * @param {Array} imageList - List of images to display
 * @param {Boolean} showPhotoNumber - Wether or not to display photo's file name
 * @param {Array} selectedImages - List of selected images
 * @param {Callback} onImageSelection - Callback to handle image selection
 */
const ImageRow = (props) => {
  const [showHighlight, setShowHighlight] = useState(false);
  const [highlightedImage, setHighlightedImage] = useState(null);

  const isSelected = (id) => {
    for (const image of props.selectedImages) {
      if (image.id === id) {
        return (true);
      }
    }
    return (false);
  };

  const highlightImage = (id) => {
    setShowHighlight(true);
    setHighlightedImage(id);
  };

  return (
    <div>
      <HighlightModal
        showHighlight={showHighlight}
        highlightedImage={highlightedImage}
        onClose={() => setShowHighlight(false)}
      />
      {
        props.mode === mode.OVERVIEW &&
          <OverviewRow
            showLikedImages={props.showLikedImages}
            imageList={props.imageList}
            showPhotoNumber={props.showPhotoNumber}
            isSelected={(id) => isSelected(id)}
            highlightImage={(id) => highlightImage(id)}
            onImageSelection={(image) => props.onImageSelection(image)}
          />
      }
      {
        props.mode === mode.COMPARE &&
          <CompareRow
            showLikedImages={props.showLikedImages}
            imageList={props.imageList}
            showPhotoNumber={props.showPhotoNumber}
            isSelected={(id) => isSelected(id)}
            highlightImage={(id) => highlightImage(id)}
            onImageSelection={(image) => props.onImageSelection(image)}
          />
      }
      {
        props.mode === mode.DEFAULT &&
          <ListRow
            imageList={props.imageList}
            showPhotoNumber={props.showPhotoNumber}
            isSelected={(id) => isSelected(id)}
            highlightImage={(id) => highlightImage(id)}
            onImageSelection={(image) => props.onImageSelection(image)}
          />
      }
    </div>
  );
};

export default ImageRow;
