import request from 'utils/request';
import auth from 'utils/auth';

const jwt = auth.getUser().jwt;

const create = (product, handleSuccess, handleFailure) => {
  request.postDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/products`,
    product,
    jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const get = (id, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/products/${id}`,
    { id },
    jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getByAuthorId = (authorId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/products`,
    { authorId },
    jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const update = (product, handleSuccess, handleFailure) => {
  request.putDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/products/${product.id}`,
    product,
    jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const remove = (id, handleSuccess, handleFailure) => {
  request.deleteDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/products/${id}`,
    jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const Product = {
  create,
  get,
  getByAuthorId,
  update,
  remove
};

export default Product;
