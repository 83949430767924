import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import Client from 'controllers/Client';
import Home from './Home';
import { SpinWrapper } from './UploadViews.module.css';

/**
 * UploadViews
 */
const UploadViews = () => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState({});
  const { uploadCode } = useParams();

  const handleClientAccessSuccess = (result) => {
    const currentClient = result[0];

    if (!currentClient) {
      window.location = '/';
    };
    setClient(currentClient);
    setLoading(false);
  };

  const handleClientAccessFailure = (error) => {
    console.log('> error : ', error);
  };

  useEffect(() => {
    const tmpClient = {
      id: uploadCode.split('-')[0],
      userId: uploadCode.split('-')[1],
      authorId: uploadCode.split('-')[2]
    };

    Client.getWithoutAuth(
      tmpClient,
      (result) => handleClientAccessSuccess(result),
      (error) => handleClientAccessFailure(error)
    );
  }, []);

  if (loading) {
    return (
      <div className={SpinWrapper}>
        <Spin />
      </div>
    );
  }

  return (
    <Home
      client={client}
      userId={uploadCode.split('-')[1]}
    />
  );
};

export default UploadViews;
