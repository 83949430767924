import React, { useState } from 'react';
import { Collapse, Row, Col, Input } from 'antd';
import Button from 'components/Button';
import Order from 'controllers/Order';
import notification from 'utils/notification';
import photo from 'utils/photo';
import appLogs from 'utils/appLogs';
import {
  Image,
  ProductImageContainer,
  ProductImage,
  CarrouselContainer
} from './Summary.module.css';

const { Panel } = Collapse;

const productType = {
  FRAME: 'frame',
  ALBUM: 'album',
  CUSTOM: 'custom'
};

/**
 * CustomCarousel
 * @param {Array} imageList - List of images to display
 */
const CustomCarousel = (props) => {
  const [index, setIndex] = useState(0);
  const maxIndex = props.imageList.length - 1;

  return (
    <div>
      <div
        className={CarrouselContainer}
        onClick={() => setIndex(index + 1 <= maxIndex ? index + 1 : 0)}
      >
        <img src={props.imageList[index].photo} alt={`carousel-${index}`} className={Image} />
        <p>{ photo.getImageName(props.imageList[index]) }</p>
      </div>
      {
        maxIndex > 0 &&
          <div style={{ textAlign: 'center' }}>
            <p>
              Photo : { index + 1 } / { maxIndex + 1 }
              <br />
              Cliquez sur la photo pour afficher la suivante
            </p>
          </div>
      }
    </div>
  );
};

/**
 * Note
 * @param {Object} order - Current order
 * @param {Callback} onNoteUpdate - CAllback to handle note update
 */
const Note = (props) => {
  const [editNote, setEditNote] = useState(false);
  const [note, setNote] = useState(props.order.note ? props.order.note : '');

  const handleOrderUpdateSuccess = () => {
    notification.success('Félicitations !', 'Votre note a bien été enregistrée');
  };

  const handleOrderUpdateFailure = (error) => {
    notification.error('Impossible d\'enregistrer votre note', 'Veuillez ré-essayer plus tard');
    appLogs.logError({
      function: 'handleOrderUpdateFailure',
      component: 'Note',
      order: props.order,
      error
    })
  };

  const handleSave = () => {
    if (editNote) {
      props.order.note = note;
      Order.update(props.order, () => handleOrderUpdateSuccess(), (error) => handleOrderUpdateFailure(error));
    }
    setEditNote(!editNote);
  };

  return (
    <div>
      <p><b>Notes</b></p>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Input
          defaultValue={note}
          disabled={!editNote}
          onChange={(event) => setNote(event.target.value)}
        />
        <Button
          type='default'
          style={{ marginTop: 0, marginLeft: 16 }}
          onClick={() => handleSave()}
        >
          { !editNote ? <span>Modifier</span> : <span>Enregistrer</span> }
        </Button>
      </div>
    </div>
  );
};

/**
 * Summary
 * @param {Object} shooting - Current shooting
 */
const Summary = (props) => {

  const getOrderPrice = (order) => {
    if (order.product.type === productType.FRAME) {
      return (parseFloat(order.dimensions.price.sell).toFixed(2));
    } else if (order.product.type === productType.ALBUM) {
      return (parseFloat(order.product.specs.price.sell).toFixed(2));
    } else if (order.product.type === productType.CUSTOM) {
      return (parseFloat(order.product.specs.price.sell).toFixed(2));
    }
  };

  return (
    <div>
      <Collapse defaultActiveKey={[0]}>
        {
          props.shooting.orderList.map((order, index) => (
            <Panel header={order.product.name} key={index}>
              <Row gutter={16}>
                <Col span={12}>
                  <div>
                    <p><b>Nom du produit</b></p>
                    <p>{ order.product.name }</p>
                  </div>
                  {
                    order.product.type === productType.FRAME &&
                      <div>
                        <br />
                        <p><b>Dimensions</b></p>
                        <p>{ order.dimensions.size.width } x { order.dimensions.size.height } cm</p>
                      </div>
                  }
                  <br />
                  <div>
                    <p><b>Prix</b></p>
                    <p>{ getOrderPrice(order) } €</p>
                  </div>
                  <br />
                  <div>
                    <Note order={order} />
                  </div>
                </Col>
                <Col span={6}>
                  <CustomCarousel imageList={order.imageList} />
                </Col>
                <Col span={6}>
                  <div className={ProductImageContainer}>
                    { order.product.photo !== '' && <img src={order.product.photo} alt='' className={ProductImage} /> }
                  </div>
                </Col>
              </Row>
            </Panel>
          ))
        }
      </Collapse>
    </div>
  );
};

export default Summary;
