import request from './request';
import auth from './auth';

const logError = (msg) => {
  const user = auth.getUser();
  const now = new Date();
  const info = {
    email: user.usr.email,
    when: now.toUTCString(),
    where: window.location.href,
    what: msg
  };

  request.postDataWithAuth(`${request.API_ENDPOINT_URL}/app-logs`, { email: user.usr.email, info }, user.jwt)
    .then((result) => console.log(result))
    .catch((error) => console.log(error));
};

const appLogs = { logError };
export default appLogs;
