import React, { useState, useEffect } from 'react';
import auth from 'utils/auth';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import LeftPanel from './LeftPanel';
import Editor from './Editor';
import { Container, LeftPart, RightPart } from './Editor.module.css';

/**
 * Editor's entry point
 * @param {Callback} onButtonClick - Callback to handle Stripe modal opening
 */
const Home = (props) => {
  const [loading, setLoading] = useState(true);
  const [shootingId, setShootingId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [scene, setScene] = useState({});
  const user = auth.getUser();

  const handleSceneCreationSuccess = (clientId, result) => {
    window.location = `/editor/${clientId}/${result.shootingId}/${result.id}`;
  };

  const handleSceneCreationFailure = (error) => {
    notification.error('Impossible de créer la scene', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleSceneCreationFailure', error });
  };

  const handleSceneUpdateSuccess = (result) => {
    // console.log('> update success');
  };

  const handleSceneUpdateFailure = (error) => {
    notification.error('Impossible d\'enregistrer la scene', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleSceneUpdateFailure', error });
  };

  const updateSceneInDb = () => {
    request.putDataWithAuth(`${request.API_ENDPOINT_URL}/scenes/${scene.id}`, scene, user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleSceneUpdateFailure(result));
          }
          return (handleSceneUpdateSuccess(result));
        },
        (error) => handleSceneUpdateFailure(error)
      );
  };

  const onNewScene = () => {
    window.location = `/editor/${clientId}/${shootingId}`;
  };

  const onSave = (config) => {
    scene.config = config;
    setScene(scene);
    updateSceneInDb();
  };

  const createSceneInDb = async (clientId, shootingId) => {
    const dbScene = {
      email: user.usr.email,
      shootingId: shootingId,
      config: {
        ratio: 1,
        scale: 1,
        coefficient: 1,
        isLiked: false,
        context: {
          url: '',
          size: {
            height: 0,
            width: 0
          }
        },
        pictureList: []
      }
    };

    request.postDataWithAuth(`${request.API_ENDPOINT_URL}/scenes`, dbScene, user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleSceneCreationFailure(result));
          }
          return (handleSceneCreationSuccess(clientId, result));
        },
        (error) => handleSceneCreationFailure(error)
      );
  };

  const alertImpossibleToCreate = (step) => {
    notification.error('Impossible de créer une scène pour cette projection', 'Veuillez réessayer avec une autre projection');
    appLogs.logError({ function: 'alertImpossibleToCreate', step: step });
  };

  const checkShootingOwner = async (clientId, shootingId) => {
    let clientList = [];
    let shooting = {};

    clientList = await request.getDataWithAuth(`${request.API_ENDPOINT_URL}/clients`, { userId: user.usr.id }, user.jwt);
    shooting = await request.getDataWithAuth(`${request.API_ENDPOINT_URL}/shootings`, { id: shootingId }, user.jwt);
    if (
      clientList.statusCode === 400
      || shooting.statusCode === 400
      || !shooting.length
    ) {
      alertImpossibleToCreate(3);
      return (false);
    }
    for (const client of clientList) {
      if (client.id === shooting[0].clientId) {
        createSceneInDb(clientId, shootingId);
        return (true);
      }
    }
    alertImpossibleToCreate(4);
    return (false);
  };

  const createSceneInDbAfterCheck = (path, step) => {
    let clientId = 0;
    let shootingId = 0;

    if (path[step.PAGE_NAME] !== 'editor' || !path[step.SHOOTING_ID]) {
      alertImpossibleToCreate(1);
      return (false);
    }
    clientId = parseInt(path[step.CLIENT_ID]);
    shootingId = parseInt(path[step.SHOOTING_ID]);
    if (isNaN(clientId) || isNaN(shootingId)) {
      alertImpossibleToCreate(2);
      return (false);
    }
    setClientId(clientId);
    setShootingId(shootingId);
    checkShootingOwner(clientId, shootingId);
  };

  const handleSceneAccessSuccess = (result) => {
    if (!result.length) {
      notification.error('Il semble que cette scène n\'existe pas', 'Veuillez réessayer');
    } else {
      setScene(result[0]);
      setLoading(false);
    }
  };

  const handleSceneAccessFailure = (error) => {
    notification.error('Impossible de récupérer la scène', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleSceneAccessFailure', error });
    setLoading(false);
  };

  const getSceneFromDb = (sceneId, shootingId) => {
    const scene = {
      id: sceneId,
      shootingId: shootingId,
      email: user.usr.email
    };

    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/scenes`, scene, user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleSceneAccessFailure(result));
        }
        return (handleSceneAccessSuccess(result));
      })
      .catch((error) => handleSceneAccessFailure(error));
  }

  const urlHasSceneId = (path, step) => {
    let clientId = 0;
    let shootingId = 0;
    let sceneId = 0;

    if (
      path[step.PAGE_NAME] !== 'editor'
      || !path[step.CLIENT_ID]
      || !path[step.SHOOTING_ID]
      || !path[step.SCENE_ID]
    ) {
      return (false);
    }
    clientId = parseInt(path[step.CLIENT_ID]);
    shootingId = parseInt(path[step.SHOOTING_ID]);
    sceneId = parseInt(path[step.SCENE_ID]);
    if (isNaN(clientId) || isNaN(shootingId) || isNaN(sceneId)) {
      return (false);
    }
    return (true);
  };

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const step = {
      PAGE_NAME: 1,
      CLIENT_ID: 2,
      SHOOTING_ID: 3,
      SCENE_ID: 4
    };
    let clientId = 0;
    let shootingId = 0;
    let sceneId = 0;

    if (urlHasSceneId(path, step)) {
      clientId = parseInt(path[step.CLIENT_ID]);
      shootingId = parseInt(path[step.SHOOTING_ID]);
      sceneId = parseInt(path[step.SCENE_ID]);
      setClientId(clientId);
      setShootingId(shootingId);
      getSceneFromDb(sceneId, shootingId);
    } else {
      createSceneInDbAfterCheck(path, step);
    }
  }, [ setScene, setShootingId ]);

  if (loading) {
    return (
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1>Loading ...</h1>
      </div>
    )
  }

  return (
    <div className={Container}>
      <div className={LeftPart}>
        <LeftPanel
          user={user}
          clientId={clientId}
          shootingId={shootingId}
          onButtonClick={props.onButtonClick}
        />
      </div>
      <div className={RightPart}>
        <Editor
          scene={scene.config}
          newScene={() => onNewScene()}
          saveScene={(config) => onSave(config)}
        />
      </div>
    </div>
  );
};

export default Home;
