/*jslint es6 */
import request from 'utils/request';
import auth from 'utils/auth';

const user = auth.getUser();

const getAudioDuration = (file) => {
  const reader = new FileReader();

  return (
    new Promise((resolve) => {
      try {
        reader.onload = (e) => {
          const audio = new Audio(e.target.result);
  
          audio.onloadedmetadata = () => {
            resolve(audio.duration);
          };
        };
        reader.readAsDataURL(file);
      } catch (error) {
        resolve(0);
      }
    })
  );
};

const uploadMusic = (file, handleSucces, handleFailure) => {
  const validFileTypes = ['audio/mpeg'];
  const formData = new FormData();

  if (!validFileTypes.includes(file.type)) {
    handleFailure({ typeIsIncorrect: true, file });
    return (false);
  }
  formData.append('files', file);
  request.postMediaWithAuth(`${request.API_ENDPOINT_URL}/upload`, formData, user.jwt)
    .then(
      (result) => {
        if (result.statusCode === 400) {
          return (handleFailure(result));
        }
        handleSucces(result);
      },
      (error) => handleFailure(error)
    );
  return (false);
};

const Music = {
  uploadMusic,
  getAudioDuration
};

export default Music;
