import React from 'react';
import { Frame } from './FrameObject.module.css';

/**
 * Handle the Frame object
 * @param {Number} index - Picture's index in the pictureList
 * @param {Object} pictureSize - Picture's height and width
 * @param {Object} frame - Frame object
 * @param {Callback} onClick - Callback to trigger the Edit Menu
 */
const FrameObject = (props) => {
  const defaultFramePhoto = 'https://lumina-images.s3.eu-west-3.amazonaws.com/white_f10ee2663d.jpeg';

  return (
    <img
      id={`frame-${props.index}`}
      className={Frame}
      src={defaultFramePhoto}
      alt={`frame-${props.index}`}
      draggable={false}
      style={{ height: props.pictureSize.height, width: props.pictureSize.width }}
      onClick={props.onClick}
    />
  );
};

export default FrameObject;
