import request from 'utils/request';
import auth from 'utils/auth';

const jwt = auth.getUser().jwt;

const update = (order, handleSuccess, handleFailure) => {
  request.putDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/orders/${order.id}`,
    order,
    jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const Order = { update };

export default Order;
