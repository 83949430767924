import React, { useState } from 'react';
import { Form, DatePicker, Row, Col } from 'antd';
import Button from 'components/Button';
import appLogs from 'utils/appLogs';
import notification from 'utils/notification';
import request from 'utils/request';

/**
 * ShootingAdd
 * @param {Object} user - Current user
 * @param {Integer} clientId - Id of the current client
 * @param {Callback} onShootingCreation - Callback to handle shooting creation
 * @param {Callback} onCancel - Callback to handle creation cancellation
 */
const ShootingAdd = (props) => {
  const [loading, setLoading] = useState(false);

  const handleShootingCreationSuccess = (result) => {
    notification.success('Félicitations !', 'La projection a bien été créée');
    setLoading(false);
    props.onShootingCreation();
  };

  const handleShootingCreationFailure = (error) => {
    notification.error('Impossible de créer une projection', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleShootingCreationFailure', error });
    setLoading(false);
  };

  const handleFormSubmit = (data) => {
    const shooting = {
      clientId: props.clientId,
      projectionDate: data.projectionDate,
      imageIdList: [],
      sceneIdList: [],
      orderIdList: []
    };

    setLoading(true);
    request.postDataWithAuth(`${request.API_ENDPOINT_URL}/shootings`, shooting, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleShootingCreationFailure(result));
        }
        return (handleShootingCreationSuccess(result));
      })
      .catch((error) => handleShootingCreationFailure(error));
  };

  const onChange = (date) => {
    console.log(date);
  };

  return (
    <div>
      <Form
        name='add'
        layout='vertical'
        onFinish={handleFormSubmit}
      >
        <Row justify='space-between'>
          <h2>Ajout de la projection</h2>
          <div>
            <Button type='link' onClick={props.onCancel} style={{ marginRight: '16px' }}>Annuler</Button>
            <Button type='primary' htmlType='submit' loading={loading}>Enregistrer</Button>
          </div>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label='Date de projection'>
              <Form.Item name='projectionDate'>
                <DatePicker onChange={onChange} />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row></Row>
      </Form>
    </div>
  );
};

export default ShootingAdd;
