import React, { useEffect } from 'react';
import Button from 'components/Button';
import { NAV_HEADER_HEIGHT } from 'configs/AppConfig';
import { ButtonContainer, Sticky } from './StickyButtonContainer.module.css';

/**
 * StickButtonContainer
 * @param {String} title - Title of the container
 * @param {Object} children - Children to render
 */
const StickyButtonContainer = (props) => {
  let stickyLimit = 0;

  const checkStickyness = (title) => {
    const header = document.getElementById(title);
    const filler = document.getElementById(`${title}-filler`);
    const stickyTrigger = NAV_HEADER_HEIGHT + 8;

    if (!header || !filler) {
      return (false);
    }
    if (
      !header.classList.contains(Sticky)
      && header.getBoundingClientRect().top !== 0
      && header.getBoundingClientRect().top <= stickyTrigger
    ) {
      header.classList.add(Sticky);
      filler.style.display = 'block';
      stickyLimit = window.scrollY;
    } else if (
      header.classList.contains(Sticky)
      && window.pageYOffset <= stickyLimit
    ) {
      header.classList.remove(Sticky);
      filler.style.display = 'none';
      stickyLimit = 0;
    }
  };

  useEffect(() => {
    if (!window.onscroll) {
      window.onscroll = () => {
        checkStickyness('DefaultModeHeader');
        checkStickyness('CompareModeHeader');
        checkStickyness('OrdersHeader');
      };
    }
  }, []);

  return (
    <div>
      <div id={props.title} className={ButtonContainer}>
        { props.children }
      </div>
      {
        <div id={`${props.title}-filler`} className={ButtonContainer} style={{ display: 'none' }}>
          <Button></Button>
        </div>
      }
    </div>
  );
};

export default StickyButtonContainer;
