import React from 'react';
import Button from 'components/Button';
import { WelcomeWrapper } from './WelcomeScreen.module.css';

/**
 * WelcomeScreen
 * @param {Callbacl} onStart - Callback to handle start
 */
const WelcomeScreen = (props) => {
  return (
    <div className={WelcomeWrapper}>
      <p>Envoyez <b>la photo de votre intérieur</b> à votre photographe <b>en quelques secondes</b></p>
      <Button
        type='primary'
        style={{ width: '100%' }}
        onClick={() => props.onStart()}
      >
        C'est parti !
      </Button>
    </div>
  );
};

export default WelcomeScreen;
