import React, { useState } from 'react';
import { Upload } from 'antd';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import photo from 'utils/photo';

/**
 * PhotoUploader
 * @param {String} text - Text to display on the button
 * @param {String} buttonType - Type of the main button (primary | default | link)
 * @param {Callback} onPhotoUpload - Callback to handle photo upload
 */
const PhotoUploader = (props) => {
  const [loading, setLoading] = useState(false);

  const handleLogoUploadSuccess = (result) => {
    props.onPhotoUpload(result.data[0].url);
    setLoading(false);
  };

  const handleLogoUploadFailure = (error) => {
    if (error.typeIsIncorrect) {
      notification.error('Format de photo incorrect', 'Vérifiez que le format est bien .png, .jpg ou .jpeg');
    } else {
      notification.error('Impossible d\'upload la photo', 'Veuillez réessayer');
    }
    setLoading(false);
    appLogs.logError({ function: 'handleLogoUploadFailure', error });
  };

  const uploadLogo = (file) => {
    setLoading(true);
    photo.uploadPhotoWithoutAuth(
      file,
      (result) => handleLogoUploadSuccess(result),
      (error) => handleLogoUploadFailure(error)
    );
  }

  return (
    <Upload
      name='file'
      showUploadList={false}
      beforeUpload={(file) => uploadLogo(file)}
    >
      <Button
        type={props.buttonType ? props.buttonType : 'primary'}
        loading={loading}
      >
        { props.text }
      </Button>
    </Upload>
  );
};

export default PhotoUploader;
