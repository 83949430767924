import React, { useState, useEffect } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { ClickableText, MainRow } from './ModelRow.module.css';

/**
 * ModelRow
 * @param {Object} margin - Margin for products
 * @param {String} supplierName - Current supplier's name
 * @param {String} serieName - Current serie's name
 * @param {Object} model - Current model
 * @param {Object} currentProduct - Product in user' productList that is from the catalog
 * @param {Callback} onSelectionUpdate - Callback to handle selection update
 */
const ModelRow = (props) => {
  const [checkedList, setCheckedList] = useState([]);
  const [product, setProduct] = useState(props.currentProduct);

  const addVariationToProduct = (index) => {
    const newVariation = {
      size: props.model.variations[index].dimensions,
      price: {
        buy: props.model.variations[index].price.toFixed(2),
        sell: (props.model.variations[index].price * props.margin[props.supplierName]).toFixed(2)
      }
    };

    product.specs.dimensions.push(newVariation);
    setProduct(product);
    return (newVariation);
  };

  const removeVariationToProduct = (index) => {
    product.specs.dimensions.forEach((dimension, dimensionIndex) => {
      if (props.model.variations[index].dimensions.width == dimension.size.width
        && props.model.variations[index].dimensions.height == dimension.size.height) {
          product.specs.dimensions.splice(dimensionIndex, 1);
        }
    });
    setProduct(product);
  };

  const findDifference = (a, b) => {
    let i = 0;

    while (i < a.length && i < b.length) {
      if (a[i] !== b[i]) {
        return (a[i]);
      }
      i += 1;
    }
    if (!a[i] && b[i]) {
      return (b[i]);
    } else if (a[i] && !b[i]) {
      return (a[i]);
    }
    return (0);
  };

  const updateProduct = (newCheckedList) => {
    if (checkedList.length < newCheckedList.length) {
      addVariationToProduct(findDifference(newCheckedList, checkedList));
    } else if (checkedList.length > newCheckedList.length) {
      removeVariationToProduct(findDifference(checkedList, newCheckedList));
    }
  };

  const handleCheckUpdate = (data) => {
    updateProduct(data);
    setProduct(product);
    setCheckedList(data);
    props.onSelectionUpdate(product);
  };

  const checkAll = () => {
    const allIndexList = Array.from(Array(props.model.variations.length).keys());

    product.specs.dimensions = [];
    allIndexList.forEach((index) => {
      addVariationToProduct(index);
    });
    setCheckedList(allIndexList);
    props.onSelectionUpdate(product);
  };

  const uncheckAll = () => {
    product.specs.dimensions = [];
    setProduct(product);
    setCheckedList([]);
    props.onSelectionUpdate(product);
  };

  useEffect(() => {
    const tmpArray = [];

    props.model.variations.forEach((variation, index) => {
      product.specs.dimensions.forEach((dimension) => {
        if (
          variation.dimensions.width == dimension.size.width
          && variation.dimensions.height == dimension.size.height
        ) {
          tmpArray.push(index);
        }
      });
    });
    setCheckedList(tmpArray);
  }, []);

  return (
    <Row gutter={16} className={MainRow}>
      <Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={props.model.photo} alt='' style={{ maxWidth: '100%' }} />
      </Col>
      <Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <p><b>{ props.supplierName.toUpperCase() }</b></p>
          <p>
            <b>{ props.model.modelName }</b><br />
            <i>{ props.serieName }</i><br />
            Dos { props.model.paintedBack ? 'peint' : 'normal' }
          </p>
        </div>
      </Col>
      <Col span={12}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
          <span
            className={ClickableText}
            onClick={() => checkAll()}
          >
            Tout séléctionner
          </span>
          <span style={{ width: '32px' }} />
          <span
            className={ClickableText}
            onClick={() => uncheckAll()}
          >
            Tout dé-séléctionner
          </span>
        </div>
        <Checkbox.Group
          value={checkedList}
          style={{ width: '100%' }}
          onChange={(data) => handleCheckUpdate(data)}
        >
          <Row gutter={16}>
            {
              props.model.variations.map((variation, index) => (
                <Col span={8}>
                  <Checkbox value={index}>
                    { variation.dimensions.width } x { variation.dimensions.height }
                  </Checkbox>
                </Col>
              ))
            }
          </Row>
        </Checkbox.Group>
      </Col>
    </Row>
  );
};

export default ModelRow;