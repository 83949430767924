import React, { useState } from 'react';
import { Checkbox } from 'antd';
import StickyButtonContainer from 'components/StickyButtonContainer';
import Button from 'components/Button';
import UploadModal from './UploadModal';
import { ButtonContainer, ButtonItem } from './DefaultModeHeader.module.css';

/**
 * DefaultModeHeader
 * @param {Object} user - Current user
 * @param {Integer} shootingId - Current shooting id
 * @param {Boolean} disabled - Weather or not to disable buttons
 * @param {Array} selectedImages - List of selected images
 * @param {Callback} onDiaporamaLaunch - Callback to handle diaporama launch
 * @param {Callback} onImageLike - Callback to handle image like
 * @param {Callback} onImageCreation - Callback to handle photo upload
 * @param {Callback} onImageDelete - Callback to handle photo delete
 * @param {Callback} onshowPhotoNumberChange - Callback to handle showPhotoNumber change
 * @param {Callback} onModeSwitch - Callback to handle mode switch
 */
 const DefaultModeHeader = (props) => {
  const [showPhotoNumber, setShowPhotoNumber] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const launchDiaporama = () => {
    props.onDiaporamaLaunch();
  };

  const handleShowPhotoNumberchange = () => {
    setShowPhotoNumber(!showPhotoNumber);
    props.onshowPhotoNumberChange(!showPhotoNumber);
  };

  return (
    <div>
      <UploadModal
        user={props.user}
        show={showModal}
        shootingId={props.shootingId}
        onImageCreation={props.onImageCreation}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      />
      <h2>Photos de la projection</h2>
      <p>Uploadez les photos de votre shooting</p>
      <StickyButtonContainer title='DefaultModeHeader'>
        <Button
          type={!props.selectedImages.length && !props.disabled ? 'primary' : 'default'}
          className={ButtonItem}
          disabled={props.disabled}
          onClick={() => launchDiaporama()}
        >
          Lancer le diaporama
        </Button>
        <Button
          type={!props.selectedImages.length ? 'default' : 'primary'}
          className={ButtonItem}
          disabled={props.disabled || !props.selectedImages.length}
          onClick={props.onImageLike}
        >
          { props.selectedImages.length && props.selectedImages[0].isLiked ? `Je n'aime plus` : `J'aime` }
        </Button>
        <Button
          type='default'
          className={ButtonItem}
          disabled={props.disabled || props.selectedImages.length < 2}
          onClick={() => props.onModeSwitch()}
        >
          Comparer
        </Button>
        <Button
          type={props.disabled ? 'primary' : 'link'}
          className={ButtonItem}
          onClick={() => setShowModal(true)}
        >
          Ajouter des photos
        </Button>
        <Button
          type='link'
          className={ButtonItem}
          style={{ color: '#F47174' }}
          disabled={props.disabled || !props.selectedImages.length}
          onClick={props.onImageDelete}
        >
          Supprimer
        </Button>
      </StickyButtonContainer>
      <br />
      <div className={ButtonContainer}>
        <Checkbox
          onChange={() => handleShowPhotoNumberchange()}
        >
          Afficher le numéro de photo
        </Checkbox>
      </div>
    </div>
  );
};

export default DefaultModeHeader;
