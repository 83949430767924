import React from 'react';
import { Row } from 'antd';

const productType = {
  FRAME: 'frame',
  ALBUM: 'album',
  CUSTOM: 'custom'
};

/**
 * PriceTag
 * @param {Object} shooting - Current shooting
 */
const PriceTag = (props) => {

  const getTotalPrice = () => {
    let total = 0;

    for (const order of props.shooting.orderList) {
      if (order.product.type === productType.FRAME) {
        total += parseFloat(order.dimensions.price.sell);
      } else if (order.product.type === productType.ALBUM) {
        total += parseFloat(order.product.specs.price.sell);
      } else if (order.product.type === productType.CUSTOM) {
        total += parseFloat(order.product.specs.price.sell);
      }
    }
    for (const extra of props.shooting.extraList) {
      total += parseFloat(extra.price.ttl);
    }
    return (total.toFixed(2));
  };

  return (
    <Row justify='end'>
      <h3><b>Prix total :</b> { getTotalPrice() }€</h3>
    </Row>
  );
};

export default PriceTag;
