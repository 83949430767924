import React, { useState } from 'react';
import { Card, Divider } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import notification from 'utils/notification';
import stripeApi from 'utils/stripeApi';
import appLogs from 'utils/appLogs';
import {
  Container,
  LeftPart,
  RightPart,
  DefaultCard,
  HighlightCard,
  Recurrence,
  Amount,
  Description,
  FeatureCheckStyle,
  ButtonStyle,
} from './PlanSelection.module.css';

/**
 * FeatureCheck
 * @param {String} text - Feature description
 */
const FeatureCheck = (props) => {
  return (
    <div className={FeatureCheckStyle}>
      <CheckCircleOutlined />
      <span style={{ width: '8px' }} />
      <p>{ props.text }</p>
    </div>
  );
};

/**
 * PlanCard
 * @param {Boolean} isHighlighted - Weather ir not the card is highlighted
 * @param {Callback} onPlanSelection - Callback to handle plan selection
 */
const PlanCard = (props) => {
  const [loading, setLoading] = useState(false);
  const plans = [
    {
      id: 0,
      reccurence: <span>Mensuel</span>,
      amount: 29,
      description: <>Payez <b>mensuellement</b></>,
      stripeProductId: stripeApi.getProductsId().MONTHLY_SUB_ID,
      cta: <span>Choisir le plan Mensuel</span>
    },
    {
      id: 1,
      reccurence: <span>Annuel (- 20%)</span>,
      amount: 24,
      description: <span>Payez <b>annuellement</b> et économisez <b>20%</b></span>,
      stripeProductId: stripeApi.getProductsId().ANNUAL_SUB_ID,
      cta: <span><b>Choisir le plan Annuel (- 20%)</b></span>
    }
  ];
  const plan = props.isHighlighted ? plans[1] : plans[0];

  const handleCheckoutSessionCreationSuccess = (result) => {
    props.onPlanSelection(result);
  };

  const handleCheckoutSessionCreationFailure = (error) => {
    notification.error('Impossible de d\'établir un lien avec le service de paiement', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleCheckoutSessionCreationFailure', error });
  };

  const createCheckoutSession = (stripeProductId) => {
    setLoading(true);
    stripeApi.createCheckoutSession(
      stripeProductId,
      (result) => handleCheckoutSessionCreationSuccess(result),
      (error) => handleCheckoutSessionCreationFailure(error)
    );
  };

  return (
    <>
      <Card className={props.isHighlighted ? HighlightCard : DefaultCard}>
        <div>
          <p className={Recurrence}>{ plan.reccurence }</p>
          <p className={Amount}>{ plan.amount } € / mois</p>
          <p className={Description}>{ plan.description }</p>
        </div>
        <Divider />
        <div>
          <FeatureCheck text='Clients illimités' />
          <FeatureCheck text='Shootings illimités' />
          <FeatureCheck text='Projections illimités' />
          <FeatureCheck text='Bon de commande automatique' />
          <FeatureCheck text='Plusieurs identités visuelles' />
        </div>
      </Card>
        <Button
          id='checkout-and-portal-button'
          className={ButtonStyle}
          type={props.isHighlighted ? 'primary' : 'default'}
          loading={loading}
          htmlType='submit'
          onClick={() => createCheckoutSession(plan.stripeProductId)}
        >
          { plan.cta }
        </Button>
    </>
  );
};

/**
 * PlanSelection
 * @param {Callback} onPlanSelection - Callback to handle plan selection
 */
const PlanSelection = (props) => {
  return (
    <div className={Container}>
      <div className={LeftPart}>
        <PlanCard
          isHighlighted={false}
          onPlanSelection={(planId) => props.onPlanSelection(planId)}
        />
      </div>
      <div className={RightPart}>
        <PlanCard
          isHighlighted={true}
          onPlanSelection={(planId) => props.onPlanSelection(planId)}
        />
      </div>
    </div>
  );
};

export default PlanSelection;
