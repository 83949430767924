import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Calibrator from './Calibrator';
import Home from './Home';

const EditorViews = (props) => {
  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route path='/editor/calibrate/:clientId/:contextId'>
          <Calibrator />
        </Route>
        <Route path='/editor/:clientId/:shootingId/:sceneId'>
          <Home />
        </Route>
        <Route path='/editor/:clientId/:shootingId'>
          <Home />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default EditorViews;
