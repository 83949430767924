import React, { useState } from 'react';
import Button from 'components/Button';
import PriceTag from './PriceTag';
import Summary from './Summary';
import ExtraOrder from './ExtraOrder';
import ClientInfo from './ClientInfo';
import VisualIdentitySelector from './VisualIndentitySelector';
import PurchaseOrderPDF from './PurchaseOrderPDF';

/**
 * DownloadButton
 * @param {Object} user - Current user
 * @param {Object} shooting - Current shooting
 */
const DownloadButton = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Button
        type='primary'
        onClick={() => setShow(true)}
      >
        Générer le bon de commande
      </Button>
      {
        show &&
          <PurchaseOrderPDF
            user={props.user}
            shooting={props.shooting}
            onCancel={() => setShow(false)}
          />
      }
      </div>
  );
};

/**
 * PurchaseOrderHeader
 * @param {Object} user - Current user
 * @param {Object} shooting - Current shooting
 */
const PurchaseOrderHeader = (props) => {
  return (
    <div>
      <h2>Bon de commande</h2>
      <p>Cliquez sur "Télécharger le bon de commande" pour obtenir le PDF de bon de commande</p>
      <DownloadButton user={props.user} shooting={props.shooting} />
    </div>
  );
};

/**
 * PurchaseOrder
 * @param {Object} user - Current user
 * @param {Integer} shootingId - Current shootingId
 * @param {Object} client - Current client
 * @param {Object} shooting - Current shooting
 */
const PurchaseOrder = (props) => {
  const [refresh, setRefresh] = useState(false);

  const onVisualIdentitySelection = (visualIdentity) => {
    props.shooting.visualIdentity = visualIdentity;
    setRefresh(!refresh);
  };

  return (
    <div>
      <PurchaseOrderHeader user={props.user} shooting={props.shooting} />
      <PriceTag shooting={props.shooting} />
      <Summary shooting={props.shooting} />
      <ExtraOrder
        user={props.user}
        shootingId={props.shootingId}
        shooting={props.shooting}
        onExtraCreation={() => setRefresh(!refresh)}
      />
      <ClientInfo
        user={props.user}
        shootingId={props.shootingId}
        client={props.client}
        shooting={props.shooting}
        onClientInfoUpdate={() => setRefresh(!refresh)}
      />
      <VisualIdentitySelector
        user={props.user}
        shooting={props.shooting}
        onVisualIdentitySelection={(visualIdentity) => onVisualIdentitySelection(visualIdentity)}
      />
      <DownloadButton user={props.user} shooting={props.shooting} />
    </div>
  );
};

export default PurchaseOrder;
