import request from 'utils/request';
import auth from 'utils/auth';

const user = auth.getUser();

const getImageById = (imageId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/images/${imageId}`,
    {},
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getImageByShootingId = (shootingId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/images`,
    { shootingId },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const Image = {
  getImageById,
  getImageByShootingId
};

export default Image;
