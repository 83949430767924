import React, { useState } from 'react';
import { Modal, Steps } from 'antd';
import VideoModal from 'components/VideoModal';
import Button from 'components/Button';

const { Step } = Steps;

/**
 * AllVideoModal
 * @param {Boolean} show - Wether or not to show the modal
 * @param {Callback} onOk - Callback to handle 'ok' click
 * @param {Callback} onCancel - Callback to handle 'cancel' click
 */
const AllVideosModal = (props) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [step, setStep] = useState(-1);
  const titleList = [
    'Compléter mon profil',
    'Importer mes produits',
    'Créer ma 1ere projection'
  ];
  const descriptionList = [
    'Créer mon identité visuelle',
    'Créer mon catalogue dans Lumina',
    'Mener ma projection avec Lumina'
  ];

  const openVideoModal = (targetStep) => {
    setStep(targetStep);
    setShowVideoModal(true);
  };

  const closeVideoModal = () => {
    setShowVideoModal(false);
    setStep(-1);
  };

  return (
    <div>
      <VideoModal
        show={showVideoModal}
        onboardingStep={step}
        onOk={() => closeVideoModal()}
        onCancel={() => closeVideoModal()}
      />
      <Modal
        title='Vidéos tutoriel'
        visible={props.show && !showVideoModal}
        onOk={() => props.onOk()}
        onCancel={() => props.onCancel()}
      >
        <h3>Choisissez la vidéo que vous voulez visionner :</h3>
        <br />
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '60%', display: 'flex' }}>
            <Steps direction='vertical' current={4} progressDot>
              <Step title={titleList[0]} description={descriptionList[0]} />
              <Step title={titleList[1]} description={descriptionList[1]} />
              <Step title={titleList[2]} description={descriptionList[2]} />
            </Steps>
          </div>
          <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
            <Button type='default' onClick={() => openVideoModal(0)} >Regarder la vidéo</Button>
            <Button type='default' onClick={() => openVideoModal(1)} >Regarder la vidéo</Button>
            <Button type='default' onClick={() => openVideoModal(2)} >Regarder la vidéo</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AllVideosModal;
