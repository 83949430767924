import React from 'react';
import CanvaDrawing from './CanvaDrawing';
import { Container, ContextWrapper } from './Editor.module.css';

/**
 * Editor
 * @param {Object} context - Current context
 * @param {Array} points - Array of calibration points
 * @param {Callback} onNewPoint - Callback to handle point creation
 */
const Editor = (props) => {
  return (
    <div className={Container}>
      <CanvaDrawing
        points={props.points}
        onNewPoint={(point) => props.onNewPoint(point)}
      />
      <img
        id='context'
        className={ContextWrapper}
        src={props.context.url}
        alt='context'
        draggable={false}
      />
    </div>
  );
};

export default Editor;
