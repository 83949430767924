/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Wrapper, ImageWrapper, Image } from './PhotoTab.module.css';
import drag from 'utils/drag';

/**
 * Handle the 'Photo' tab
 * @param {Object} imageList - List of all the photos
 */
const PhotoTab = (props) => {
  return (
    <div className={Wrapper}>
      <div className={ImageWrapper}>
        <h2>Photos aimées</h2>
        {
          props.imageList.filter((image) => image.isLiked).map((image, index) => (
            <img
              src={image.photo}
              alt={`photo-${index}`}
              className={Image}
              onDragStart={(event) => drag.setData(event, drag.dataType.PHOTO, { photo: image.photo })}
            />
          ))
        }
        <h2>Toutes les photos</h2>
        {
          props.imageList.filter((image) => !image.isLiked).map((image, index) => (
            <img
              src={image.photo}
              alt={`photo-${index}`}
              className={Image}
              onDragStart={(event) => drag.setData(event, drag.dataType.PHOTO, { photo: image.photo })}
            />
          ))
        }
      </div>
    </div>
  );
};

export default PhotoTab;
