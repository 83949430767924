import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Alert, Row, Col } from 'antd';
import { Form, Input } from 'antd';
import Button from 'components/Button';
import { THEME_COLOR } from 'configs/AppConfig';
import auth from 'utils/auth';

const backgroundStyle = {
  backgroundColor: THEME_COLOR.lightest,
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

/**
 * ResetPassword
 * @param {Callback} onLogin - Callback to handle user login
 */
const ResetPassword = (props) => {
  const [alert, setAlert] = useState({ show: false, type: 'info', message: '' });
  const [loading, setLoading] = useState(false);
  const alertStyle = { display: `${ alert.show ? '' : 'none' }` };
  const query = useQuery();

  const passwordIsCorrect = (data) => {
    const message = {
      PWD_DIFFER: 'La confirmation du mot de passe doit être identique',
      PWD_TOO_SHORT: 'Le mot de passe doit faire au moins 6 charactères'
    };

    if (data.password !== data.confirm) {
      setAlert({ show: true, type: 'error', message: message.PWD_DIFFER });
      setTimeout(() => setAlert({ show: false, type: 'info', message: '' }), 5000);
      setLoading(false);
      return (false);
    }
    if (data.password.length < 6) {
      setAlert({ show: true, type: 'error', message: message.PWD_TOO_SHORT });
      setTimeout(() => setAlert({ show: false, type: 'info', message: '' }), 5000);
      setLoading(false);
      return (false);
    }
    return (true);
  };

  const handleResetSuccess = (result) => {
    const { jwt, user } = result;

    setLoading(false);
    props.onLogin(jwt, user);
    return (true);
  };

  const handleResetFailure = (result) => {
    const error = {
      BAD_CODE: 'Auth.form.error.code.provide'
    };
    const message = {
      BAD_CODE: 'Le lien de réinitialisation est incorrect ou incomplet',
      DEFAULT: 'Une erreur s\'est produite pendant la réinitialisation, veuillez faire une nouvelle demande'
    };
    const errorCode = result?.data[0]?.messages[0]?.id;

    if (errorCode === error.BAD_CODE) {
      setAlert({ show: true, type: 'error', message: message.BAD_CODE });
    } else {
      setAlert({ show: true, type: 'error', message: message.DEFAULT });
    }
    setTimeout(() => setAlert({ show: false, type: 'info', message: '' }), 5000);
    setLoading(false);
    return (false);
  };

  const updatePassword = (data) => {
    const code = query.get('code');

    if (!passwordIsCorrect(data)) {
      return (false);
    }
    setLoading(true);
    auth.resetPassword(code, data.password, handleResetSuccess, handleResetFailure);
  };

  return (
    <div className='h-100' style={backgroundStyle}>
      <div className='container d-flex flex-column justify-content-center h-100'>
        <Row justify='center'>
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className='my-4'>
                <Alert message={alert.message} type={alert.type} showIcon style={alertStyle} />
                <div className='text-center'>
                  <img className='img-fluid' src='/img/logo.png' alt='' style={{ maxWidth: '100px' }} />
                </div>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Form layout='vertical' name='forget-form' onFinish={updatePassword}>
                      <Form.Item name='password' label='Nouveau mot de passe'>
                        <Input.Password placeholder='*****' />
                      </Form.Item>
                      <Form.Item name='confirm' label='Confirmation'>
                        <Input.Password placeholder='*****' />
                      </Form.Item>
                      <Form.Item>
                        <Button type='primary' htmlType='submit' loading={loading} block>
                          Réinitialiser mon mot de passe
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResetPassword;
