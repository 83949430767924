import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Login from './Login';
import Signup from './Signup';
import ResetPassword from './ResetPassword';
import Confirmed from './Confirmed';

/**
 * AuthViews
 * @param {Callback} onLogin - Callback to handle user login
 * @param {Callback} onSignup - Callback to handle user signup
 */
export const AuthViews = (props) => {
  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route path='/auth/login'>
          <Login onLogin={(jwt, user) => props.onLogin(jwt, user)} />
        </Route>
        <Route path='/auth/signup'>
          <Signup onSignup={(jwt, user) => props.onSignup(jwt, user)} />
        </Route>
        <Route path='/auth/reset-pwd'>
          <ResetPassword onLogin={(jwt, user) => props.onLogin(jwt, user)} />
        </Route>
        <Route path='/auth/confirmed'>
          <Confirmed onLogin={(jwt, user) => props.onLogin(jwt, user)} />
        </Route>
        <Redirect from='/auth' to='/auth/signup' />
      </Switch>
    </Suspense>
  );
};

export default AuthViews;
