import React, { useState } from 'react';
import { Modal } from 'antd';
import ImageRow from 'components/ImageRow';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import OrdersHeader from './OrdersHeader';
import OrderModal from './OrderModal';
import { SectionTitle, DefaultOrder, SelectedOrder } from './Orders.module.css';

const productType = {
  FRAME: 'frame',
  ALBUM: 'album',
  CUSTOM: 'custom'
};

/**
 * Orders
 * @param {Object} user - Current user
 * @param {Integer} clientId - Current client id
 * @param {Integer} shootingId - Current shooting id
 * @param {Object} shooting - Current shooting
 */
const Orders = (props) => {
  const [showPhotoNumber, setShowPhotoNumber] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedScenes, setSelectedScenes] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const selectionType = {
    IMAGE: 'image',
    SCENE: 'scene',
    ORDER: 'order'
  };

  const isSelected = (id, selectedList) => {
    for (const image of selectedList) {
      if (image.id === id) {
        return (true);
      }
    }
    return (false);
  };

  const handleSelection = (target, type) => {
    let tmpArray = [];
    let setterMethod = () => {};

    if (type === selectionType.IMAGE) {
      tmpArray = selectedImages;
      setterMethod = setSelectedImages;
    } else if (type === selectionType.SCENE) {
      tmpArray = selectedScenes;
      setterMethod = setSelectedScenes;
    } else if (type === selectionType.ORDER) {
      tmpArray = selectedOrders;
      setterMethod = setSelectedOrders;
    }
    if (!isSelected(target.id, tmpArray)) {
      tmpArray.push(target);
    } else {
      tmpArray = tmpArray.filter((elem) => elem.id !== target.id);
    }
    setterMethod(tmpArray);
    setRefresh(!refresh);
  };

  const handleOrderDeleteSuccess = (result) => {
    notification.success('Félicitations !', 'La / les commande(s) a / ont bien été supprimée(s)');
  };

  const handleOrderDeleteFailure = (error) => {
    notification.error('Impossible de supprimer la commande', 'Veuillez ré-essayer plus tard');
    appLogs.logError({ function: 'handleOrderDeleteFailure', error });
  };

  const deleteSelectedOrders = () => {
    selectedOrders.forEach((order) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/orders/${order.id}`, props.user.jwt, handleOrderDeleteSuccess, handleOrderDeleteFailure);
      props.shooting.orderList = props.shooting.orderList.filter((item) => item.id !== order.id);
    });
    setSelectedOrders([]);
    setRefresh(!refresh);
  };

  const confirmSelectedOrdersDelete = () => {
    Modal.confirm({
      title: 'Supprimer ce(s) commande(s)',
      content: 'Etes-vous sûr de vouloir supprimer ce(s) commande(s) ?',
      okText: 'Oui, supprimer',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        deleteSelectedOrders();
      }
    });
  };

  const handleOrderCreationSuccess = (result) => {
    notification.success('Félicitations !', 'Le produit a bien été commandé');
    props.shooting.orderList.push(result);
    setLoading(false);
    setShowOrderModal(false);
    setSelectedImages([]);
    setSelectedScenes([]);
    setRefresh(!refresh);
  };

  const handleOrderCreationFailure = (error) => {
    notification.error('Impossible de créer la commande', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleOrderCreationFailure', error });
  };

  const handleOrderCreation = (choosenProduct, frameConfigIndex, imageList) => {
    const order = {
      shootingId: props.shootingId,
      product: choosenProduct,
      imageList: imageList,
      dimensions: {}
    };

    if (choosenProduct.type === productType.FRAME) {
      order.dimensions = order.product.specs.dimensions[frameConfigIndex];
    }
    setLoading(true);
    request.postDataWithAuthInline(`${request.API_ENDPOINT_URL}/orders`, order, props.user.jwt, handleOrderCreationSuccess, handleOrderCreationFailure);
  };

  const getScenes = () => {
    const sceneList = props.shooting.sceneList;
    const sceneListWithImage = sceneList.filter((scene) => scene.config.context.url);
    const scenes = sceneListWithImage.map((scene) => ({ ...scene, photo: scene.config.context.url }));

    return (scenes);
  };

  return (
    <div>
      <OrderModal
        show={showOrderModal}
        user={props.user}
        selectedImages={selectedImages}
        loading={loading}
        onOrder={(choosenProduct, frameConfigIndex) => handleOrderCreation(choosenProduct, frameConfigIndex, selectedImages)}
        onCancel={() => setShowOrderModal(false)}
      />
      <OrdersHeader
        clientId={props.clientId}
        shootingId={props.shootingId}
        productType={productType}
        selectedImages={selectedImages}
        selectedScenes={selectedScenes}
        setShowOrderModal={setShowOrderModal}
        onshowPhotoNumberChange={(state) => setShowPhotoNumber(state)}
        onOrder={(choosenProduct, frameConfigIndex, imageList) => handleOrderCreation(choosenProduct, frameConfigIndex, imageList)}
      />
      <h3 className={SectionTitle}>Scénes crées</h3>
      <p>Retrouvez ici les scènes créées dans l'éditeur</p>
      <ImageRow
        imageList={getScenes()}
        showPhotoNumber={false}
        selectedImages={selectedScenes}
        onImageSelection={(image) => handleSelection(image, selectionType.SCENE)}
      />
      <h3 className={SectionTitle}>Mentions J'aime</h3>
      { !props.shooting.imageList.filter((img) => img.isLiked).length && <p>Retrouvez ici les photos marquées "j'aime" dans la section "Photos de la projection"</p>}
      <ImageRow
        imageList={props.shooting.imageList.filter((img) => img.isLiked)}
        showPhotoNumber={showPhotoNumber}
        selectedImages={selectedImages}
        onImageSelection={(image) => handleSelection(image, selectionType.IMAGE)}
      />
      <h2 style={{ marginTop: '32px' }}>Produits commandés</h2>
      <Button
        type='default'
        style={{ color: '#F47174' }}
        disabled={!selectedOrders.length}
        onClick={() => confirmSelectedOrdersDelete()}
      >
        Supprimer la / les commande(s)
      </Button>
      {
        props.shooting.orderList.map((order) => (
          <div className={(!isSelected(order.id, selectedOrders) ? DefaultOrder : SelectedOrder)}>
            <h3 className={SectionTitle} onClick={() => handleSelection(order, selectionType.ORDER)}>
							{
								order.product.type === 'frame' ?
									<>{ order.product.name } - { order.dimensions.size.width } x { order.dimensions.size.height } cm</>
								:
									<>{ order.product.name }</>
							}
            </h3>
            <ImageRow
              imageList={order.imageList}
              showPhotoNumber={true}
              selectedImages={[]}
              onImageSelection={() => handleSelection(order, selectionType.ORDER)}
            />
          </div>
        ))
      }
    </div>
  );
};

export default Orders;
