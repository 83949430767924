import React from 'react';
import { Select, Divider, Modal } from 'antd';
import { THEME_COLOR } from 'configs/AppConfig';
import { RotateRightOutlined, GatewayOutlined, DeleteOutlined } from '@ant-design/icons';
import { Wrapper, SelectWrapper, Icon } from './EditMenu.module.css';

const { Option } = Select;
const { confirm } = Modal;

/**
 * Handle the Edit Menu of each Picture
 * @param {Number} index - Index of the Picture the menu is attached to
 * @param {Boolean} pictureHasPhoto - Weather or not the Picture has a Photo yet
 * @param {Object} pictureSize - Picture's height and width
 * @param {Array} dimensions - Array of all the Frame's dimensions
 * @param {Number} currentDimension - Index of current dimension in dimensions
 * @param {Callback} onRotation - Callback to handle frame rotation
 * @param {Callback} onCurrentDimensionChange - Callback to update Picture on dimension change
 * @param {Callback} onCropTrigger - Callback to trigger the crop feature
 * @param {Callback} onDelete - Callback to delete the Picture
 */
const EditMenu = (props) => {
  const deletePicture = () => {
    confirm({
      title: 'Supprimer ce tableau',
      content: 'Etes-vous sûr de vouloir supprimer ce tableau',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
        props.onDelete();
      }
    });
  };

  return (
    <div>
      <div className={Wrapper} style={{ top: `calc(${props.pictureSize.height}px + 8px)` }}>
        <Select className={SelectWrapper} defaultValue={props.currentDimension} bordered={false} onChange={props.onCurrentDimensionChange}>
          {
            props.dimensions.map((dimension, index) => (
              <Option value={index}>
                {dimension.size.width} x {dimension.size.height} cm
              </Option>
            ))
          }
        </Select>
        <Divider type='vertical' style={{border: `1px solid ${THEME_COLOR.lightest}50`}} />
        <RotateRightOutlined className={Icon} onClick={() => props.onRotation()} />
        <GatewayOutlined className={Icon} onClick={props.pictureHasPhoto ? () => props.onCropTrigger() : () => {}} />
        <DeleteOutlined className={Icon} onClick={() => deletePicture()} />
      </div>
    </div>
  );
};

export default EditMenu;
