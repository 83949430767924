import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import ClientList from './ClientList';
import ClientAdd from './ClientAdd';
import ClientEdit from './ClientEdit';
import ClientView from './ClientView';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import auth from 'utils/auth';

const state = {
  LIST: 'list',
  VIEW: 'view',
  EDIT: 'edit',
  ADD: 'add'
};

/**
 * Client
 */
const Client = () => {
  const [currentState, setCurrentState] = useState(state.LIST);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [clientId, setClientId] = useState(null);
  const user = auth.getUser();

  const terminateDeletion = () => {
    setDeleteLoading(false);
    setCurrentState(state.LIST);
  }

  const handleImageAccessSuccess = (result) => {
    const imageListId = result.map((item) => item.id);

    imageListId.forEach((imageId) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/images/${imageId}`, user.jwt, () => {}, () => {});
    });
  };

  const handleImageAccessFailure = (error) => {
    appLogs.logError({ function: 'handleImageAccessFailure', component: 'Client', error });
  };

  const handleOrderAccessSuccess = (result) => {
    const orderListId = result.map((item) => item.id);

    orderListId.forEach((orderId) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/orders/${orderId}`, user.jwt, () => {}, () => {});
    });
  };

  const handleOrderAccessFailure = (error) => {
    appLogs.logError({ function: 'handleOrderAccessFailure', component: 'Client', error });
  };

  const handleSceneAccessSuccess = (result) => {
    const sceneListId = result.map((item) => item.id);

    sceneListId.forEach((sceneId) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/scenes/${sceneId}`, user.jwt, () => {}, () => {});
    });
  };

  const handleSceneAccessFailure = (error) => {
    appLogs.logError({ function: 'handleSceneAccessFailure', component: 'Client', error });
  };

  const handleShootingAccessSuccess = (result) => {
    const shootingIdList = result.map((item) => item.id);

    shootingIdList.forEach((shootingId) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/shootings/${shootingId}`, user.jwt, () => {}, () => {});
      request.getDataWithAuthInline(`${request.API_ENDPOINT_URL}/scenes`, { shootingId }, user.jwt, handleSceneAccessSuccess, handleSceneAccessFailure);
      request.getDataWithAuthInline(`${request.API_ENDPOINT_URL}/orders`, { shootingId }, user.jwt, handleOrderAccessSuccess, handleOrderAccessFailure);
      request.getDataWithAuthInline(`${request.API_ENDPOINT_URL}/images`, { shootingId }, user.jwt, handleImageAccessSuccess, handleImageAccessFailure);
    });
  };

  const handleShootingAccessFailure = (error) => {
    appLogs.logError({ function: 'handleShootingAccessFailure', component: 'Client', error });
  };

  const deleteShootings = (clientId) => {
    request.getDataWithAuthInline(`${request.API_ENDPOINT_URL}/shootings`, { clientId }, user.jwt, handleShootingAccessSuccess, handleShootingAccessFailure);
  };

  const handleClientDeletionSuccess = (clientId, result) => {
    notification.success('Félicitations !', 'Le client a bien été supprimé');
    deleteShootings(clientId);
    terminateDeletion();
  };

  const handleClientDeletionFailure = (error) => {
    notification.error('Impossible de supprimer le client', 'Veuillez ré-essayer plus tard');
    appLogs.logError({ function: 'handleClientDeletionFailure', error });
    terminateDeletion();
  };

  const deleteClient = (clientId) => {
    request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/clients/${clientId}`, user.jwt, (result) => handleClientDeletionSuccess(clientId, result), handleClientDeletionFailure);
  };

  const onClientDelete = (clientId) => {
    setDeleteLoading(true);
    deleteClient(clientId);
  };

  const deleteConfirmation = (id) => {
    Modal.confirm({
      title: 'Supprimer ce client',
      content: 'Etes-vous sûr de vouloir supprimer ce client, ainsi que toutes ses projections associées ?',
      okText: 'Oui, supprimer',
      okType: 'danger',
      cancelText: 'Non',
      okButtonProps: {
        loading: deleteLoading,
      },
      onOk() {
        onClientDelete(id);
      }
    });
  };

  const onClientModify = (id) => {
    setClientId(id);
    setCurrentState(state.EDIT);
  };

  const onClientSelectionFromList = (clientId) => {
    window.location.pathname = `/mes-clients/${clientId}`;
  };

  const returnToClientList = () => {
    window.location.pathname = '/mes-clients';
  };

  const handleClientAccessSuccess = (result) => {
    if (result.userId === user.usr.id) {
      setClientId(result.id);
      setCurrentState(state.VIEW);
    }
  };

  const handleClientAccessFailure = (error) => {
    appLogs.logError({ function: 'handleClientAccessFailure', error });
    returnToClientList();
  };

  const onClientSelection = (clientId) => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/clients/${clientId}`, {}, user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientAccessFailure(result));
        }
        return (handleClientAccessSuccess(result));
      })
      .catch((error) => handleClientAccessFailure(error));
  };

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const step = {
      PAGE_NAME: 1,
      CLIENT_ID: 2
    };
    let clientId = 0;

    if (path[step.PAGE_NAME] === 'mes-clients' && path[step.CLIENT_ID]) {
      clientId = parseInt(path[step.CLIENT_ID]);
      if (isNaN(clientId)) {
        return (returnToClientList());
      }
      onClientSelection(clientId);
    }
  }, []);

  return (
    <div>
      {
        currentState === state.LIST &&
          <ClientList
            user={user}
            onClientAdd={() => setCurrentState(state.ADD)}
            onClientSelection={(clientId) => onClientSelectionFromList(clientId)}
          />
      }
      {
        currentState === state.ADD &&
          <ClientAdd
            user={user}
            onCreation={() => setCurrentState(state.LIST)}
            onCancel={() => setCurrentState(state.LIST)}
          />
      }
      {
        currentState === state.EDIT &&
          <ClientEdit
            user={user}
            clientId={clientId}
            onCancel={() => onClientSelectionFromList(clientId)}
            onUpdate={() => onClientSelectionFromList(clientId)}
          />
      }
      {
        currentState === state.VIEW &&
          <ClientView
            user={user}
            clientId={clientId}
            onModify={(id) => onClientModify(id)}
            onDelete={(id) => deleteConfirmation(id)}
          />
      }
    </div>
  );
};

export default Client;
