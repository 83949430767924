import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { THEME_COLOR, EDITOR } from 'configs/AppConfig';
import drag from 'utils/drag';
import FrameObject from './FrameObject';
import PhotoObject from './PhotoObject';
import EditMenu from './EditMenu';
import Croper from './Croper';
import { PictureWrapper, FrameWrapper } from './PictureObject.module.css';

const computeCanva = () => {
  const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) - EDITOR.LEFT_PANEL_WIDTH.PIXEL_FORMAT;
  const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - EDITOR.TOP_NAV_HEIGHT.PIXEL_FORMAT;

  return ({ height, width});
};

const step = {
  DRAG_START: 'dragStart',
	DRAG_LIVE: 'dragLive',
  DRAG_STOP: 'dragStop'
};

/**
 * Handle the Picture object
 * @param {Number} index - Picture's index in pictureList
 * @param {Object} scene - Current scene
 * @param {Number} coefficient - Number to convert from cm to px
 * @param {Array} pictureList - Array of pictures in the scene
 * @param {Object} picture - Picture = Frame + Photo
 * @param {Callback} onPhotoUpdate - Callback to update the picture's photo
 * @param {Callback} onFrameUpdate - Callback to update the picture's frame
 * @param {Callback} onPictureUpdate - Callback to update the picture object
 * @param {Callback} onCollectionItemUpdate - Callback to update the picture's collection's other frames
 * @param {Callback} onDelete - Callback to delete the picture
 */
const PictureObject = (props) => {
  const [editIsEnabled, setEditIsEnabled] = useState(false);
  const [pictureSize, setPictureSize] = useState({});
  const [showCroper, setShowCroper] = useState(false);
  const [ratioHasChanged, setRatioHasChanged] = useState(false);
  const [movedOnLastClick, setMovedOnLastClick] = useState(false);
  const ratio = (
    !props.picture.frame.isRotated ?
        (
          props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.width /
          props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.height
        )
      :
        (
          props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.height /
          props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.width
        )
  );

  const updateFrameRatio = () => {
    const newRatio = (
      !props.picture.frame.isRotated ?
          (
            props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.width /
            props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.height
          )
        :
          (
            props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.height /
            props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.width
          )
    );

    if (ratio.default !== newRatio && props.picture.photo) {
      setRatioHasChanged(true);
      setShowCroper(true);
    }
  };

  const hasMove = (from, to) => {
    if (from.x === to.x && from.y === to.y) {
      return (false);
    }
    return (true);
  }

  const isInBound = (pos) => {
    const canvaSize = computeCanva();

    if (pos.x < (pictureSize.width / 2) || pos.x > canvaSize.width - (pictureSize.width / 2)) {
      return (false);
    }
    if (pos.y < (pictureSize.height / 2) || pos.y > canvaSize.height - (pictureSize.height / 2)) {
      return (false);
    }
    return (true);
  };

  const updatePos = (event, data, currentStep) => {
    const newPos = {
      x: data.x + (pictureSize.width / 2),
      y: data.y + (pictureSize.height / 2)
    };

		if (currentStep === step.DRAG_STOP) {
      if (hasMove(props.picture.pos, newPos)) {
        setMovedOnLastClick(true);
      } else {
        setMovedOnLastClick(false);
      }
    }
    if (isInBound(newPos)) {
			if (currentStep !== step.DRAG_LIVE) {
				props.picture.pos.x = newPos.x;
				props.picture.pos.y = newPos.y;
				props.onPictureUpdate(props.picture);
			}
			if (props.picture.frame.isCollectionItem) {
				props.pictureList.forEach((picture, index) => {
					if (picture.frame.isCollectionItem
						&& picture.frame.itemId !== props.picture.frame.itemId
						&& picture.frame.collectionId === props.picture.frame.collectionId
					) {
						const moveVector = {
							x: newPos.x - props.picture.pos.x,
							y: newPos.y - props.picture.pos.y
						};
						const frameDeltaToReference = {
							x: (picture.frame.relativePos.x + picture.frame.specs.dimensions[picture.frame.currentDimension].size.width / 2)
								- (props.picture.frame.relativePos.x + props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.width / 2),
							y: (picture.frame.relativePos.y + picture.frame.specs.dimensions[picture.frame.currentDimension].size.height / 2)
								- (props.picture.frame.relativePos.y + props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.height / 2)
						};
						const startingPos = {
							x: props.picture.pos.x + (frameDeltaToReference.x * props.scene.coefficient),
							y: props.picture.pos.y + (frameDeltaToReference.y * props.scene.coefficient)
						};
						picture.pos.x = startingPos.x + moveVector.x;
						picture.pos.y = startingPos.y + moveVector.y;
						props.onCollectionItemUpdate(index, picture);
					}
				});
			}
    }
  };

  const drop = (event) => {
    drag.allowDrop(event);
    if (drag.getData(event, drag.dataType.PHOTO)) {
      setShowCroper(true);
      props.onPhotoUpdate(drag.getData(event, drag.dataType.PHOTO));
    }
  };

  const handleClickOnPicture = () => {
    if (!movedOnLastClick) {
      setEditIsEnabled(!editIsEnabled);
    }
  };

  const updateCropInfo = (cropInfo) => {
    props.picture.photo.cropInfo = cropInfo;
    props.onPhotoUpdate(props.picture.photo);
  };

  const handleCroperClose = () => {
    setRatioHasChanged(false);
    setShowCroper(false);
  };

  const updateRotation = () => {
    props.picture.frame.isRotated = !props.picture.frame.isRotated;
    updateFrameRatio();
    computeHeight();
    props.onFrameUpdate(props.picture.frame);
  };

  const updateCurrentDimension = (currentDimension) => {
		// if (props.picture.frame.isCollectionItem) {
		// 	props.pictureList.forEach((picture, index) => {
		// 		if (picture.frame.isCollectionItem
		// 			&& picture.frame.itemId !== props.picture.frame.itemId
		// 			&& picture.frame.collectionId === props.picture.frame.collectionId
		// 		) {
		// 			// 1 - Resize frames
		// 			picture.frame.currentDimension = currentDimension;
		// 			// 2 - Reposition frames
		// 			const sizeChangeRatio = {
		// 				x: props.picture.frame.specs.dimensions[currentDimension].size.width / props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.width,
		// 				y: props.picture.frame.specs.dimensions[currentDimension].size.height / props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.height
		// 			}
		// 			const newPos = {
		// 				x: (picture.pos.x - props.picture.pos.x) * sizeChangeRatio.x + (picture.frame.width / 2) * props.scene.coefficient,
		// 				y: (picture.pos.y - props.picture.pos.t) * sizeChangeRatio.t + (picture.frame.height / 2) * props.scene.coefficient
		// 			};
		// 			picture.pos = newPos;
		// 			// 3 - Update
		// 			props.onCollectionItemUpdate(index, picture);
		// 		}
		// 	});
		// }
    props.picture.frame.currentDimension = currentDimension;
    updateFrameRatio();
    props.onFrameUpdate(props.picture.frame);
  };

  const computeHeight = () => {
    const realFrame = {
      height: props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.height,
      width: props.picture.frame.specs.dimensions[props.picture.frame.currentDimension].size.width
    };
    const digitalFrame = {
      height: realFrame.height * props.coefficient,
      width: realFrame.width * props.coefficient
    };
    let tmp = 0;

    if (props.picture.frame.isRotated) {
      tmp = digitalFrame.height;
      digitalFrame.height = digitalFrame.width;
      digitalFrame.width = tmp;
    }
    setPictureSize(digitalFrame);
  }

  useEffect(() => {
    computeHeight();
  }, [ props.coefficient, props.picture.frame.currentDimension ]);

  return (
    <div className={PictureWrapper}>
      {
        showCroper &&
          <Croper
            show={showCroper}
            canQuit={!ratioHasChanged ? true : false}
            ratio={ratio}
            image={props.picture.photo}
            onUpdate={(cropInfo) => updateCropInfo(cropInfo)}
            onClose={() => handleCroperClose()}
          />
      }
      <Draggable
        disabled={( props.editIsEnabled ? true : false )}
        position={{
          x: props.picture.pos.x - (pictureSize.width / 2),
          y: props.picture.pos.y - (pictureSize.height / 2)
        }}
        onStart={(event, data) => updatePos(event, data, step.DRAG_START)}
				onDrag={(event, data) => props.picture.frame.isCollectionItem && updatePos(event, data, step.DRAG_LIVE)}
        onStop={(event, data) => updatePos(event, data, step.DRAG_STOP)}
      >
        <div
          className={FrameWrapper}
          onDrop={drop}
          onDragOver={drag.allowDrop}
        >
          {
            editIsEnabled &&
              <div
                style={{
                  height: pictureSize.height,
                  width: pictureSize.width,
                  position: 'absolute',
                  zIndex: '2',
                  borderStyle: 'solid',
                  borderWidth: '5px',
                  borderColor: THEME_COLOR.default,
                }}
                onClick={handleClickOnPicture}
              >
              </div>
          }
          {
            editIsEnabled &&
              <EditMenu
                index={props.index}
                pictureHasPhoto={props.picture.photo ? true : false}
                pictureSize={pictureSize}
                dimensions={props.picture.frame.specs.dimensions}
                currentDimension={props.picture.frame.currentDimension}
                onRotation={() => updateRotation()}
                onCurrentDimensionChange={(currentDimension) => updateCurrentDimension(currentDimension)}
                onCropTrigger={() => setShowCroper(true)}
                onDelete={() => props.onDelete()}
              />
          }
          {
            props.picture.photo &&
              <PhotoObject
                index={props.index}
                pictureSize={pictureSize}
                photo={props.picture.photo}
                onPhotoUpdate={props.onPhotoUpdate}
                onClick={handleClickOnPicture}
              />
          }
          <FrameObject
            index={props.index}
            pictureSize={pictureSize}
            frame={props.picture.frame}
            onClick={handleClickOnPicture}
          />
        </div>
      </Draggable>
    </div>
  );
};

export default PictureObject;
