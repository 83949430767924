import React from 'react';

/**
 * Header
 * @param {Object} client - Current client
 */
const Header = (props) => {
  return (
    <div style={{ margin: '32px 0px' }}>
      <p
        style={{
          textAlign: 'center',
          fontSize: 'large',
          marginBottom: 0
        }}
      >
        Bonjour
      </p>
      <h1>{ props.client.name }</h1>
    </div>
  );
};

export default Header;
