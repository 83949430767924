import React from 'react';
import { Popover } from 'antd';
import { MoreOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const More = (props) => {
  const style = {
    actionStyle: {
      cursor: 'pointer'
    },
    iconStyle: {
      margin: '10px 10px 10px 10px',
    }
  };
  const content = (
    <div>
      { props.onView && <span style={style.actionStyle} onClick={props.onView}><EyeOutlined style={style.iconStyle} /> Voir le produit</span> }
      { (props.onEdit || props.onDelete) && <br /> }
      { props.onEdit && <span style={style.actionStyle} onClick={props.onEdit}><EditOutlined style={style.iconStyle} /> Modifier le produit</span> }
      { props.onDelete && <br /> }
      { props.onDelete && <span style={style.actionStyle} onClick={props.onDelete}><DeleteOutlined style={style.iconStyle} /> Supprimer le produit</span> }
    </div>
  );

  return (
    <div>
      <Popover placement='bottomRight' content={content} trigger='click'>
        <MoreOutlined />
      </Popover>
    </div>
  );
};

export default More;
