import React, { useState, useEffect } from 'react';
import { HomeOutlined, ExpandOutlined, PictureOutlined } from '@ant-design/icons';
import Context from 'controllers/Context';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import ContextTab from './ContextTab';
import FrameTab from './FrameTab';
import PhotoTab from './PhotoTab';
import {
  Wrapper,
  TabWrapper,
  ContentWrapper,
  Tab,
  ActiveTab,
  Icon
} from './LeftPanel.module.css';
import collection_db from './collection_db.json';

const tab = {
  CONTEXT: 'context',
  FRAME: 'frame',
  PHOTO: 'photo'
};

const productType = {
  FRAME: 'frame',
	COLLECTION: 'collection',
  ALBUM: 'album',
  CUSTOM: 'custom'
};

const demoDataType = {
  CONTEXT: 'context',
  FRAME: 'frame',
  PHOTO: 'photo'
};

/**
 * Handle the left panel with the three tabs
 * @param {Object} user - Current user
 * @param {Integer} clientId - Current client id
 * @param {Integer} shootingId - Current shooting id
 */
const LeftPanel = (props) => {
  const [activeTab, setActiveTab] = useState(tab.CONTEXT);
  const [demoDataList, setDemoDataList] = useState([]);
  const [contextList, setContextList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [imageList, setImageList] = useState([]);

  const handleDemoDataAccessSuccess = (result) => {
    setDemoDataList(result);
  };

  const handleDemoDataAccessFailure = (error) => {
    appLogs.logError({ function: 'handleDemoDataAccessFailure', error });
  };

  const getDemoData = () => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/editor-demo-data`, {}, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleDemoDataAccessFailure(result));
          }
          return (handleDemoDataAccessSuccess(result));
        },
        (error) => handleDemoDataAccessFailure(error)
      );
  };

  const handleContextAccessSuccess = (result) => {
    setContextList(result);
  };

  const handleContextAccessFailure = (error) => {
    notification.error('Impossible de récupérer les intérieurs du client', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleContextAccessFailure', component: 'LeftPanel', error });
  };

  const getClientContext = () => {
    Context.getByClientId(
      props.clientId,
      (result) => handleContextAccessSuccess(result),
      (error) => handleContextAccessFailure(error)
    );
  };

  const handleProductAccessSuccess = (result) => {
		const example = {
			authorId : 75,
			created_at : "2022-11-01T19:49:42.270Z",
			email : "test42@nospam.today",
			id : 42,
			name : "LM Collection",
			photo : "https://lumina-images.s3.eu-west-3.amazonaws.com/download_4_1346d104ad.jpeg",
			published_at : "2022-11-01T19:49:42.229Z",
			specs : {
				dimensions : [
					{
						price : {buy: '18.75', sell: '56.25'},
						size : {width: 30, height: 30}
					},
					{
						price : {buy: '52.45', sell: '157.35'},
						size : {width: 50, height: 70}
					},
					{
						price : {buy: '19.90', sell: '59.70'},
						size : {width: 30, height: 40}
					},
					{
						price : {buy: '42.75', sell: '128.25'},
						size : {width: 30, height: 90}
					},
					{
						price : {buy: '21.70', sell: '65.10'},
						size : {width: 30, height: 45}
					}
				],
			},
			supplier : "Laminamarc",
			type : "frame"
		};
		const collectionProduct = {
			authorId : 75,
			created_at : "2022-11-01T19:49:42.270Z",
			email : "test42@nospam.today",
			id : 42,
			name : "LM Collection",
			photo : "https://lumina-images.s3.eu-west-3.amazonaws.com/download_4_1346d104ad.jpeg",
			published_at : "2022-11-01T19:49:42.229Z",
			specs : {
				nbItem: 3,
				dimensions: [
					{
						size: {
							width: 88,
							height: 88
						}
					}
				],
				itemList: [
					{
						pos: {
							x: 0,
							y: 0
						},
						width: 40,
						height: 40
					},
					{
						pos: {
							x: 48,
							y: 0
						},
						width: 40,
						height: 40
					},
					{
						pos: {
							x: 0,
							y: 48
						},
						width: 88,
						height: 40
					},
				]
			},
			supplier : "Laminamarc",
			type : "collection"
		};
		console.log('> collection_db -');
		console.log(collection_db);
		// result.push(collectionProduct);
		result.push(collection_db[0]);
		result.push(collection_db[1]);
		result.push(collection_db[2]);
		result.push(collection_db[3]);
		result.push(collection_db[4]);
		result.push(collection_db[5]);
		console.log(result);
		setProductList(result);
  };

  const handleProductAccessFailure = (error) => {
    notification.error('Impossible d\'accéder à vos produits', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleProductAccessFailure', error });
  };

  const getUserProducts = () => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/products`, { email: props.user.usr.email }, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleProductAccessFailure(result));
          }
          return (handleProductAccessSuccess(result));
        },
        (error) => handleProductAccessFailure(error)
      );
  }

  const handleImageListAccessSuccess = (result) => {
    setImageList(result);
  };

  const handleImageListAccessFailure = (error) => {
    notification.error('Impossible de récupérer les photos de la projection', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleImageListAccessFailure', error });
  };

  const getShootingImages = () => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/images`, { shootingId: props.shootingId }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleImageListAccessFailure(result));
        }
        handleImageListAccessSuccess(result);
      })
      .catch((error) => handleImageListAccessFailure(error));
  };

  useEffect(() => {
    getDemoData();
    getClientContext();
    getShootingImages();
    getUserProducts();
  }, []);

  return (
    <div className={Wrapper}>
      <div className={TabWrapper}>
        <div
          className={( activeTab === tab.CONTEXT ? ActiveTab : Tab )}
          onClick={() => setActiveTab(tab.CONTEXT)}
        >
          <HomeOutlined className={Icon} />
          Intérieurs
        </div>
        <div
          className={( activeTab === tab.FRAME ? ActiveTab : Tab )}
          onClick={() => setActiveTab(tab.FRAME)}
        >
          <ExpandOutlined className={Icon} />
          Cadres
        </div>
        <div
          className={( activeTab === tab.PHOTO ? ActiveTab : Tab )}
          onClick={() => setActiveTab(tab.PHOTO)}
        >
          <PictureOutlined className={Icon} />
          Photos
        </div>
      </div>
      <div className={ContentWrapper}>
        {
          activeTab === tab.CONTEXT &&
            <ContextTab
              contextList={contextList}
              demoContextList={demoDataList.filter((data) => data.type === demoDataType.CONTEXT)}
              onButtonClick={props.onButtonClick}
            />
        }
        {
          activeTab === tab.FRAME &&
            <FrameTab
              frameList={productList.filter((product) => product.type === productType.FRAME || product.type === productType.COLLECTION)}
              onButtonClick={props.onButtonClick}
            />
        }
        {
          activeTab === tab.PHOTO &&
            <PhotoTab
              imageList={imageList}
              onButtonClick={props.onButtonClick}
            />
        }
      </div>
    </div>
  );
};

export default LeftPanel;
