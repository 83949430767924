import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import EditorLayout from 'layouts/editor-layout';
import CheckoutLayout from 'layouts/checkout-layout';
import UploadLayout from 'layouts/upload-layout';
import useBodyClass from 'hooks/useBodyClass';
import auth from '../utils/auth';

/**
 * Views
 * @param {Callback} logout - Callback to handle user's logout
 */
export const Views = (props) => {
  const [logged, setLogged] = useState(auth.isLogged());
  const { location, direction } = props;

  useBodyClass(`dir-${direction}`);

  const handleLogin = (jwt, usr) => {
    auth.saveLogin(jwt, usr);
    setLogged(true);
    return (true);
  };

  const handleSignup = (jwt, usr) => {
    auth.saveLogin(jwt, usr);
    setLogged(true);
    return (true);
  };

  return (
    <Switch>
      <Route path='/upload'>
        <UploadLayout />
      </Route>
      <Route path='/checkout'>
        {
          logged ?
            <CheckoutLayout />
          :
            <Redirect to='/' />
        }
      </Route>
      <Route path='/auth'>
        {
          !logged ?
            <AuthLayout
              direction={direction}
              onLogin={(jwt, user) => handleLogin(jwt, user)}
              onSignup={(jwt, user) => handleSignup(jwt, user)}
            />
          :
            <Redirect to='/' />
        }
      </Route>
      <Route path='/editor'>
        {
          logged ?
            <EditorLayout
              direction={direction}
              location={location}
              logout={() => setLogged(false)}
            />
          :
            <Redirect to='/auth' />
        }
      </Route>
      <Route path='/'>
        {
          logged ?
            <AppLayout
              direction={direction}
              location={location}
              logout={() => setLogged(false)}
            />
          :
            <Redirect to='/auth' />
        }
      </Route>
    </Switch>
  );
};

export default Views;
