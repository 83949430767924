import React, { useState } from 'react';
import { Card, Table, Modal, Row } from 'antd';
import Button from 'components/Button';
import More from 'components/More';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import photo from 'utils/photo';
import SelectFromCatalog from './SelectFromCatalog';

/**
 * List
 * @param {Object} user - Current user
 * @param {Array} frames - List of frames product
 * @param {Array} albums - List of albums product
 * @param {Array} customs - List of customs product
 * @param {Array} productList - List of all products
 * @param {Callback} triggerAlert - Callback to trigger alerts
 * @param {Callback} onProductListUpdate - Callback to handle productList update
 * @param {Callback} onView - Callback to handle view click
 * @param {Callback} onEdit - Callback to handle edit click
 * @param {Callback} onAdd - Callback to handle add click
 * @param {Callback} onDelete - Callback to handle delete click
 */
const List = (props) => {
  const [showCatalogSelect, setShowCatalogSelect] = useState(false);
  const frameColumns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      key: 'photo',
      render: (image) => <img src={image ? image : photo.NO_PHOTO_URL} style={{ height: '100px', widht: '100px' }} alt='product-preview' />
    },
    {
      title: 'Fournisseur',
      dataIndex: 'supplier',
      key: 'supplier'
    },
    {
      title: 'Nom du produit',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Dimensions',
      dataIndex: 'specs',
      key: 'dimensions',
      render: (specs) => <>{ specs.dimensions.map((dimension) => <><span>{ dimension.size.width } cm x { dimension.size.height } cm</span><br /></>) }</>
    },
    {
      title: 'Prix d\'Achat',
      dataIndex: 'specs',
      key: 'buyingPrice',
      render: (specs) => <>{ specs.dimensions.map((dimension) => <><span>{ dimension.price.buy } €</span><br /></>) }</>
    },
    {
      title: 'Prix de Vente',
      dataIndex: 'specs',
      key: 'sellingPrice',
      render: (specs) => <>{ specs.dimensions.map((dimension) => <><span>{ dimension.price.sell } €</span><br /></>) }</>
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, context) => (
        <More
          onView={() => props.onView(context.id)}
          onEdit={() => props.onEdit(context.id)}
          onDelete={() => onDelete(context.id)}
        />
      )
    }
  ];
  const albumColumns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      key: 'photo',
      render: (image) => <img src={image ? image : photo.NO_PHOTO_URL} style={{ height: '100px', widht: '100px' }} alt='product-preview' />
    },
    {
      title: 'Fournisseur',
      dataIndex: 'supplier',
      key: 'supplier'
    },
    {
      title: 'Nom du produit',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Nombre de pages',
      dataIndex: 'specs',
      key: 'size',
      render: (specs) => <span>{ specs.size }</span>
    },
    {
      title: 'Prix d\'Achat',
      dataIndex: 'specs',
      key: 'buyingPrice',
      render: (specs) => <span>{ specs.price.buy } €</span>
    },
    {
      title: 'Prix de Vente',
      dataIndex: 'specs',
      key: 'sellingPrice',
      render: (specs) => <span>{ specs.price.sell } €</span>
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, context) => (
        <More
          onView={() => props.onView(context.id)}
          onEdit={() => props.onEdit(context.id)}
          onDelete={() => onDelete(context.id)}
        />
      )
    }
  ];
  const customColumns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      key: 'photo',
      render: (image) => <img src={image ? image : photo.NO_PHOTO_URL} style={{ height: '100px', widht: '100px' }} alt='product-preview' />
    },
    {
      title: 'Fournisseur',
      dataIndex: 'supplier',
      key: 'supplier'
    },
    {
      title: 'Nom du produit',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'specs',
      key: 'description',
      render: (specs) => <span>{ specs.description }</span>
    },
    {
      title: 'Prix d\'Achat',
      dataIndex: 'specs',
      key: 'buyingPrice',
      render: (specs) => <span>{ specs.price.buy } €</span>
    },
    {
      title: 'Prix de Vente',
      dataIndex: 'specs',
      key: 'sellingPrice',
      render: (specs) => <span>{ specs.price.sell } €</span>
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, context) => (
        <More
          onView={() => props.onView(context.id)}
          onEdit={() => props.onEdit(context.id)}
          onDelete={() => onDelete(context.id)}
        />
      )
    }
  ];
  const actionType = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete'
  };

  const handleProductListUpdate = (action, product) => {
    if (action === actionType.CREATE) {
      props.productList.push(product);
      props.onProductListUpdate();
      return (true);
    }
    props.productList.forEach((item, index) => {
      if (item.name === product.name && item.supplier === product.supplier) {
        if (action === actionType.UPDATE) {
          props.productList[index] = product;
          props.onProductListUpdate();
        } else if (action === actionType.DELETE) {
          props.productList.splice(index, 1);
          props.onProductListUpdate();
        }
      }
    });
    props.onProductListUpdate();
  };

  const handleProductDeletionSuccess = () => {
    const message = {
      DEFAULT: 'Le produit a bien été supprimé'
    };

    props.triggerAlert({ show: true, type: 'success', message: message.DEFAULT });
    props.onDelete();
  }

  const handleProductDeletionFailure = (error) => {
    const message = {
      DEFAULT: 'Impossible de supprimer ce produit, veuillez réessayer'
    };

    props.triggerAlert({ show: true, type: 'error', message: message.DEFAULT });
    appLogs.logError({ function: 'handleProductDeletionFailure', error });
  }

  const handleDelete = (productId) => {
    request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/products/${productId}`, props.user.jwt, handleProductDeletionSuccess, handleProductDeletionFailure);
  }

  const onDelete = (productId) => {
    Modal.confirm({
      title: 'Confirmation',
      content: 'Etes vous sûr de vouloir supprimer ce produit ?',
      okText: 'Oui, supprimer',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        handleDelete(productId);
      }
    });
  };

  return (
    <Card>
      <SelectFromCatalog
        user={props.user}
        show={showCatalogSelect}
        frames={props.frames}
        onProductListUpdate={(action, product) => handleProductListUpdate(action, product)}
        onOk={() => setShowCatalogSelect(false)}
        onCancel={() => setShowCatalogSelect(false)}
      />
      <h1>Mes Produits</h1>
      <Row justify="end">
        <div style={{ marginRight: '16px' }}>
          <Button type='default' onClick={() => setShowCatalogSelect(true)}>Séléctionner depuis le catalogue fournisseur</Button>
        </div>
        <div>
          <Button type='primary' onClick={props.onAdd}>Ajouter un produit</Button>
        </div>
      </Row>
      <div>
        <h2>Les Agrandissements</h2>
        <Table
          columns={frameColumns}
          dataSource={props.frames}
          pagination={false}
        />
        <br /><br /><br />
        <h2>Les Albums</h2>
        <Table
          columns={albumColumns}
          dataSource={props.albums}
          pagination={false}
        />
        <br /><br /><br />
        <h2>Les Produits Personnalisés</h2>
        <Table
          columns={customColumns}
          dataSource={props.customs}
          pagination={false}
        />
      </div>
    </Card>
  );
};

export default List;
