import React from 'react';
import { Row, Card } from 'antd';
import Confetti from 'react-confetti';
import actionLogger from 'utils/actionLogger';
import auth from 'utils/auth';
import Button from 'components/Button';
import { THEME_COLOR } from 'configs/AppConfig';
import OnboardingScreen from './OnboardingScreen';
import SmallScreen from './SmallScreen';

const getScreenSize = () => {
  const width = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const height = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  return ({ height, width});
};

/**
 * Home
 * @param {Callback} logout - Callback to logout user
 */
const Home = (props) => {
  const user = auth.getUser();

  const logout = () => {
    actionLogger.logAction(user, actionLogger.action.CLICK_DISCONNECT);
    auth.logout();
    props.logout();
  }

  if (getScreenSize().width < 500) {
    return (
      <SmallScreen />
    );
  }

  return (
    <div style={{ color: THEME_COLOR.darkest }}>
      <Confetti recycle={false} numberOfPieces={500} />
      <Card>
        <Row center='span' justify='center'>
          <img src='/img/celebration.png' alt='' />
        </Row>
        <br />
        <Row center='span' justify='center'>
          <h1>Félicitations !</h1>
        </Row>
        {
          !user?.usr?.confirmed ?
            <Row center='span' justify='center'>
              <p>Votre compte a été créé, mais vous devez encore le valider (un email vous a été envoyé)</p>
            </Row>
          :
            <Row center='span' justify='center'>
              <p>La création de votre compte est un succès !</p>
            </Row>
        }
        <OnboardingScreen
          user={user}
        />
        <Button onClick={logout}>Déconnexion</Button>
      </Card>
    </div>
  )
}

export default Home;
