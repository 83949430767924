import axios from 'axios';
import request from 'utils/request';

const updateUser = (handleUserUpdateCompletion) => {
  const user = getUser();

  const handleUserAccessSuccess = (newUser) => {
    localStorage.setItem('usr', JSON.stringify(newUser));
    handleUserUpdateCompletion({
      jwt: user.jwt,
      usr: newUser
    });
  };

  const handleUserAccessFailure = (error) => {
    console.log('> error : ', error);
  };

  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/users/me`,
    {},
    user.jwt,
    (result) => handleUserAccessSuccess(result),
    (error) => handleUserAccessFailure(error)
  );
};

const isDemo = () => {
  const user = getUser();

  if (user.usr.isDemo) {
    return (true);
  }
  return (false);
};

const isConfirmed = () => {
  const user = getUser();

  if (user.usr.confirmed) {
    return (true);
  }
  return (false);
};

const isLogged = () => {
  const lastLogin = localStorage.getItem('lastLogin');
  const now = Date.now();

  if (!lastLogin || ((now - lastLogin) / (1000 * 60 * 60 * 24)) >= 30) {
    console.log('> lastLogin : ', lastLogin);
    console.log('> out !');
    return (false);
  }
  if (!!localStorage.getItem('jwt') && localStorage.getItem('jwt') !== 'undefined') {
    return (true);
  }
  return (false);
};

const saveLogin = (jwt, usr) => {
  localStorage.setItem('jwt', jwt);
  localStorage.setItem('usr', JSON.stringify(usr));
  localStorage.setItem('lastLogin', Date.now());
  return (true);
};

const setLocal = (key, value) => {
  localStorage.setItem(key, value);
  return (true);
};

const getLocal = (key) => {
  return (localStorage.getItem(key));
};

const removeLocal = (key) => {
  return (localStorage.removeItem(key));
};

const login = async (email, password, onSuccess, onFailure) => {
  axios.post(`${request.API_ENDPOINT_URL}/auth/local`, { identifier: email, password })
    .then((result) => onSuccess(result.data))
    .catch((error) => onFailure(error))
};

const register = (email, password, onSuccess, onFailure) => {
  const userCredentials = {
    username: email,
    email: email,
    password: password
  };

  request.postData(`${request.API_ENDPOINT_URL}/auth/local/register`, userCredentials)
    .then(
      (result) => {
        if (result.statusCode === 400) {
          return (onFailure(result));
        }
        return (onSuccess(result));
      },
      (error) => onFailure(error)
    );
};

const resetPassword = (code, password, onSuccess, onFailure) => {
  const newPassword = {
    code: code,
    password: password,
    passwordConfirmation: password
  };

  request.postData(`${request.API_ENDPOINT_URL}/auth/reset-password`, newPassword)
    .then(
      (result) => {
        if (result.statusCode === 400) {
          return (onFailure(result));
        }
        return (onSuccess(result));
      },
      (error) => onFailure(error)
    );
};

const logout = () => {
  localStorage.removeItem('jwt');
  localStorage.removeItem('usr');
  return (true);
};

const getUser = () => {
  const user = {
    jwt: localStorage.getItem('jwt'),
    usr: JSON.parse(localStorage.getItem('usr'))
  };

  return (user);
};

const auth = {
  updateUser,
  isDemo,
  isConfirmed,
  isLogged,
  login,
  saveLogin,
  register,
  resetPassword,
  logout,
  setLocal,
  getLocal,
  removeLocal,
  getUser
};

export default auth;
