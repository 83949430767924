import request from 'utils/request';
import auth from 'utils/auth';

const user = auth.getUser();

const sendCalibrationLink = (userId, clientId, contextId, handleSuccess, handleFailure) => {
  const link = `/editor/calibrate/${clientId}/${contextId}`;

  request.postDataInline(
    `${request.API_ENDPOINT_URL}/mail/send-calibration-link`,
    { userId, clientId, link },
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const sendEmailConfirmation = (handleSuccess, handleFailure) => {
  request.postDataInline(
    `${request.API_ENDPOINT_URL}/auth/send-email-confirmation`,
    { email: user.usr.email },
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const welcomeNewSubscriber = (handleSuccess, handleFailure) => {
  request.postDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/mail/welcome-new-subscriber`,
    { subscriberEmail: user.usr.email },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const email = {
  sendEmailConfirmation,
  welcomeNewSubscriber,
  sendCalibrationLink
};

export default email;
