import React from 'react';
import { Form, Row, Col } from 'antd';

const Frame = (props) => {
  return (
    <Row>
      <Col span={8}>
        <Form.Item label='Dimensions'>
          {
            props.product.specs.dimensions.map((dimension, index) => <p key={index}>{ dimension.size.width } cm x { dimension.size.height } cm</p>)
          }
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={`Prix d'achat`}>
          {
            props.product.specs.dimensions.map((dimension, index) => <p key={index}>{ dimension.price.buy } €</p>)
          }
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label='Prix de vente'>
          {
            props.product.specs.dimensions.map((dimension, index) => <p key={index}>{ dimension.price.sell } €</p>)
          }
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Frame;
