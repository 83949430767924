import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import auth from 'utils/auth';
import SubscriptionManagement from './SubscriptionManagement';

/**
 * CheckoutViews
 */
const CheckoutViews = () => {
  const user = auth.getUser();

  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route path='/'>
          <SubscriptionManagement user={user} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default CheckoutViews;
