import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import ClientForm from 'components/ClientForm';
import notification from 'utils/notification';
import request from 'utils/request';
import appLogs from 'utils/appLogs';
import regex from 'utils/regex';


/**
 * ClientEdit
 * @param {Object} user - Current user
 * @param {Integer} clientId - current client id
 * @param {Callback} onCancel - Callback to handle 'Annuler' click
 * @param {Callback} onUpdate - Callback to handle 'Enregistrer' click
 */
const ClientEdit = (props) => {
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(null);

  const dataIsCorrect = (data) => {
    if (!data.name) {
      notification.error('Pas de nom du client', 'Veuillez renseigner un nom pour le client');
      return (false);
    } else if (data.email && !regex.emailIsCorrect(data.email)) {
      notification.error('Email invalide', 'Veuillez renseigner un email correct');
      return (false);
    }
    return (true);
  };

  const handleClientUpdateSuccess = (result) => {
    notification.success('Félicitations !', 'Le client a bien été créé');
    setLoading(false);
    props.onUpdate();
  };

  const handleClientUpdateFailure = (error) => {
    notification.error('Impossible de modifier le client', 'Veuillez réessayer plus tard');
    appLogs.logError({ function: 'handleClientUpdateFailure', error });
    setLoading(false);
  };

  const handleFormSubmit = (data) => {
    const tmpClient = {
      name: data.name,
      number: data.number,
      contact: {
        email: data.email,
        phone: data.phone
      }
    };

    if (!dataIsCorrect(data)) {
      return (false);
    }
    setLoading(true);
    request.putDataWithAuth(`${request.API_ENDPOINT_URL}/clients/${props.clientId}`, tmpClient, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientUpdateFailure(result));
        }
        return (handleClientUpdateSuccess(result));
      })
      .catch((error) => handleClientUpdateFailure(error));
  };

  const handleClientAccessSuccess = (result) => {
    setClient(result);
  };

  const handleClientAccessFailure = (error) => {
    appLogs.logError({ function: 'handleClientAccessFailure', component: 'ClientEdit', error });
  };

  useEffect(() => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/clients/${props.clientId}`, {}, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientAccessFailure(result));
        }
        return (handleClientAccessSuccess(result));
      })
      .catch((error) => handleClientAccessFailure(error));
  }, []);

  if (client === null) {
    return (<></>);
  }

  return (
    <Card>
      <ClientForm
        title='Modifier un client'
        loading={loading}
        currentClient={client}
        onCancel={props.onCancel}
        onFinish={handleFormSubmit}
      />
    </Card>
  );
};

export default ClientEdit;
