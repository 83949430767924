import React, { useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import Button from 'components/Button';
import Loading from 'components/Loading';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import { ButtonContainer } from './GeneralInformations.module.css';

/**
 * Handle the credentials informations
 * @param {Enumerator} state - Weather it is in VIEW or EDIT mode
 * @param {Object} user - User
 * @param {Object} profile - Profile of a given user
 * @param {Boolean} loading - Weather or not the data are retrieved yet
 * @param {Callback} setAlert - Callback to display success and error messages
 */
const GeneralInformations = (props) => {
  const [state, setState] = useState(props.state.VIEW);
  const [loading, setLoading] = useState(false);

  const handleProfileUpdateSuccess = (data) => {
    const message = {
      DEFAULT: 'Votre profil a bien été mis à jour !'
    };

    setLoading(false);
    setState(props.state.VIEW);
    props.setAlert({ show: true, type: 'success', message: message.DEFAULT });
    setTimeout(() => props.setAlert({ show: false, type: 'info', message: '' }), 5000);
  }

  const handleProfileUpdateFailure = () => {
    const message = {
      DEFAULT: 'Votre profil n\'a pas été mis à jour, veuillez réessayer'
    };

    setLoading(false);
    setState(props.state.VIEW);
    props.setAlert({ show: true, type: 'error', message: message.DEFAULT });
    setTimeout(() => props.setAlert({ show: false, type: 'info', message: '' }), 5000);
    appLogs.logError({ function: 'handleProfileUpdateFailure' });
  }

  const handleFormSubmit = (data) => {
    if (data.password === undefined) {
      setState(props.state.VIEW);
      return (true);
    }

    setLoading(true);
    request.putDataWithAuth(`${request.API_ENDPOINT_URL}/users/${props.user.usr.id}`, data, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleProfileUpdateFailure());
          }
          return (handleProfileUpdateSuccess(result));
        },
        (error) => handleProfileUpdateFailure()
      );
  };

  if (props.loading) {
    return (
      <Loading />
    );
  }

  return (
    <div>
      <Form
        name='informations'
        layout='vertical'
        initialValues={
          {
            email: props.profile?.get()?.email
          }
        }
        onFinish={handleFormSubmit}
        onFinishFailed={handleFormSubmit}
      >
        <div className={ButtonContainer}>
          { state === props.state.VIEW && <Button type='primary' onClick={() => setState(props.state.EDIT)}>Modifier</Button> }
          {
            state === props.state.EDIT &&
              <>
                <Button type='link' onClick={() => setState(props.state.VIEW)}>Annuler</Button>
                <Button type='primary' htmlType='submit' loading={loading}>Enregistrer</Button>
              </>
          }
        </div>
        <Row>
          <Col span={6}>
            <Form.Item label='Identifiant'>
              { state === props.state.VIEW && <p>{props.profile.get().email}</p> }
              {
                state === props.state.EDIT &&
                  <Form.Item name='email'>
                    <Input disabled />
                  </Form.Item>
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item label='Mot de passe'>
              { state === props.state.VIEW && <p>*****</p> }
              {
                state === props.state.EDIT &&
                  <Form.Item name='password'>
                    <Input.Password placeholder='Nouveau mot de passe' />
                  </Form.Item>
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GeneralInformations;
