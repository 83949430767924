import React, { useState, useEffect } from 'react';
import { Card, Row, Radio, Checkbox } from 'antd';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import Image from 'controllers/Image';
import Profile from 'controllers/Profile';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Mosaic from './Mosaic';
import styles from './PlancheContact.module.css';

const status = {
  ALL: 1,
  LIKED: 2
};

/**
 * PlancheContact
 * @param {Integer} shootingId - Id of shooting to display
 * @param {Object} client - Current client
 * @param {Callback} onClose - Callback to handle outside modal click
 */
const PlancheContact = (props) => {
  const [imageList, setImageList] = useState([]);
  const [selectionStatus, setSelectionStatus] = useState(status.ALL);
  const [selection, setSelection] = useState([]);
  const [profile, setProfile] = useState(null);
  const [isBigImage, setIsBigImage] = useState(false);

  const onPrint = () => {
    window.print();
    props.onClose();
  };

  const onSelectionChange = (value) => {
    if (value === status.ALL) {
      setSelectionStatus(status.ALL);
      setSelection(imageList);
    } else {
      setSelectionStatus(status.LIKED);
      setSelection(imageList.filter(image => image.isLiked));
    }
  };

  const handleProfileAccessSuccess = (result) => {
    setProfile(result);
  };

  const handleProfileAccessFailure = (error) => {
    notification.error('Erreur', 'Impossible de récupérer votre profil, veuillez réessayer');
    appLogs.logError({ function: 'handleProfileAccessFailure', error });
  };

  const handleImageAccessSuccess = (result) => {
    setImageList(result);
    setSelection(result);
  };

  const handleImageAccessFailure = (error) => {
    notification.error('Erreur', 'Impossible de récupérer les images pour cette planche, veuillez réessayer');
    appLogs.logError({ function: 'handleImageAccessFailure', error });
  };

  useEffect(() => {
    if (!props.shootingId) {
      return (false);
    }
    Image.getImageByShootingId(
      props.shootingId,
      (result) => handleImageAccessSuccess(result),
      (error) => handleImageAccessFailure(error)
    );
    Profile.get(
      (result) => handleProfileAccessSuccess(result),
      (error) => handleProfileAccessFailure(error)
    );
  }, [ props.shootingId ]);

  if (props.shootingId === 0) {
    return (<></>);
  }

  return (
    <Card
      id='container'
      bordered={false}
      className={styles.ModalContainer}
    >
      <div id='preview' className={styles.ModalStyle}>
        <h1>{ props.client.name } - Planche Contact</h1>
        <div className={styles.DecorationItem}>
          <p>
            { profile?.visualIdentities[0].name }<br />
            { profile?.visualIdentities[0].phone }<br />
            { profile?.visualIdentities[0].email }
          </p>
          <img
            src={profile?.visualIdentities[0].logo}
            alt='logo'
            className={styles.Logo}
          />
        </div>
        <div className={styles.ControlItem}>
          <Button type='primary' onClick={() => onPrint()}>
            Imprimer
          </Button>
          <Row style={{ marginTop: '16px' }}>
            <Radio.Group onChange={(event) => onSelectionChange(event.target.value)} value={selectionStatus}>
              <Radio value={status.ALL}>Toutes les photos</Radio>
              <Radio value={status.LIKED}>Les photos aimées</Radio>
            </Radio.Group>
          </Row>
          <Row style={{ marginTop: '16px' }}>
            <Checkbox
              checked={isBigImage}
              onChange={(event) => setIsBigImage(event.target.checked)}
            >
              Agrandir les photos
            </Checkbox>
          </Row>
        </div>
        {
          !imageList.length ?
              <Loading />
            :
              <Mosaic imageList={selection} isBigImage={isBigImage} />
        }
      </div>
    </Card>
  );
};

export default PlancheContact;
