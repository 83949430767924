import React from 'react';
import Button from 'components/Button';
import { ButtonWrapper } from './SuccessScreen.module.css';

/**
 * SuccesScreen
 * @param {Integer} clientId - Current client's id
 */
const SuccessScreen = (props) => {
  return (
    <div>
      <p>Vous pouvez désormais revenir au client</p>
      <Button
        type='primary'
        onClick={() => window.location = `/mes-clients/${props.clientId}` }
        className={ButtonWrapper}
      >
        Revenir au client
      </Button>
    </div>
  );
};

export default SuccessScreen;
