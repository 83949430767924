import React from 'react';
import Button from 'components/Button';

/**
 * Tutorial
 * @param {Callback} onNext - Callback to call when the user clicks on the next button
 */
const Tutorial = (props) => {
  return (
    <div>
      <h1>Attention</h1>
      <p>
        Mettez vous bien face au mur pour prendre votre photo
      </p>
      <img
        src="/img/lumina-tuto.png"
        alt="tutoriel"
        style={{
          width: 'calc(100% - 32px)',
          height: 'auto',
          margin: '16px'
        }}
      />
      <Button type='primary' onClick={() => props.onNext()}>
        J'ai compris
      </Button>
    </div>
  );
};

export default Tutorial;
