import React, { useState } from 'react';
import { EDITOR } from 'configs/AppConfig';
import drag from 'utils/drag';
import Toolbar from './Toolbar';
import PictureObject from './PictureObject';
import { Wrapper, Context, EmptyTextContainer } from './Editor.module.css';

const computeCanva = () => {
  const width = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  ) - EDITOR.LEFT_PANEL_WIDTH.PIXEL_FORMAT;
  const height = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  ) - EDITOR.TOP_NAV_HEIGHT.PIXEL_FORMAT;

  return ({ height, width});
};

/**
 * Handle the Editor part
 * @param {Object} scene - The scene object
 * @param {Callback} newScene - Callback to create a new scene
 * @param {Callback} saveScene - Callback to update scene in db
 */
const Editor = (props) => {
  const [refresh, setRefresh] = useState(false);
  const canva = {
    ratio: computeCanva().width / computeCanva().height,
    size: computeCanva()
  };

  const updateScene = (scene, disableAutoSaving=false) => {
		if (!disableAutoSaving) {
			props.saveScene(scene);
		}
    setRefresh(!refresh);
  };

  const updateContext = (event) => {
    const image = new Image();

    props.scene.context = drag.getData(event, drag.dataType.CONTEXT);
    image.src = props.scene.context.url;
    image.onload = () => {
      const context = {
        ratio: image.naturalWidth / image.naturalHeight,
        size: {
          height: image.naturalHeight,
          width: image.naturalWidth
        }
      };

      if (context.ratio >= canva.ratio) {
        props.scene.scale = canva.size.width / context.size.width;
      } else {
        props.scene.scale = canva.size.height / context.size.height;
      }
      context.size.height = context.size.height * props.scene.scale;
      context.size.width = context.size.width * props.scene.scale;
      props.scene.coefficient = context.size.width / props.scene.context.size.width;
      updateScene(props.scene);
    };
  };

	const addPictureToPictureList = (pos, frame) => {
		props.scene.pictureList.push({
      show: true,
      pos: {
        y: pos.y,
        x: pos.x
      },
      frame: frame,
    });
	};

  const updatePictureList = (event) => {
		addPictureToPictureList(
			{
				y: event.pageY - EDITOR.TOP_NAV_HEIGHT.PIXEL_FORMAT,
				x: event.pageX - EDITOR.LEFT_PANEL_WIDTH.PIXEL_FORMAT
			},
			drag.getData(event, drag.dataType.FRAME)
		);
    updateScene(props.scene);
  };

  const drop = (event) => {
    drag.allowDrop(event);
    if (drag.getData(event, drag.dataType.CONTEXT)) {
      updateContext(event);
    } else if (drag.getData(event, drag.dataType.FRAME)) {
      updatePictureList(event);
    } else if (drag.getData(event, drag.dataType.COLLECTION)) {
			const collection = drag.getData(event, drag.dataType.COLLECTION);
			const collectionId = Math.random();
			const dropPoint = {
				y: event.pageY - EDITOR.TOP_NAV_HEIGHT.PIXEL_FORMAT,
				x: event.pageX - EDITOR.LEFT_PANEL_WIDTH.PIXEL_FORMAT
			}

			collection.specs.itemList[0].forEach((item, index) => {
				const pos = {
					y: dropPoint.y + (item.pos.y + item.height / 2) * props.scene.coefficient,
					x: dropPoint.x + (item.pos.x + item.width / 2) * props.scene.coefficient
				};
				const frameDimensions = collection.specs.itemList
					.map((tmpCollection) => tmpCollection
						.map((tmpItem, tmpIndex) => tmpIndex === index ? { size: { width: tmpItem.width, height: tmpItem.height } } : null)
						.filter((tmpItem) => tmpItem !== null)[0]
					);
				const childFrame = {
					isCollectionItem: true,
					collectionId: collectionId,
					itemId: Math.random(),
					isRotated: false,
					currentDimension: 0,
					relativePos: item.pos,
					specs: { dimensions: frameDimensions }
				}

				addPictureToPictureList(pos, childFrame);
			});
			updateScene(props.scene);
		}
  };

  const updatePhoto = (index, photo) => {
    props.scene.pictureList[index]['photo'] = photo;
    updateScene(props.scene);
  }

  const updateFrame = (index, frame) => {
    props.scene.pictureList[index]['frame'] = frame;
    updateScene(props.scene);
  }

  const updatePicture = (index, picture, disableAutoSaving) => {
    props.scene.pictureList[index] = picture;
    updateScene(props.scene, disableAutoSaving);
  };

  const deletePicture = (index) => {
    props.scene.pictureList[index].show = false;
    updateScene(props.scene);
  };

  const updateSceneLikeStatus = (isLiked) => {
    props.scene.isLiked = isLiked;
    updateScene(props.scene);
  }

  return (
    <div
      id='editor-wrapper'
      className={Wrapper}
      onDrop={drop}
      onDragOver={drag.allowDrop}
    >
      <Toolbar
        pictureList={props.scene.pictureList}
        isLiked={props.scene.isLiked}
        onNextScene={props.nextScene}
        onNewScene={props.newScene}
        onLike={() => updateSceneLikeStatus(true)}
        onUnlike={() => updateSceneLikeStatus(false)}
      />
      {
        props.scene.pictureList.map((picture, index) => (
          picture.show &&
            <PictureObject
              index={index}
							scene={props.scene}
              coefficient={props.scene.coefficient}
              pictureList={props.scene.pictureList}
              picture={picture}
              onPhotoUpdate={(photo) => updatePhoto(index, photo)}
              onFrameUpdate={(frame) => updateFrame(index, frame)}
              onPictureUpdate={(picture => updatePicture(index, picture, false))}
							onCollectionItemUpdate={(index, picture) => updatePicture(index, picture, true)}
              onDelete={() => deletePicture(index)}
            />
        ))
      }
      {
        props.scene.context.url ?
            <img
              id='context'
              className={Context}
              src={props.scene.context.url}
              alt='context'
              draggable={false}
            />
          :
            <div className={EmptyTextContainer}>
              <p>Glissez un intérieur ici !</p>
            </div>
      }
    </div>
  );
};

export default Editor;
