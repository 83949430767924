import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import Product from 'controllers/Product';
import Profile from 'controllers/Profile';
import MarginConfig from './MarginConfig';
import ModelRow from './ModelRow';
import { TopButton } from './SelectFromCatalog.module.css';
import catalog from './catalog.json';

/**
 * SaveButton
 * @param {Boolean} loading - Weather or not the button is loading
 * @param {Callback} onClick - Callback to handle button click
 */
const SaveButton = (props) => {
  return (
    <Button
      key='Enregistrer'
      type='primary'
      loading={props.loading}
      onClick={() => props.onClick()}
    >
      Enregistrer les modifications
    </Button>
  );
};

/**
 * SelectFromCatalog
 * @param {Object} user - Current user
 * @param {Boolean} show - Weather or not to show the modal
 * @param {Array} frames - List of frame products
 * @param {Callback} onProductListUpdate - Callback to handle productList update
 * @param {Callback} onOk - Callback to handle the ok click
 * @param {Callback} onCancel - Callback to handle the cancel click
 */
const SelectFromCatalog = (props) => {
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const actionType = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete'
  };

  const getProductIndexInSelection = (product) => {
    let index = 0;

    for (const item of selection) {
      if (item.name === product.name && item.supplier === product.supplier) {
        return (index);
      }
      index += 1;
    }
    return (-1);
  };

  const handleSelectionUpdate = (product) => {
    if (getProductIndexInSelection(product) === -1) {
      selection.push(product);
    } else {
      selection[getProductIndexInSelection(product)] = product;
    }
    setSelection(selection);
  };

  const exitModal = () => {
    setLoading(false);
    setSelection([]);
    props.onOk();
  };

  const handleProductCreationSuccess = (product) => {
    props.onProductListUpdate(actionType.CREATE, product);
    exitModal();
    notification.success('Félicitations !', `Le produit ${product.name} a bien été créé`);
  };

  const handleProductCreationFailure = (error, product) => {
    appLogs.logError({
      function: 'handleProductCreationFailure',
      component: 'SelectFromCatalog',
      product,
      error
    });
    notification.error(`Impossible de créer le produit ${product.name}`, 'Veuillez ré-essayer plus tard');
    exitModal();
  };

  const handleProductUpdateSuccess = (product) => {
    props.onProductListUpdate(actionType.UPDATE, product);
    exitModal();
    notification.success('Félicitations !', `Le produit ${product.name} a bien été mis à jour`);
  };

  const handleProductUpdateFailure = (error, product) => {
    appLogs.logError({
      function: 'handleProductUpdateFailure',
      component: 'SelectFromCatalog',
      product,
      error
    });
    exitModal();
    notification.error(`Impossible de mettre à jour le produit ${product.name}`, 'Veuillez ré-essayer plus tard');
  };

  const handleProductDeleteSuccess = (product) => {
    props.onProductListUpdate(actionType.DELETE, product);
    exitModal();
    notification.success('Félicitations !', `Le produit ${product.name} a bien été supprimé`);
  };

  const handleProductDeleteFailure = (error, product) => {
    appLogs.logError({
      function: 'handleProductDeleteFailure',
      component: 'SelectFromCatalog',
      product,
      error
    });
    exitModal();
    notification.error(`Impossible de supprimer le produit ${product.name}`, 'Veuillez ré-essayer plus tard');
  };

  const handleSave = () => {
    if (!selection.length) {
      return (false);
    }
    setLoading(true);
    selection.forEach((product) => {
      if (!product.id && product.specs.dimensions.length) {
        Product.create(
          product,
          () => handleProductCreationSuccess(product),
          (error) => handleProductCreationFailure(error, product)
        );
      } else if (product.id && product.specs.dimensions.length) {
        Product.update(
          product,
          () => handleProductUpdateSuccess(product),
          (error) => handleProductUpdateFailure(error, product)
        );
      } else if (product.id && !product.specs.dimensions.length) {
        Product.remove(
          product.id,
          () => handleProductDeleteSuccess(product),
          (error) => handleProductDeleteFailure(error, product)
        );
      }
    });
    setSelection([]);
  };

  const getCurrentProduct = (supplierName, model) => {
    const tmpProduct = {
      photo: model.photo,
      supplier: supplierName,
      name: model.modelName,
      type: 'frame',
      email: props.user.usr.email,
      specs: {
        'dimensions': []
      }
    };

    for (const product of props.frames) {
      if (product.name === model.modelName && product.supplier === supplierName) {
        return (product);
      }
    }
    return (tmpProduct);
  };

  useEffect(() => {
    const handleProfileAccessSuccess = (result) => {
      const tmpProfile = result;

      // to remove when every profile.id < 7 in DB has a margin
      if (!tmpProfile.margin) {
        tmpProfile.margin = {};
      }
      setProfile(tmpProfile);
    };

    const handleProfileAccessFailure = (error) => {
      appLogs.logError({
        function: 'handleProfileAccessFailure',
        component: 'SelectFromCatalog',
        error
      });
    };

    Profile.get(
      (result) => handleProfileAccessSuccess(result),
      (error) => handleProfileAccessFailure(error)
    );
  }, []);

  if (!profile) {
    return (<></>);
  }

  if (!profile.margin || !profile.margin['Laminamarc'] || !profile.margin['OneLabPro']) {
    return (
      <>
        {
          props.show &&
            <MarginConfig
              show={props.show}
              profile={profile}
              onOk={() => setRefresh(!refresh)}
              onCancel={() => props.onCancel()}
            />
        }
      </>
    );
  }

  return (
    <>
      {
        props.show &&
          <Modal
            visible={props.show}
            title='Selectionner depuis le catalogue'
            width='76%'
            footer={[
              <Button key='Annuler' onClick={() => props.onCancel()}>
                Annuler
              </Button>,
              <SaveButton
                loading={loading}
                onClick={() => handleSave()}
              />,
            ]}
            onOk={() => handleSave()}
            onCancel={() => props.onCancel()}
          >
            <div className={TopButton}>
              <SaveButton
                loading={loading}
                onClick={() => handleSave()}
              />
            </div>
            {
              catalog.map((supplier) => (
                supplier.series.map((serie) => (
                  serie.models.map((model) => (
                    <ModelRow
                      margin={profile.margin}
                      supplierName={supplier.supplierName}
                      serieName={serie.serieName}
                      model={model}
                      currentProduct={getCurrentProduct(supplier.supplierName, model)}
                      onSelectionUpdate={(product) => handleSelectionUpdate(product)}
                    />
                  ))
                ))
              ))
            }
          </Modal>
      }
    </>
  );
};

export default SelectFromCatalog;
