import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'Lumina';
export const APP_ENDPOINT_URL = window.location.origin;
export const API_ENDPOINT_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/';
export const AUTH_PREFIX_PATH = '/auth';
export const NAV_HEADER_HEIGHT = 70;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR
};

export const THEME_COLOR = {
  darkest: '#224563',
  darker: '#2D5D85',
  dark: '#3975A7',
  default: '#4C8CC2',
  light: '#6FA2CE',
  lighter: '#91B8D9',
  lightest: '#B3CEE5'
};

export const EDITOR = {
  LEFT_PANEL_WIDTH: {
    STRING_FORMAT: '350px',
    PIXEL_FORMAT: 350
  },
  TOP_NAV_HEIGHT: {
    STRING_FORMAT: '70px',
    PIXEL_FORMAT: 70
  }
};
