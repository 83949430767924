import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Profile from './Profile';
import Client from './Client';
import Home from './Home';
import Product from './Product';

/**
 * App's entry point
 * @param {Callback} logout - Callback to handle user's logout
 */
export const AppViews = (props) => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path='/mon-profil'>
          <Profile logout={props.logout} />
        </Route>
        <Route path='/mes-clients'>
          <Client logout={props.logout} />
        </Route>
        <Route path='/mes-produits'>
          <Product logout={props.logout} />
        </Route>
        <Route path='/'>
          <Home logout={props.logout} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
