import React from 'react';
import { Text, Title, Name, SubTitle } from './WelcomeScreen.module.css';

/**
 * WelcomeScreen
 * @param {Object} client - Current client
 * @param {Object} settings - Diaporama settings about music and timing
 */
const WelcomeScreen = (props) => {
	return (
		<div className={Text}>
			<p className={Title}>
				{
					props.settings.welcomeScreen?.title ?
							props.settings.welcomeScreen.title
						:
							<>Bonjour <span className={Name}>{props.client.name}</span></>
				}
			</p>
			<p className={SubTitle}>
				{
					props.settings.welcomeScreen?.description ?
							props.settings.welcomeScreen.description
						:
							<>Installez-vous et préparez-vous<br />
							pour le visionnage de votre séance</>
				}
			</p>
		</div>
	);
}

export default WelcomeScreen;
