import React from 'react';
import photo from 'utils/photo';
import { Container, ImageWrapper, Image } from './Mosaic.module.css';

/**
 * Mosaic
 * @param {Array} imageList - List of images to display
 * @param {Boolean} isBigImage - True if the image is displayed bigger
 */
const Mosaic = (props) => {
  return (
    <div className={Container}>
      {
        props.imageList.map((image, index) => (
          <div
            className={ImageWrapper}
            style={{
              width: props.isBigImage ? '24vw' : '16vw',
            }}
          >
            <img
              key={index}
              src={image.photo}
              alt={image.name}
              className={Image}
            />
            <p>{ photo.getImageName(image) }</p>
          </div>
        ))
      }
    </div>
  );
};

export default Mosaic;
