import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import { Container } from './VisualIdentitySelector.module.css';

const { Option } = Select;

/**
 * VisualIdentitySelector
 * @param {Object} user - Current user
 * @param {Object} shooting - Current shooting
 * @param {Callback} onVisualIdentitySelection - Callback to handle visual identity selection
 */
const VisualIdentitySelector = (props) => {
  const [visualIndentities, setVisualIdentities] = useState([]);

  const onSelection = (value) => {
    props.onVisualIdentitySelection(visualIndentities[value]);
  };

  const handleProfileAccessSuccess = (result) => {
    setVisualIdentities(result[0].visualIdentities);
    if (result[0].visualIdentities?.length) {
      props.onVisualIdentitySelection(result[0].visualIdentities[0]);
    }
  };

  const handleProfileAccessFailure = (error) => {
    notification.error('Impossible de récupérer les identités visuelles', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleProfileAccessFailure', component: 'VisualIdentitySelector', error });
  };

  useEffect(() => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/profiles`, { email: props.user.usr.email }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleProfileAccessFailure(result));
        }
        return (handleProfileAccessSuccess(result));
      })
      .catch((error) => handleProfileAccessFailure(error));
  }, []);

  return (
    <div className={Container}>
      <h3>Identité Visuelle</h3>
      <Select
        defaultValue={0}
        style={{ width: 240 }}
        onChange={(value) => onSelection(value)}
      >
        {
          visualIndentities.map((visualIndentity, index) => (
            <Option value={index}>{ visualIndentity.name }</Option>
          ))
        }
        </Select>
    </div>
  );
};

export default VisualIdentitySelector;
