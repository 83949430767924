/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Row } from 'antd';
import photo from 'utils/photo';
import {
  ImageContainer,
  Image,
  ImageSelected
} from '../ImageRow.module.css';

/**
 * ListRow
 * @param {Array} imageList - List of images to display
 * @param {Boolean} showPhotoNumber - Wether or not to display photo's file name
 * @param {Callback} isSelected - Callback to know whether an image is selected or not
 * @param {Callback} highlightImage - Callback to highlight an image
 * @param {Callback} onImageSelection - Callback to handle image selection
 */
 const ListRow = (props) => {
  return (
    <Row>
      {
        props.imageList.map((image) => (
          <div>
            <div className={ImageContainer}>
              <img
                src={image.photo}
                alt={`photo-${image.id}`}
                draggable={false}
                className={props.isSelected(image.id) ? ImageSelected : Image}
                onClick={() => props.onImageSelection(image)}
                onDoubleClick={() => props.highlightImage(image.photo)}
              />
            </div>
            <Row style={{ margin: '8px' }}>
              <p>{ props.showPhotoNumber && photo.getImageName(image) }</p>
            </Row>
          </div>
        ))
      }
    </Row>
  );
};

export default ListRow;
