import React, { useState } from 'react';
import Context from 'controllers/Context';
import email from 'utils/email';
import WelcomeScreen from './WelcomeScreen';
import Tutorial from './Tutorial';
import SuccessScreen from './SuccessScreen';
import Timeline from './Timeline';
import Preview from './Preview';
import Header from './Header';
import Footer from './Footer';
import { Container } from './Home.module.css';

/**
 * Home
 * @param {Object} client - Current client
 * @param {Integer} userId - Id of the user owning the shooting to which we upload the context
 */
const Home = (props) => {
  const [step, setStep] = useState(0);
  const [photo, setPhoto] = useState({});
  const stepList = [
    <WelcomeScreen onStart={() => setStep(1)} />,
    <Tutorial onNext={() => setStep(2)} />,
    <Timeline step={0} onNextStep={() => setStep(3)} />,
    <Timeline
      step={1}
      onNextStep={(photoUrl) => handlePhotoUpload(photoUrl)}
    />,
    <Preview
      photoUrl={photo}
      onNextStep={() => createContext()}
      onNewPhoto={(photoUrl) => handlePhotoUpload(photoUrl) }
    />,
    <SuccessScreen onStartOver={() => setStep(2)} />
  ];

  const handleContextCreationSuccess = (result) => {
    email.sendCalibrationLink(
      props.userId,
      props.client.id,
      result.id,
      () => {},
      () => {}
    );
    setStep(5);
  };

  const handleContextCreationFailure = (error) => {
    console.log('> error : ', error);
  };

  const createContext = () => {
    const context = {
      url: photo,
      clientId: props.client.id,
      info: {
        size: {
          height: 0,
          width: 0
        }
      },
      userId: props.userId,
      authorId: props.userId
    };

    console.log('> Create context !');
    console.log('> context : ', context);
    Context.create(
      context,
      (result) => handleContextCreationSuccess(result),
      (error) => handleContextCreationFailure(error)
    );
  };

  const handlePhotoUpload = (photoUrl) => {
    setPhoto(photoUrl);
    setStep(4);
  };

  return (
    <div className={Container}>
      <Header client={props.client} />
      { stepList[step] }
      <Footer />
    </div>
  );
};

export default Home;
