import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Steps } from 'antd';
import { THEME_COLOR } from 'configs/AppConfig';
import { getParams } from 'utils/url';
import email from 'utils/email';
import auth from 'utils/auth';
import PlanSelection from './PlanSelection';
import SuccessScreen from './SuccessScreen';

const { Step } = Steps;

const backgroundStyle = {
  backgroundColor: THEME_COLOR.lightest
};

/**
 * SubscriptionManagement
 */
const SubscriptionManagement = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handlePlanSelection = (session) => {
    auth.setLocal('stripeSessionId', session.id);
    window.location = session.url;
  };

  useEffect(() => {
    const params = getParams();

    if (params.success && params.session_id === auth.getLocal('stripeSessionId')) {
      auth.removeLocal('stripeSessionId');
      email.welcomeNewSubscriber(() => {}, () => {});
      setCurrentStep(2);
    }
  }, []);

  return (
    <div className='h-100' style={backgroundStyle}>
      <div className='container d-flex flex-column justify-content-center h-100'>
        <Row justify='center'>
          <Col xs={20} sm={20} md={20} lg={20}>
            <Card>
              <div className='my-4'>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Steps current={currentStep}>
                      <Step title='Création du compte' description='Terminé' />
                      <Step title='Abonnement'description='Choisissez un plan' />
                      <Step title='Paiement' description='Dernière étape !' />
                    </Steps>
                    {
                      currentStep === 1 &&
                        <PlanSelection
                          onPlanSelection={(session) => handlePlanSelection(session)}
                        />
                    }
                    {
                      currentStep === 2 &&
                        <SuccessScreen />
                    }
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SubscriptionManagement;
