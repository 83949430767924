import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import Button from 'components/Button';

/**
 * ClientForm for ClientAdd and ClientEdit
 * @param {String} title - Title displayed on the top of the form
 * @param {Boolean} loading - Form's loading status
 * @param {Object} currentClient - Current client
 * @param {Callback} onCancel - Callback to handle 'Annuler' click
 * @param {Callback} onFinish - Callback to handle 'Enregistrer' click
 */
const ClientForm = (props) => {
  return (
    <Form
      name='add'
      layout='vertical'
      initialValues={{
        name: props.currentClient?.name,
        number: props.currentClient?.number,
        email: props.currentClient?.contact?.email,
        phone: props.currentClient?.contact?.phone
      }}
      onFinish={props.onFinish}
    >
      <Row justify='space-between'>
        <h2>{ props.title }</h2>
        <div>
          <Button type='link' onClick={props.onCancel} style={{ marginRight: '16px' }}>Annuler</Button>
          <Button type='primary' htmlType='submit' loading={props.loading}>Enregistrer</Button>
        </div>
      </Row>
      <br /><br />
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item label='Nom du client'>
            <Form.Item name='name'>
              <Input placeholder='Jean Dupont' />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label='Numéro de client'>
            <Form.Item name='number'>
              <Input placeholder='abc123' />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item label='Email'>
            <Form.Item name='email'>
              <Input placeholder='jean.dupont@mail.com' />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label='Numéro de téléphone'>
            <Form.Item name='phone'>
              <Input placeholder='06.12.34.56.78' />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientForm;
