import React from 'react';

/**
 * Footer
 */
const Footer = () => {
  return (
    <div style={{ margin: '32px 0px' }}>
      <span style={{ marginRight: '8px' }}>Powered by</span>
      <img
        src='/logo.svg'
        alt='Logo Lumina'
        width='32px'
      />
    </div>
  );
};

export default Footer;
