import React, { useState, useEffect } from 'react';
import { Alert } from 'antd';
import VideoModal from 'components/VideoModal';
import Product from 'controllers/Product';
import appLogs from 'utils/appLogs';
import auth from 'utils/auth';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const state = {
  LIST: 'list',
  VIEW: 'view',
  EDIT: 'edit',
  ADD: 'add'
};

const type = {
  FRAME: 'frame',
  ALBUM: 'album',
  CUSTOM: 'custom'
};

/**
 * Product
 */
const ProductView = () => {
  const [currentState, setCurrentState] = useState(state.LIST);
  const [productList, setProductList] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(0);
  const [lastRefresh, setLastRefresh] = useState(Date.now());
  const [showTutorial, setShowTutorial] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, type: 'info', message: '' });
  const user = auth.getUser();

  const triggerAlert = (alertSettings) => {
    setAlert(alertSettings);
    setTimeout(() => setAlert({ show: false, type: 'info', message: '' }), 5000);
  }

  const handleProductAccessSuccess = (result) => {
    setProductList(result);
    setLoading(false);
    if (!result.length) {
      setShowTutorial(true);
    }
  }

  const handleProductAccessFailure = (error) => {
    const message = {
      DEFAULT: 'Impossible de récupérer vos produits. Veuillez ré-essayer'
    };

    triggerAlert({ show: true, type: 'error', message: message.DEFAULT});
    appLogs.logError({ function: 'handleProductAccessFailure', error });
    setLoading(false);
  }

  const getProductList = () => {
    Product.getByAuthorId(user.usr.id, (result) => handleProductAccessSuccess(result), (error) => handleProductAccessFailure(error));
  };

  const refreshComponent = () => {
    getProductList();
    setCurrentState(state.LIST);
    setRefresh(!refresh);
    setLastRefresh(Date.now());
    if (Date.now() - lastRefresh < 1000) {
      setTimeout(() => setRefresh(!refresh), 1000);
    }
  };

  useEffect(() => {
    getProductList();
  }, []);

  if (loading) {
    return (
      <div>Chargement...</div>
    );
  }

  return (
    <div>
      <Alert
        message={alert.message}
        type={alert.type}
        showIcon
        style={
          alert.show ?
              {
                marginLeft: 'calc(50vw - 125px)',
                marginBottom: '20px',
                width: '250px',
                display: ''
              }
            :
              {
                display: 'none'
              }
        }
      />
      <VideoModal
        show={showTutorial}
        onboardingStep={1}
        onOk={() => setShowTutorial(false)}
        onCancel={() => setShowTutorial(false)}
      />
      {
        currentState === state.LIST &&
          <List
            user={user}
            frames={productList.filter((elem) => elem.type === type.FRAME)}
            albums={productList.filter((elem) => elem.type === type.ALBUM)}
            customs={productList.filter((elem) => elem.type === type.CUSTOM)}
            productList={productList}
            triggerAlert={triggerAlert}
            onProductListUpdate={() => refreshComponent()}
            onView={(productId) => {
              setCurrentProduct(productId);
              setCurrentState(state.VIEW);
            }}
            onEdit={(productId) => {
              setCurrentProduct(productId);
              setCurrentState(state.EDIT);
            }}
            onAdd={() => setCurrentState(state.ADD)}
            onDelete={() => refreshComponent()}
          />
      }
      {
        currentState === state.VIEW &&
          <View
            onCancel={() => setCurrentState(state.LIST)}
            onEdit={() => setCurrentState(state.EDIT)}
            type={type}
            product={productList.filter((elem) => elem.id === currentProduct)[0]}
          />
      }
      {
        currentState === state.EDIT &&
          <Edit
            onCancel={() => setCurrentState(state.VIEW)}
            onSave={() => setCurrentState(state.VIEW)}
            triggerAlert={triggerAlert}
            type={type}
            product={productList.filter((elem) => elem.id === currentProduct)[0]}
            user={user}
          />
      }
      {
        currentState === state.ADD &&
          <Add
            onCancel={() => setCurrentState(state.LIST)}
            onSave={() => refreshComponent()}
            triggerAlert={triggerAlert}
            type={type}
            user={user}
          />
      }
    </div>
  );
};

export default ProductView;
