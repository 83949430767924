import React, { useState } from 'react';
import { Steps } from 'antd';
import CalibrationValidation from './CalibrationValidation';
import ObjectDefinition from './ObjectDefinition';
import PointsCreation from './PointsCreation';
import SuccessScreen from './SuccessScreen';
import { Container, StepsWrapper, MainWrapper } from './LeftPanel.module.css';

const { Step } = Steps;

/**
 * LeftPanel
 * @param {Integer} clientId - Current client's id
 * @param {Array} points - List of calibration points
 * @param {Object} context - Current context
 * @param {Callback} onPointsConfirmation - Callback to handle points confirmation
 * @param {Callback} onObjectDefinition - Callback to handle object's width definition
 * @param {Callback} onCalibrationValidation - Callback to handle calibration validation
 * @param {Callback} onStartOver - Callback to handle start over
 */
const LeftPanel = (props) => {
  const [step, setStep] = useState(0);
  const stepList = [
    <PointsCreation
      points={props.points}
      onPointsConfirmation={() => onNextStep()}
      onStartOver={() => onStartOver()}
    />,
    <ObjectDefinition
      onObjectDefinition={(data) => onNextStep(data)}
      onStartOver={() => onStartOver()}
    />,
    <CalibrationValidation
      context={props.context}
      onCalibrationValidation={() => onNextStep()}
      onStartOver={() => onStartOver()}
    />,
    <SuccessScreen clientId={props.clientId} />
  ];

  const onStartOver = () => {
    setStep(0);
    props.onStartOver();
  };

  const onNextStep = (data) => {
    if (step === 0) {
      props.onPointsConfirmation();
      setStep(step + 1);
    } else if (step === 1) {
      props.onObjectDefinition(data);
      setTimeout(() => setStep(step + 1), 1000);
    } else if (step === 2) {
      props.onCalibrationValidation();
      setStep(step + 1);
    }
  };

  return (
    <div className={Container}>
       <Steps current={step} className={StepsWrapper}>
        <Step />
        <Step />
        <Step />
      </Steps>
      <div className={MainWrapper}>
        { stepList[step] }
      </div>
    </div>
  );
};

export default LeftPanel;
