import React, { useState, useEffect } from 'react';
import { Layout, Divider } from 'antd';
import { THEME_COLOR } from 'configs/AppConfig'
import EditorViews from 'Views/editor-views';
import Button from 'components/Button';
import Client from 'controllers/Client';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import { Wrapper, LeftPart, LeftText, Text, Seperator } from './EditorLayout.module.css';

const { Content, Header } = Layout;

/**
 * HeaderNav
 */
const HeaderNav = () => {
  const [isCalibration, setIsCalibration] = useState(false);
  const [shootingId, setShootingId] = useState(0);
  const [client, setClient] = useState({});

  const getBackToShooting = () => {
    window.location = `/mes-clients/${client.id}/${shootingId}?tab=2`;
  };

  const getBackToClient = () => {
    window.location = `/mes-clients/${client.id}`;
  };

  const handleBackClick = () => {
    if (!isCalibration) {
      getBackToShooting();
    } else {
      getBackToClient();
    }
  };

  const handleClientAccessSuccess = (result) => {
    setClient(result);
  };

  const handleClientAccessFailure = (error) => {
    notification.error('Impossible d\'accéder au client', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleClientAccessFailure', component: 'HeaderNav', error });
  };

  useEffect(() => {
    const path = window.location.pathname;
    let clientId = 0;

    if (path.split('/')[2] === 'calibrate') {
      setShootingId(path.split('/')[4]);
      clientId = path.split('/')[3];
      setIsCalibration(true);
    } else {
      setShootingId(path.split('/')[3]);
      clientId = path.split('/')[2];
    }
    Client.getById(
      clientId,
      (result) => handleClientAccessSuccess(result),
      (error) => handleClientAccessFailure(error)
    );
  }, []);

  return (
    <Header className={Wrapper}>
      <div className={LeftPart}>
        <Button
          type='default'
          style={{ marginTop: 0 }}
          onClick={() => handleBackClick()}
        >
          Retour
        </Button>
        <span className={Seperator} />
        <Divider type='vertical' style={{ height: '50%', border: `1px solid ${THEME_COLOR.lightest}50` }}/>
        <span className={Seperator} />
        <div className={LeftText}>
          <p className={Text}>{ client?.name }</p>
        </div>
      </div>
    </Header>
  );
};

/**
 * EditorLayout
 */
const EditorLayout = () => {
  return (
    <Layout>
      <HeaderNav />
      <Layout className='editor-container'>
        <Content>
          <EditorViews />
        </Content>
      </Layout>
    </Layout>
  );
};

export default EditorLayout;
