import React from 'react';
import { Steps } from 'antd';
import PhotoUploader from 'components/PhotoUploader';
import Button from 'components/Button';
import { Container, PhotoWrapper, ButtonWrapper } from './Preview.module.css';

const { Step } = Steps;

/**
 * Preview
 * @param {String} photoUrl - Url of the photo to display
 * @param {Callback} onNextStep - Callback to handle image validation
 * @param {Callback} onNewPhoto - Callback to handle new Photo
 */
const Preview = (props) => {
  return (
    <div className={Container}>
      <Steps
        direction='vertical'
        current={2}
      >
        <Step style={{ display: 'none' }} />
        <Step style={{ display: 'none' }} />
        <Step
          title='Envoie'
          description='Confirmez et envoyez votre photo'
        />
      </Steps>
      <img
        src={props.photoUrl}
        alt='preview'
        className={PhotoWrapper}
      />
      <Button
        type='primary'
        onClick={() => props.onNextStep()}
        className={ButtonWrapper}
      >
        Envoyer
      </Button>
      <PhotoUploader
        text='Reprendre une photo'
        buttonType='link'
        onPhotoUpload={(photoUrl) => props.onNewPhoto(photoUrl)}
      />
    </div>
  );
};

export default Preview;
