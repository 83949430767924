import request from 'utils/request';
import auth from 'utils/auth';

const user = auth.getUser();

const getByShootingId = (shootingId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/diaporama-settings`,
    { ownerId: user.usr.id, shootingId },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const create = (settings, handleSuccess, handleFailure) => {
  request.postDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/diaporama-settings`,
    settings,
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const update = (settings, handleSuccess, handleFailure) => {
  request.putDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/diaporama-settings/${settings.id}`,
    settings,
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const DiaporamaSettings = {
  getByShootingId,
  create,
  update
};

export default DiaporamaSettings;
