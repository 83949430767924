import React, { useState } from 'react';
import Button from 'components/Button';

/**
 * SuccessScreen
 */
const SuccessScreen = () => {
  const [remainingTime, setRemainingTime] = useState(10);

  if (remainingTime > 0) {
    setTimeout(() => setRemainingTime(remainingTime - 1), 1000);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src='/img/celebration.png' alt='' style={{ margin: '64px 0px 8px 0px' }} />
      <h1 style={{ margin: '8px 0px' }}>Felicitations !</h1>
      <p style={{ margin: '8px 0px' }}>La creation de votre compte est un succes</p>
      <Button
        type={remainingTime > 0 ? 'default' : 'primary'}
        style={{
          margin: '16px 0px'
        }}
        disabled={remainingTime > 0 ? true : false}
        onClick={() => window.location = '/'}
      >
        Commencer a utiliser Lumina !
        { remainingTime > 0 && <> ({ remainingTime } s)</> }
      </Button>
    </div>
  );
};

export default SuccessScreen;
