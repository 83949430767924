import React, { useEffect, useState } from 'react';
import { Layout, Divider } from 'antd';
import { TeamOutlined, UserOutlined, TagOutlined } from "@ant-design/icons";
import { THEME_COLOR } from 'configs/AppConfig';
import { NAV_TYPE_TOP } from 'constants/ThemeConstant';
import utils from 'utils';

const { Header } = Layout;
const url = {
  PROFILE: '/mon-profil',
  PRODUCT: '/mes-produits',
  CLIENT: '/mes-clients',
  HOME: '/'
};

/**
 * HeaderNav
 * @param {Object} navType - ?
 * @param {Object} headerNavColor - ?
 */
export const HeaderNav = (props) => {
  const [hideTabName, setHideTabName] = useState(false);
  const { navType, headerNavColor } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  let selectedMenu = url.HOME;
  const style = {
    backgroundColor: headerNavColor,
    display: 'flex',
    justifyContent: 'space-between',
    color: THEME_COLOR.darkest,
    fontSize: 'large',
  };
  const logoStyle = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '32px',
      cursor: 'pointer'
    },
    svg: {
      maxWidth: '40px'
    },
    image: {
      maxWidth: '100px',
      marginLeft: '32px'
    }
  };
  const menuStyle = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '32px',
    },
    profile: {
      width: '200px',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '24px',
      alignItems: 'center',
      background: '#F9FAFB',
      borderRadius: '8px',
      color: THEME_COLOR.darker,
      cursor: 'pointer'
    },
    profileSelected: {
      width: '200px',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '24px',
      alignItems: 'center',
      background: THEME_COLOR.darker,
      borderRadius: '8px',
      color: '#F9FAFB',
      cursor: 'pointer'
    },
    default: {
      width: '160px',
      height: '70px',
      textAlign: 'center',
      color: '#D1D5DB',
      cursor: 'pointer'
    },
    available: {
      width: '160px',
      textAlign: 'center',
      color: THEME_COLOR.default,
      cursor: 'pointer'
    },
    selected: {
      width: '160px',
      textAlign: 'center',
      color: THEME_COLOR.darker,
      borderBottom: `4px ${THEME_COLOR.darker} solid`,
      cursor: 'pointer'
    }
  };

  const getSelectedMenu = () => {
    const currentUrl = window.location.href;

    if (currentUrl.indexOf(url.PRODUCT) !== -1) {
      selectedMenu = url.PRODUCT;
    } else if (currentUrl.indexOf(url.CLIENT) !== -1) {
      selectedMenu = url.CLIENT;
    } else if (currentUrl.indexOf(url.PROFILE) !== -1) {
      selectedMenu = url.PROFILE;
    }
  };

  const mode = () => {
    if(!headerNavColor) {
      return (utils.getColorContrast('#ffffff'));
    }
    return (utils.getColorContrast(headerNavColor));
  }

  const navMode = mode();
  getSelectedMenu();

  useEffect(() => {
    getSelectedMenu();
    if (window.location.pathname.includes('/mes-clients')) {
      setHideTabName(true);
    }
  }, [ selectedMenu, window.location.pathname ]);

  return (
    <div>
      <Header className={`app-header ${navMode}`}>
        <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`} style={style}>
          <div
            style={logoStyle.container}
            onClick={() => window.location = '/'}
          >
            <img className='img-fluid' src='/logo.svg' alt='' style={logoStyle.svg} />
            <img className='img-fluid' src='/img/logo.png' alt='' style={logoStyle.image} />
          </div>
          <div style={menuStyle.container}>
            <span
              style={selectedMenu === url.CLIENT ? menuStyle.selected : menuStyle.available}
              onClick={() => window.location = '/mes-clients'}
            >
              <TeamOutlined />
              { !hideTabName && <>{' '}Mes Clients</> }
            </span>
            <span
              style={selectedMenu === url.PRODUCT ? menuStyle.selected : menuStyle.available}
              onClick={() => window.location = '/mes-produits'}
            >
              <TagOutlined />
              { !hideTabName && <>{' '}Mes Produits</> }
            </span>
            <Divider type='vertical' style={{ height: '35%', border: `1px solid ${THEME_COLOR.lightest}50`, margin: '0px 32px' }}/>
            <span
              style={selectedMenu === url.PROFILE ? menuStyle.profileSelected : menuStyle.profile}
              onClick={() => window.location = '/mon-profil'}
            >
              <UserOutlined />
              <>{' '}Mon Profil</>
              { selectedMenu === url.PROFILE ? <img src='/camera_icon_light.svg' alt='' /> : <img src='/camera_icon.svg' alt='' /> }
            </span>
          </div>
        </div>
      </Header>
    </div>
  );
};

export default HeaderNav;
