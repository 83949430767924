import React from 'react';
import { Spin } from 'antd';

const Loading = () => {
  return (
    <div style={{ textAlign: 'center', margin: '50px' }}>
      <Spin />
    </div>
  );
};

export default Loading;
