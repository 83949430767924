import React, { useState } from 'react';
import { Card, Form, Row, Col, Input, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import photo from 'utils/photo';
import Frame from './Frame';
import Album from './Album';
import Custom from './Custom';

const Edit = (props) => {
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogoUploadSuccess = (result) => {
    setImage(result.data[0].url);
    setLoading(false);
  };

  const handleLogoUploadFailure = (error) => {
    if (error.typeIsIncorrect) {
      notification.error('Format de photo incorrect', 'Vérifiez que le format est bien .png, .jpg ou .jpeg');
    } else {
      notification.error('Impossible d\'upload la photo', 'Veuillez réessayer');
    }
    setLoading(false);
    appLogs.logError({ function: 'handleLogoUploadFailure', error });
  };

  const uploadLogo = (image) => {
    setLoading(true);
    photo.uploadPhoto(image, props.user.jwt, handleLogoUploadSuccess, handleLogoUploadFailure);
  }

  const generateInitialValues = () => {
    const initialValues = {
      dimensions: []
    };

    if (props.product.type === props.type.FRAME) {
      props.product.specs.dimensions.forEach((dimension, index) => {
        initialValues.dimensions.push({
          'size-width': dimension.size.width,
          'size-height': dimension.size.height,
          'price-buy': dimension.price.buy,
          'price-sell': dimension.price.sell,
        });
      });
    } else if (props.product.type === props.type.ALBUM) {
      initialValues['size'] = props.product.specs.size;
      initialValues['price-buy'] = props.product.specs.price.buy;
      initialValues['price-sell'] = props.product.specs.price.sell;
    } else if (props.product.type === props.type.CUSTOM) {
      initialValues['description'] = props.product.specs.description;
      initialValues['price-buy'] = props.product.specs.price.buy;
      initialValues['price-sell'] = props.product.specs.price.sell;
    }
    return (initialValues);
  };

  const handleProductUpdateSuccess = () => {
    const message = {
      DEFAULT: 'Le produit a bien été modifié'
    };

    setLoading(false);
    props.triggerAlert({ show: true, type: 'success', message: message.DEFAULT });
    props.onSave();
  }

  const handleProductUpdateFailure = (error) => {
    const message = {
      DEFAULT: 'Impossible de modifier le produit'
    };

    setLoading(false);
    props.triggerAlert({ show: true, type: 'error', message: message.DEFAULT });
    appLogs.logError({ function: 'handleProductUpdateFailure', error });
  }

  const handleFormSubmit = (values) => {
    const product = props.product;
    product['name'] = values.name;
    product['photo'] = ( image !== '' ? image : props.product.photo);
    product['supplier'] = values.supplier;

    if (product.type === props.type.FRAME) {
      product.specs = { dimensions: [] };
      values.dimensions?.forEach((dimension) => {
        product.specs.dimensions.push({
          size: {
            width: dimension['size-width'],
            height: dimension['size-height']
          },
          price: {
            buy: dimension['price-buy'],
            sell: dimension['price-sell']
          }
        });
      });
    } else if (product.type === props.type.ALBUM) {
      product.specs['size'] = values.size;
      product.specs['price'] = {
        buy: values['price-buy'],
        sell: values['price-sell']
      }
    } else if (product.type === props.type.CUSTOM) {
      product.specs['description'] = values.description;
      product.specs['price'] = {
        buy: values['price-buy'],
        sell: values['price-sell']
      }
    }
    setLoading(true);
    request.putDataWithAuth(`${request.API_ENDPOINT_URL}/products/${product.id}`, product, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleProductUpdateFailure(result));
          }
          return (handleProductUpdateSuccess());
        },
        (error) => handleProductUpdateFailure(error)
      );
    props.onSave();
  };

  return (
    <Card>
      <Form
        name='edit'
        layout='vertical'
        initialValues={{
          name: props.product.name,
          supplier: props.product.supplier,
          ...generateInitialValues()
        }}
        onFinish={handleFormSubmit}
      >
        <Row justify='space-between'>
          <h2>Modification du produit</h2>
          <div>
            <Button type='link' onClick={props.onCancel}>Annuler</Button>
            <Button type='primary' htmlType='submit' loading={loading}>Enregistrer</Button>
          </div>
        </Row>
        <br /><br />
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Nom du produit'>
              <Form.Item name='name'>
                <Input />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Photo'>
            <Upload
              name='avatar'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              beforeUpload={uploadLogo}
              onPreview={photo.handlePreview}
            >
              {
                image !== '' ?
                  <img src={image} alt='avatar' style={{ width: '100px' }} />
                :
                  props.product.photo ?
                    <img src={props.product.photo} alt='avatar' style={{ width: '100px' }} />
                  :
                    <div>
                      <PlusOutlined />
                      <div className='ant-upload-text'>Upload</div>
                    </div>
              }
            </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Fournisseur'>
              <Form.Item name='supplier'>
                <Input />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        { props.product.type === props.type.FRAME && <Frame /> }
        { props.product.type === props.type.ALBUM && <Album /> }
        { props.product.type === props.type.CUSTOM && <Custom /> }
      </Form>
    </Card>
  );
};

export default Edit;
