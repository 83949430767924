/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { HeartFilled } from '@ant-design/icons';
import photo from 'utils/photo';
import {
  BigImageContainer,
  BigImage,
  BigImageSelected,
  LikedIcon
} from '../ImageRow.module.css';

/**
 * OverviewRow
 * @param {Array} imageList - List of images to display
 * @param {Boolean} showPhotoNumber - Wether or not to display photo's file name
 * @param {Callback} isSelected - Callback to know whether an image is selected or not
 * @param {Callback} highlightImage - Callback to highlight an image
 * @param {Callback} onImageSelection - Callback to handle image selection
 */
const OverviewRow = (props) => {
  const [config, setConfig] = useState([]);

  const computeNbLine = () => {
    const n = props.imageList.length;
    let x = 1;

    while (x * (x * 2) < n) {
      x = x + 1;
    }
    console.log('> n : ', n);
    console.log('> x : ', x);
    return (x);
  };

  const createConfig = () => {
    const x = computeNbLine();
    const config = [];

    for (let i = 0; i < props.imageList.length; i++) {
      if (i % (2 * x) === 0) {
        config.push([props.imageList[i]]);
      } else {
        config[config.length - 1].push(props.imageList[i]);
      }
    }
    console.log('> config : ', config);
    setConfig(config);
  };

  useEffect(() => {
    createConfig();
  }, [ props.imageList ]);

  return (
    <div>
      <div>
      {
        config.map((row) => (
          <Row>
            {
              row.map((image) => (
                <Col span={24 / row.length}>
                  <div className={BigImageContainer}>
                    <HeartFilled
                      className={LikedIcon}
                      style={{
                        display: props.showLikedImages && image.isLiked ? 'block' : 'none',
                      }}
                    />
                    <img
                      src={image.photo}
                      id={`photo-${image.id}`}
                      alt={`photo-${image.id}`}
                      draggable={false}
                      className={props.isSelected(image.id) ? BigImageSelected : BigImage}
                      style={{
                        height: `${(window.innerHeight - 200) / config.length - (8 * config.length)}px`,
                      }}
                      onClick={() => props.onImageSelection(image)}
                      onDoubleClick={() => props.highlightImage(image.photo)}
                    />
                  </div>
                  <Row style={{ margin: '8px' }}>
                    <p>{ props.showPhotoNumber && photo.getImageName(image) }</p>
                  </Row>
                </Col>
              ))
            }
          </Row>
        ))
      }
    </div>
    </div>
  );
};

export default OverviewRow;
