import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import photo from 'utils/photo';

/**
 * UploadModal
 * @param {Object} user - Current user object
 * @param {Boolean} show - Control the modal visibility
 * @param {Integer} shootingId - Current shooting's id
 * @param {Callback} onImageCreation - Callback to handle image creation in db
 * @param {Callback} onOk - Callback to handle ok
 * @param {Callback} onCancel - Callback to handle cancel
 */
const UploadModal = (props) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleImageCreationSuccess = (result, filename) => {
    message.success(`Félicitations ! ${filename} a bien été upload.`);
    setIsUploading(false);
    props.onImageCreation(result);
  };

  const handleImageCreationFailure = (error, filename) => {
    appLogs.logError({ function: 'handleImageCreationFailure', error });
    setIsUploading(false);
    message.error(`Impossible d'upload la photo : ${filename}`);
  };

  const createImageInDb = (url, filename) => {
    const image = {
      shootingId: props.shootingId,
      photo: url,
      isLiked: false
    };

    request.postDataWithAuthInline(
      `${request.API_ENDPOINT_URL}/images`,
      image,
      props.user.jwt,
      (result) => handleImageCreationSuccess(result, filename),
      (error) => handleImageCreationFailure(error, filename)
    );
  };

  const handlePhotoUploadSuccess = (result, filename) => {
    createImageInDb(result.data[0].url, filename);
  };

  const handlePhotoUploadFailure = (error, filename) => {
    appLogs.logError({ function: 'handlePhotoUploadFailure', error });
    message.error(`Impossible d'upload la photo : ${filename}`);
    setIsUploading(false);
  };

  const handleCompressionSuccess = (file) => {
    const formData = new FormData();
    const filename = file.name;

    formData.append('files', file);
    setIsUploading(true);
    setTimeout(() => {
      request.postMediaWithAuthInline(
        `${request.API_ENDPOINT_URL}/upload`,
        formData,
        props.user.jwt,
        (result) => handlePhotoUploadSuccess(result, filename),
        (error) => handlePhotoUploadFailure(error, filename)
      );
    }, Math.random() * 1000);
  };

  const handleCompressionFailure = (error, filename) => {
    appLogs.logError({ function: 'handleCompressionFailure', error });
    message.error(`Impossible d'upload la photo : ${filename}`);
    setIsUploading(false);
  };

  return (
    <Modal
      title='Ajouter des photos'
      visible={props.show}
      onOk={props.onOk}
      onCancel={props.onCancel}
    >
      <h2>Importez les photos de la projection</h2>
      <br />
      <Upload.Dragger
        name='file'
        multiple={true}
        accept='.jpg,.png,.svg'
        showUploadList={false}
        customRequest={(info) => {
          photo.compressPhoto(
            info.file,
            (file) => handleCompressionSuccess(file),
            (error, filename) => handleCompressionFailure(error, filename)
          )
        }}
      >
        <p className="ant-upload-drag-icon">
          {isUploading ? <Spin /> : <InboxOutlined />}
        </p>
        <p className="ant-upload-text">Cliquez ou glissez-deposez pour upload</p>
        <p className="ant-upload-hint">
          Uploadez une ou plusieurs photos en même temps. Seul les .png, .jpg et .jpeg sont authorisés
        </p>
      </Upload.Dragger>
    </Modal>
  );
};

export default UploadModal;
