import React, { useState } from 'react';
import { Card, Form, Input, Row, Col } from 'antd';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import { Container } from './ClientInfo.module.css';

/**
 * ClientInfo
 * @param {Object} user - Current user
 * @param {Integer} shootingId - Current shooting id
 * @param {Object} client - Current client
 * @param {Object} shooting - Current shooting
 * @param {Callback} onClientInfoUpdate - Callback to handle client info update
 */
const ClientInfo = (props) => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleClientInfoCreationSuccess = (result) => {
    props.shooting.clientInfo = result;
    notification.success('Félicitations !', 'Les informations ont bien été enregistrées');
    props.onClientInfoUpdate();
    setLoading(false);
  };

  const handleClientInfoCreationFailure = (error) => {
    notification.error('Impossible d\'enregistrer les modifications', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleClientInfoCreationFailure', error });
    setLoading(false);
  };

  const updateClientInfo = (clientInfo) => {
    request.putDataWithAuth(`${request.API_ENDPOINT_URL}/client-infos/${props.shooting.clientInfo.id}`, clientInfo, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientInfoCreationFailure(result));
        }
        return (handleClientInfoCreationSuccess(result));
      })
      .catch((error) => handleClientInfoCreationFailure(error));
  }

  const createClientInfo = (clientInfo) => {
    request.postDataWithAuth(`${request.API_ENDPOINT_URL}/client-infos`, clientInfo, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientInfoCreationFailure(result));
        }
        return (handleClientInfoCreationSuccess(result));
      })
      .catch((error) => handleClientInfoCreationFailure(error));
  }

  const handleFormSubmit = (data) => {
    const clientInfo = {
      shootingId: props.shootingId,
      infos: data
    };

    setLoading(true);
    if (!props.shooting.clientInfo || !props.shooting.clientInfo.id) {
      createClientInfo(clientInfo);
    } else {
      updateClientInfo(clientInfo);
    }
  };

  const clientInfoIsKnown = () => {
    if (!props.shooting.clientInfo) {
      const splittedName = props.client.name.split(' ');

      props.shooting.clientInfo = {
        shootingId: props.shootingId,
        infos: {
          firstname: splittedName.length > 1 ? splittedName[0] : props.client.name,
          lastname: splittedName.length > 1 ? splittedName[1] : '',
          address: ''
        }
      };
      setRefresh(!refresh);
    }
    if (!props.shooting.clientInfo
      || !props.shooting.clientInfo.infos
      || !props.shooting.clientInfo.infos.firstname
      || !props.shooting.clientInfo.infos.lastname
      || !props.shooting.clientInfo.infos.address) {
        return (false);
      }
    return (true);
  };

  return (
    <Card className={Container}>
      <h3>Informations du client</h3>
      <Form
        name='client-info'
        layout='vertical'
        initialValues={{
          firstname: props.shooting.clientInfo?.infos?.firstname,
          lastname: props.shooting.clientInfo?.infos?.lastname,
          address: props.shooting.clientInfo?.infos?.address
        }}
        onFinish={(data) => handleFormSubmit(data)}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name='firstname'>
              <Input placeholder='Jean' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='lastname'>
              <Input placeholder='Dupont' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item name='address'>
              <Input placeholder='42 rue de la photo' />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type={clientInfoIsKnown() ? 'default' : 'primary'}
          loading={loading}
          htmlType='submit'
        >
          Enregistrer
        </Button>
      </Form>
    </Card>
  );
};

export default ClientInfo;
