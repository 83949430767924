import { notification } from 'antd';

const notificationType = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

const openNotificationWithIcon = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const success = (title, description) => {
  openNotificationWithIcon(notificationType.SUCCESS, title, description);
};

const info = (title, description) => {
  openNotificationWithIcon(notificationType.INFO, title, description);
};

const warning = (title, description) => {
  openNotificationWithIcon(notificationType.WARNING, title, description);
};

const error = (title, description) => {
  openNotificationWithIcon(notificationType.ERROR, title, description);
};

const myNotification = { success, info, warning, error };
export default myNotification;

