import request from 'utils/request';
import auth from 'utils/auth';

const user = auth.getUser();

const create = (context, handleSuccess, handleFailure) => {
  request.postDataInline(
    `${request.API_ENDPOINT_URL}/contexts`,
    context,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getById = (contextId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/contexts/${contextId}`,
    {},
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getByClientId = (clientId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/contexts`,
    { clientId },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const update = (context, handleSuccess, handleFailure) => {
  request.putDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/contexts/${context.id}`,
    context,
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const Context = {
  create,
  getById,
  getByClientId,
  update
};

export default Context;
