import React from 'react';
import { Switch, Route, } from 'react-router-dom';
import AuthViews from 'Views/auth-views';

/**
 * AuthLayout
 * @param {Callback} onLogin - Callback to handle login
 * @param {Callback} onSignup - Callback to handle signup
 */
export const AuthLayout = (props) => {
  return (
    <div className='auth-container'>
      <Switch>
        <Route path=''>
          <AuthViews
            onLogin={(jwt, user) => props.onLogin(jwt, user)}
            onSignup={(jwt, user) => props.onSignup(jwt, user)}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default AuthLayout;
