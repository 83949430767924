import React, { useState } from 'react';
import { Divider, Tooltip } from 'antd';
import { HeartOutlined, PlusOutlined } from '@ant-design/icons';
import PriceTag from './PriceTag';
import { ToolbarWrapper, Icon, IconSelected } from './Toolbar.module.css';

/**
 * Handle the Toolbar component
 * @param {Array} pictureList - List of Picture objects
 * @param {Boolean} isLiked - Weather the scene is liked or not
 * @param {Callback} onNewScene - Callback to create a new scene
 * @param {Callback} onLike - update Scene on like click
 * @param {Callback} onUnlike - update Scene on like click
 */
const Toolbar = (props) => {
  const [showPrice, setShowPrice] = useState(false);

  const switchLikeStatus = () => {
    if (!props.isLiked) {
      props.onLike();
    } else {
      props.onUnlike();
    }
  };

  const switchShowStatus = () => {
    setShowPrice(!showPrice);
  };

  return (
    <div>
      <div className={ToolbarWrapper}>
        <Tooltip placement='bottom' title='Aimer la scène'>
          <div
            className={props.isLiked ? IconSelected : Icon}
            onClick={switchLikeStatus}
          >
            <HeartOutlined />
          </div>
        </Tooltip>
        <Tooltip placement='bottom' title='Créer une nouvelle scène'>
          <div
            className={Icon}
            onClick={props.onNewScene}
          >
            <PlusOutlined />
          </div>
        </Tooltip>
        <Divider type='vertical' />
        <Tooltip placement='bottomRight' title='Afficher le prix'>
          <div
            className={showPrice ? IconSelected : Icon}
            onClick={switchShowStatus}
          >
            €
          </div>
        </Tooltip>
      </div>
      {
        showPrice &&
          <PriceTag
            pictureList={props.pictureList}
            totalPrice={props.totalPrice}
          />
      }
    </div>
  );
};

export default Toolbar;
