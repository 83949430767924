import React, { useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import Button from 'components/Button';
import Loading from 'components/Loading';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import { ButtonContainer } from './PurchaseOrderSettings.module.css';

const billingIsEmpty = (profile) => {
  if (
    !profile.billing?.name &&
    !profile.billing?.tva &&
    !profile.billing?.cgv
  ) {
    return (true);
  }
  return (false);
};

/**
 * Handle the purchase order settings
 * @param {Enumerator} state - Weather it is in VIEW or EDIT mode
 * @param {Object} user - User
 * @param {Object} profile - Profile of a given user
 * @param {Boolean} loading - Weather or not the data are retrieved yet
 * @param {Callback} setAlert - Callback to display success and error messages
 */
const PurchaseOrderSettings = (props) => {
  const [state, setState] = useState(billingIsEmpty(props.profile.get()) ? props.state.EDIT : props.state.VIEW);
  const [loading, setLoading] = useState(false);

  const handleBillingUpdateSuccess = (data) => {
    const message = {
      DEFAULT: 'Votre profil a bien été mis à jour !'
    };

    setLoading(false);
    setState(props.state.VIEW);
    props.setAlert({ show: true, type: 'success', message: message.DEFAULT });
    setTimeout(() => props.setAlert({ show: false, type: 'info', message: '' }), 5000);
    props.profile.set(data);
  };

  const handleBillingUpdateFailure = () => {
    const message = {
      DEFAULT: 'Votre profil n\'a pas été mis à jour, veuillez réessayer'
    };

    setLoading(false);
    setState(props.state.VIEW);
    props.setAlert({ show: true, type: 'error', message: message.DEFAULT });
    setTimeout(() => props.setAlert({ show: false, type: 'info', message: '' }), 5000);
    appLogs.logError({ function: 'handleBillingUpdateFailure' });
  };

  const handleFormSubmit = (data) => {
    const tmpProfile = props.profile.get();

    setLoading(true);
    tmpProfile.billing = JSON.stringify(data);
    request.putDataWithAuth(`${request.API_ENDPOINT_URL}/profiles/${props.profile.get().id}`, tmpProfile, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleBillingUpdateFailure());
          }
          return (handleBillingUpdateSuccess(result));
        },
        (error) => handleBillingUpdateFailure()
      )
  };

  if (props.loading) {
    return (
      <Loading />
    );
  }

  return (
    <div>
      <Form
        name='purchase-order-settings'
        layout='vertical'
        initialValues={{
          name: props.profile.get().billing?.name,
          tva: props.profile.get().billing?.tva,
          cgv: props.profile.get().billing?.cgv
        }}
        onFinish={handleFormSubmit}
      >
        <div className={ButtonContainer}>
          { state === props.state.VIEW && <Button type='primary' onClick={() => setState(props.state.EDIT)}>Modifier</Button> }
          {
            state === props.state.EDIT &&
              <>
                <Button type='link' onClick={() => setState(props.state.VIEW)}>Annuler</Button>
                <Button type='primary' htmlType='submit' loading={loading}>Enregistrer</Button>
              </>
          }
        </div>
        <Row>
          <Col span={6}>
            <Form.Item label='Nom'>
              { state === props.state.VIEW && <p>{ props.profile.get().billing?.name }</p> }
              {
                state === props.state.EDIT &&
                  <Form.Item name='name' rules={[{ required: true, message: 'Vous ne pouvez pas laisser ce champ vide' }]}>
                    <Input />
                  </Form.Item>
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item label='TVA'>
              { state === props.state.VIEW && <p>{ props.profile.get().billing?.tva }</p> }
              {
                state === props.state.EDIT &&
                  <Form.Item name='tva' rules={[{ required: true, message: 'Vous ne pouvez pas laisser ce champ vide' }]}>
                    <Input />
                  </Form.Item>
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label='Conditions Générales de Vente'>
              { state === props.state.VIEW && <p>{ props.profile.get().billing?.cgv }</p> }
              {
                state === props.state.EDIT &&
                  <Form.Item name='cgv' rules={[{ required: true, message: 'Vous ne pouvez pas laisser ce champ vide' }]}>
                    <Input.TextArea rows={6} />
                  </Form.Item>
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PurchaseOrderSettings;
