/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import drag from 'utils/drag';
import { Wrapper, ImageWrapper, Image } from './ContextTab.module.css';

const ContextImageList = (props) => {
  const sectionTitles = {
    demo: 'Les intérieurs de démonstration',
    client: 'Les intérieurs envoyés'
  };

  return (
    <>
      <p style={{ fontSize: 'large' }}>{ props.isDemo ? sectionTitles.demo : sectionTitles.client }</p>
      {
        props.list.map((context, index) => (
          <img
            src={props.isDemo ? context.info.url : context.url}
            alt={`photo-${index}`}
            className={Image}
            onDragStart={(event) => drag.setData(event, drag.dataType.CONTEXT, context.info)}
          />
        ))
      }
    </>
  );
};

/**
 * Handle the 'Context' tab
 * @param {Object} contextList - List of all the client contexts
 * @param {Object} demoContextList - List of all the demo contexts
 */
const ContextTab = (props) => {
  return (
    <div className={Wrapper}>
      <div className={ImageWrapper}>
        <ContextImageList list={props.contextList} isDemo={false} />
        <ContextImageList list={props.demoContextList} isDemo={true} />
      </div>
    </div>
  );
};

export default ContextTab;
