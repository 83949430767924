import React from 'react';
import { Switch, Route, } from 'react-router-dom';
import CheckoutViews from 'Views/checkout-views';

/**
 * CheckoutLayout
 */
const CheckoutLayout = () => {
  return (
    <div className='auth-container'>
      <Switch>
        <Route path=''>
          <CheckoutViews />
        </Route>
      </Switch>
    </div>
  );
};

export default CheckoutLayout;
