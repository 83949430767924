import React from 'react';
import Button from 'components/Button';
import { Container, ImageWrapper, MainText, ButtonWrapper } from './SuccessScreen.module.css';

/**
 * SuccessScreen
 * @param {Callback} onStartOver - Callback to handle new photo upload
 */
const SuccessScreen = (props) => {
  return (
    <div className={Container}>
      <img
        src='/img/celebration.png'
        alt='celebration'
        className={ImageWrapper}
      />
      <h1>Félicitations !</h1>
      <p className={MainText}>Votre photo a bien été envoyée</p>
      <p>(Vous pouvez quitter cette page)</p>
      <Button
        type='link'
        className={ButtonWrapper}
        onClick={() => props.onStartOver()}
      >
        Envoyer une autre photo
      </Button>
    </div>
  );
};

export default SuccessScreen;
