import React from 'react';

const SmallScreen = () => {
  return (
    <div
      style={{
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      <div>
        <p>Ce site n'a pas de version mobile</p>
        <p>Veuillez vous connecter sur votre ordinateur</p>
        <br />
        <p>(si vous êtes déjà sur votre ordinateur, veuillez mettre votre navigateur en plein écran)</p>
      </div>
    </div>
  );
};

export default SmallScreen;
