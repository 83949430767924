import React, { useState } from 'react';
import { PauseOutlined, CaretRightOutlined, HeartOutlined, HeartFilled } from '@ant-design/icons';
import WelcomeScreen from './WelcomeScreen';
import { Container, LeftArea, RightArea, FullscreenImage, PlayButton, LikeButton } from './Diaporama.module.css';

const direction = {
  NEXT: 'NEXT',
  PREV: 'PREV'
};

const fadeType = {
  FADE_IN: 'FADE_IN',
  FADE_OUT: 'FADE_OUT'
};

/**
 * Diaporama
 * @param {Boolean} show - Weather isFullScreen is activated in parent component
 * @param {Object} client - Current client
 * @param {Object} settings - Diaporama settings about music and timing
 * @param {Array} selectedImages - List of selected images in parent component
 * @param {Array} imageList - List of all images in parent component
 * @param {Callback} onLike - Callback to handle like in diaporama
 */
const Diaporama = (props) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio());
  const [tid, setTid] = useState(0);
  const imageList = props.selectedImages.length ? props.selectedImages : props.imageList;
  const fadeSpeed = 10;
  const fadeStep = 0.01;
  const playSpeed = props.settings?.timing?.value ? props.settings.timing.value * 1000 : 3000;

  const fadeImage = (type) => {
    const image = document.getElementById('mainPicture');

    return (new Promise((resolve) => {
      try {
        image.style.opacity = (type === fadeType.FADE_OUT ? 1 : 0);

        const tick = () => {
          image.style.opacity = +image.style.opacity + (type === fadeType.FADE_OUT ? -fadeStep : fadeStep);
          if ((type === fadeType.FADE_IN && +image.style.opacity < 1)
            || (type === fadeType.FADE_OUT && +image.style.opacity > 0)) {
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, fadeSpeed);
          }
          if ((type === fadeType.FADE_IN && +image.style.opacity === 1)
            || (type === fadeType.FADE_OUT && +image.style.opacity === 0)) {
            resolve();
          }
        };
        tick();
      } catch (error) {
        resolve();
      }
    }));
  };

  const changeImage = (targetDirection, fade) => {
    if (targetDirection === direction.PREV) {
      setCurrentImage(currentImage => (currentImage - 1 < 0 ? imageList.length - 1 : currentImage - 1));
    } else if (targetDirection === direction.NEXT) {
      if (!fade) {
        setCurrentImage(currentImage => (currentImage + 1 >= imageList.length ? 0 : currentImage + 1));
        return (true);
      }
      fadeImage(fadeType.FADE_OUT)
        .then(() => {
          setCurrentImage(currentImage => (currentImage + 1 >= imageList.length ? 0 : currentImage + 1));
          fadeImage(fadeType.FADE_IN);
        });
    }
  };

  const handleLike = (image) => {
    props.onLike(image);
  };

  const restartMusic = () => {
    audio.currentTime = 0;
    audio.play();
  };

  const pauseMusic = () => {
    audio.pause();
  };

  const stopMusic = () => {
    audio.pause();
    audio.currentTime = 0;
  };

  const playMusic = () => {
    if (!audio.src || audio.src !== props.settings.music.url) {
      audio.src = props.settings.music.url;
    }
    audio.onended = () => {
      restartMusic();
    };
    audio.volume = 1;
    audio.play();
    setAudio(audio);
  };

  const fadeOutAudio = () => {
    let interval = 200;

    const fadeout = setInterval(() => {
      if (audio.volume > 0) {
        audio.volume -= 0.05;
        if (audio.volume < 0.1) {
          audio.volume = 0;
        }
      }
      else {
        clearInterval(fadeout);
        stopMusic();
      }
    }, interval);
  };

  const stopAutoPlay = (timerId) => {
    clearInterval(timerId);
    setIsPlaying(false);
    fadeOutAudio();
  };

  const launchAutoPlay = () => {
    let nbImg = 0;
    const timerId = setInterval(() => {
      nbImg += 1;
      if (nbImg >= imageList.length - 1) {
        stopAutoPlay(timerId);
      }
      changeImage(direction.NEXT, true);
    }, playSpeed);

    setTid(timerId);
  };

  const handlePlayPause = (newState) => {
    if (newState === true) {
			setShowWelcome(false);
      setIsPlaying(true);
      launchAutoPlay();
      playMusic();
    } else {
      setIsPlaying(false);
      pauseMusic();
      clearInterval(tid);
    }
  };

  if (!props.show) {
    if (currentImage !== 0 || isPlaying) {
      setCurrentImage(0);
      setIsPlaying(false);
      clearInterval(tid);
      stopMusic();
    }

    return (
      <div id='diaporama-container'></div>
    );
  }

  return (
    <div id='diaporama-container' className={Container}>
      <div className={LeftArea} onClick={() => changeImage(direction.PREV, false)}></div>
      <div className={RightArea} onClick={() => changeImage(direction.NEXT, false)}></div>
      {
        isPlaying ?
          <PauseOutlined className={PlayButton} onClick={() => handlePlayPause(false)} />
        :
          <CaretRightOutlined className={PlayButton} onClick={() => handlePlayPause(true)} />
      }
      {
        imageList[currentImage].isLiked ?
          <HeartFilled className={LikeButton} onClick={() => handleLike(imageList[currentImage])} />
        :
          <HeartOutlined className={LikeButton} onClick={() => handleLike(imageList[currentImage])} />
      }
			{
				showWelcome ?
						<WelcomeScreen client={props.client} settings={props.settings} />
					:
						<img
							id='mainPicture'
							src={imageList[currentImage].photo}
							alt='prise-de-vue'
							className={FullscreenImage}
						/>
			}
    </div>
  );
};

export default Diaporama;
