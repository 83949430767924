import React from 'react';
import drag from 'utils/drag';
import { Wrapper, Row, LeftPart, RightPart, Image } from './FrameTab.module.css';

/**
 * Handle the 'Frame' tab
 * @param {Array} frameList - List of all the frames for a given user
 */
const FrameTab = (props) => {
  const defaultFramePhoto = 'https://lumina-images.s3.eu-west-3.amazonaws.com/white_f10ee2663d.jpeg';

  return (
    <div className={Wrapper}>
      {
        props.frameList.map((frame) => (
          <div className={Row}>
            <div className={LeftPart}>
              <p>{frame.name}</p>
            </div>
            <div className={RightPart}>
              <img
                src={frame.photo ? frame.photo : defaultFramePhoto}
                alt={frame.name}
                className={Image}
                style={
									frame.type === 'collection' ?
										{ width: '100%', height: '100%' }
									:
										frame.specs.dimensions[0].size.width > frame.specs.dimensions[0].size.height ?
											{ width: '100%', height: `${(frame.specs.dimensions[0].size.height / frame.specs.dimensions[0].size.width) * 96}px` }
										:
											{ width: `${(frame.specs.dimensions[0].size.width / frame.specs.dimensions[0].size.height) * 100}%`, height: '100%' }
                }
                onDragStart={(event) => {
									if (frame.type === drag.dataType.FRAME) {
										drag.setData(event, drag.dataType.FRAME, { currentDimension: 0, ...frame })
									} else if (frame.type === drag.dataType.COLLECTION) {
										drag.setData(event, drag.dataType.COLLECTION, { currentDimension: 0, ...frame })
									}
								}}
              />
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default FrameTab;
