import React, { useState } from 'react';
import { Modal, Row } from 'antd';
import { THEME_COLOR } from 'configs/AppConfig'
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import email from 'utils/email';

/**
 * ConfirmAccountModal
 * @param {Object} user - Current user
 */
const ConfirmAccountModal = (props) => {
  const [remainingTime, setRemainingTime] = useState(60);

  const sendEmailSuccess = () => {
    notification.success('Félicitations !', 'Un nouvel email vient d\'être envoyé');
    setRemainingTime(60);
  };

  const sendEmailFailure = (error) => {
    notification.error('Impossible d\'envoyer le mail à nouveau', 'Veuillez vérifier votre boite mail à nouveau');
    appLogs.logError({ function: 'sendEmailFailure', error });
  };

  const resendWelcomeEmail = () => {
    email.sendEmailConfirmation(
      () => sendEmailSuccess(),
      (error) => sendEmailFailure(error)
    );
  };

  if (remainingTime > 0) {
    setTimeout(() => setRemainingTime(remainingTime - 1), 1000);
  }

  return (
    <Modal
      visible={true}
      footer={[]}
    >
      <h2>Validez votre compte</h2>
      <br />
      <p style={{ color: THEME_COLOR.darker }}>Un email a été envoyé à l'adresse :</p>
      <h3 style={{ color: THEME_COLOR.default, textAlign: 'center' }}>{props.user.usr.email}</h3>
      <br />
      <p style={{ color: THEME_COLOR.darker }}>
        Cliquez sur le lien qui vous a été envoyé par email
        <br />
        (pensez à vérifier dans le dossier des spams)
      </p>
      <br />
      <Row justify='center'>
        <Button
          type={remainingTime > 0 ? 'default' : 'primary'}
          disabled={remainingTime > 0 ? true : false}
          onClick={() => resendWelcomeEmail()}
        >
          Renvoyer le mail de confirmation { remainingTime > 0 && <> ({ remainingTime } s)</> }
        </Button>
      </Row>
    </Modal>
  );
};

export default ConfirmAccountModal;
