import React, { useState, useEffect } from 'react';
import { Modal, Select } from 'antd';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';

const { Option } = Select;

const productType = {
  FRAME: 'frame',
  ALBUM: 'album',
  CUSTOM: 'custom'
};

const productTypeToName = {
  frame: 'Cadre',
  album: 'Album',
  custom: 'Personnalisé'
};

/**
 * OrderModal
 * @param {Boolean} show - Weather or not to show the modal
 * @param {Object} user - Current user
 * @param {Array} selectedImages - List of selected images
 * @param {Boolean} loading - Weather or not the order in created in db
 * @param {Callback} onOrder - Callback to handle 'Commander' push
 * @param {Callback} onCancel - Callback to handle 'Annuler' push or outside click
 */
const OrderModal = (props) => {
  const [productList, setProductList] = useState([]);
  const [choosenProduct, setChoosenProduct] = useState({});
  const [frameConfigIndex, setFrameConfigIndex] = useState(-1);
  const [refresh, setRefresh] = useState(false);
  const footer = [
    <Button
      type='default'
      onClick={() => {
        setChoosenProduct({});
        setFrameConfigIndex(-1);
        props.onCancel();
      }}
    >
      Annuler
    </Button>,
    <Button
      type='primary'
      onClick={() => {
        setChoosenProduct({});
        setFrameConfigIndex(-1);
        props.onOrder(choosenProduct, frameConfigIndex);
      }}
      disabled={
        (!Object.keys(choosenProduct).length)
        || (choosenProduct.type === productType.FRAME && frameConfigIndex === -1)
      }
      loading={props.loading}
    >
      Commander
    </Button>,
  ];


  const updateChoosenProduct = (productId) => {
    setChoosenProduct(productList.find((product) => product.id === productId));
    setFrameConfigIndex(-1);
    setRefresh(!refresh);
  };

  const handleProductAccessSuccess = (result) => {
    setProductList(result);
    setRefresh(!refresh);
  };

  const handleProductAccessFailure = (error) => {
    notification.error('Impossible d\'accéder à vos produits', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleProductAccessFailure', error });
  };

  useEffect(() => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/products`, { email: props.user.usr.email }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleProductAccessFailure(result));
        }
        return (handleProductAccessSuccess(result));
      })
      .catch((error) => handleProductAccessFailure(error));
  }, []);

  if (!props.show) {
    return (<></>);
  }

  return (
    <Modal
      title={`Commander ${props.selectedImages.length} produit${props.selectedImages.length > 1 ? 's' : ''}`}
      visible={props.show}
      onOk={props.onOrder}
      onCancel={props.onCancel}
      footer={footer}
    >
      <p>Choisissez le produit</p>
      <Select defaultValue='default' style={{ width: 400 }} onChange={(value) => updateChoosenProduct(value)}>
        <Option value='default' disabled>Produit associé</Option>
        {
          productList.map((product) => (
            <Option value={product.id}>{ productTypeToName[product.type] } - { product.name }</Option>
          ))
        }
      </Select>
      {
        (choosenProduct && choosenProduct.type === productType.FRAME) &&
          <>
            <br /><br />
            <p>Choisissez la taille</p>
            <Select defaultValue={frameConfigIndex} style={{ width: 400 }} onChange={(value) => setFrameConfigIndex(value)}>
              <Option value={-1} disabled>Dimensions</Option>
              {
                choosenProduct.specs.dimensions.map((dimensions, index) => (
                  <Option value={index}>{dimensions.size.width} x {dimensions.size.height} cm</Option>
                ))
              }
            </Select>
          </>
      }
    </Modal>
  );
};

export default OrderModal;
