const getEnv = () => {                                                          
    return ({                                                                     
      API_ENDPOINT_URL: 'https://api.lumina.pics',                                
      STRIPE_PRODUCT: {                                                           
        MONTHLY_SUB_ID: 'price_1PnHTlAGIt9CNzQOicKV4PFE',                         
        ANNUAL_SUB_ID: 'price_1PnHV7AGIt9CNzQOEf1O0mgJ'                           
      },                                                                          
      STRIPE_COUPON: {                                                            
        MONTHLY_DISCOUNT: 'Xp7U7spb',                                             
        ANNUAL_DISCOUNT: 'USCgPjVo',                                              
        FREE_FULL_ACCESS: 'NqE0l3Ll'                                              
      },                                                                          
    });                                                                           
  };                                                                              
                                                                                  
  export const env = getEnv(); 