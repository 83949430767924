import { env } from 'configs/EnvironmentConfig';
import request from 'utils/request';
import auth from 'utils/auth';

const user = auth.getUser();

const createPortalSession = (customerId, handleSuccess, handleFailure) => {
  request.postDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/stripe/create-portal-session`,
    { customerId },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getProductsId = () => {
  return (env.STRIPE_PRODUCT);
};

const getCouponsId = () => {
  return (env.STRIPE_COUPON);
};

const createCheckoutSession = (stripeProductId, handleSuccess, handleFailure) => {
  const founderList = [
    'contact@lumina.pics',
    'nicolas@lumina.pics',
    'tferry@berkeley.edu',
    'demo@lumina.pics'
  ];
  const betaList = [
    'damien.blaise@mac.com',
    'commande@foteli.com',
    'photogz83@gmail.com',
    'contact@lauresophie.fr',
    'contact@fabien-marcorelles.fr',
    'info@galerie-foteli.be',
    'cedricderbaise@gmail.com'
  ];

  const getCouponId = () => {
    if (!founderList.includes(user.usr.email) && !betaList.includes(user.usr.email)) {
      return (undefined);
    }
    if (founderList.includes(user.usr.email)) {
      return (getCouponsId().FREE_FULL_ACCESS);
    }
    if (stripeProductId === getProductsId().MONTHLY_SUB_ID) {
      return (getCouponsId().MONTHLY_DISCOUNT);
    } else if (stripeProductId === getProductsId().ANNUAL_SUB_ID) {
      return (getCouponsId().ANNUAL_DISCOUNT);
    }
    return (undefined);
  };

  request.postDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/stripe/create-checkout-session`,
    {
      stripeProductId: stripeProductId,
      stripeCouponId: getCouponId(),
      user_email: user.usr.email
    },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const createPaymentIntent = (planId, handleSuccess, handleFailure) => {
  request.postDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/stripe/create-payment-intent`,
    { planId },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getProductDetails = (planId, handleSuccess, handleFailure) => {
  request.getDataWithAuthInline(
    `${request.API_ENDPOINT_URL}/stripe/product-details`,
    { planId },
    user.jwt,
    (result) => handleSuccess(result),
    (error) => handleFailure(error)
  );
};

const getPublicStripeKey = () => {
  return (
    request.getDataWithAuth(
      `${request.API_ENDPOINT_URL}/stripe/public-key`,
      {},
      user.jwt
    )
  );
};

const stripeApi = {
  createPaymentIntent,
  getPublicStripeKey,
  getProductDetails,
  createCheckoutSession,
  getProductsId,
  getCouponsId,
  createPortalSession
};

export default stripeApi;
