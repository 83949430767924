import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { Table } from 'antd';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import styles from './PurchaseOrderPDF.module.css';

const productType = {
  FRAME: 'frame',
  ALBUM: 'album',
  CUSTOM: 'custom'
};

const getImageName = (image) => {
  const url = image.photo;
  const lastSlashIndex = url.lastIndexOf('/');
  const lastUnderscoreIndex = url.lastIndexOf('_');
  const lastDotIndex = url.lastIndexOf('.');
  const imageName = url.substring(lastSlashIndex + 1, lastUnderscoreIndex);
  const extension = url.substring(lastDotIndex);
  const fullName = imageName + extension;

  return (fullName);
};

/**
 * PurchaseOrderPDF
 * @param {Object} user - Current user
 * @param {Object} shooting - Current shooting
 * @param {Callback} onCancel - Callback to hanlde outside modal click
 */
const PurchaseOrderPDF = (props) => {
  const [profile, setProfile] = useState({ billing: { cgv: '' }});
  const orderColumns = [
    {
      title: 'Nom du produit',
      dataIndex: 'product',
      key: 'product',
      render: (product) => product.name
    },
    {
      title: 'Dimensions',
      dataIndex: 'dimensions',
      key: 'dimensions',
      render: (dimensions) => <>{ dimensions.size && <>{ dimensions.size?.width } x { dimensions.size?.height } cm</> }</>
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Photos',
      dataIndex: 'imageList',
      key: 'imageList',
      render: (imageList) => (
        <div>
          {
            imageList.sort((a, b) => a.photo < b.photo ? -1 : 1).map((image, index) => (
              <>
                <img src={image.photo} alt={index} key={index} style={{ height: 'auto', width: '64px' }} />
                <p>{ getImageName(image) }</p>
              </>
            ))
          }
        </div>
      )
    },
    {
      title: 'Prix',
      dataIndex: '',
      key: '',
      render: (order) => (
        <>{ order.product.type === 'frame' ? order.dimensions.price.sell : order.product.specs.price.sell } €</>
      )
    }
  ];
  const extraColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Quantitée',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Prix HT',
      dataIndex: 'price',
      key: 'price',
      render: (price) => price.ht
    },
    {
      title: 'TVA',
      dataIndex: 'price',
      key: 'price',
      render: (price) => price.tva
    },
    {
      title: 'Prix TTC',
      dataIndex: 'price',
      key: 'price',
      render: (price) => price.ttc
    },
    {
      title: 'Total',
      dataIndex: 'price',
      key: 'price',
      render: (price) => price.ttl
    }
  ];

  const getTotalPrice = () => {
    let total = 0;

    for (const order of props.shooting.orderList) {
      if (order.product.type === productType.FRAME) {
        total += parseFloat(order.dimensions.price.sell);
      } else if (order.product.type === productType.ALBUM) {
        total += parseFloat(order.product.specs.price.sell);
      } else if (order.product.type === productType.CUSTOM) {
        total += parseFloat(order.product.specs.price.sell);
      }
    }
    for (const extra of props.shooting.extraList) {
      total += parseFloat(extra.price.ttl);
    }
    return (total.toFixed(2));
  };

  const handleClick = (event) => {
    if (event.target.id === 'container') {
      props.onCancel();
    }
  }

  const handleProfileAccessSuccess = (result) => {
    setProfile(result[0]);
    window.print();
  };

  const handleProfileAccessFailure = (error) => {
    notification.error('Impossible de récupérer votre profil', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleProfileAccessFailure', error });
  };

  useEffect(() => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/profiles`, { email: props.user.usr.email }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleProfileAccessFailure(result));
        }
        return (handleProfileAccessSuccess(result));
      })
      .catch((error) => handleProfileAccessFailure(error));
  }, []);

  return (
    <Card
      id='container'
      className={styles.ModalContainer}
      onClick={(event) => handleClick(event)}
    >
      <div
        id='preview'
        className={styles.ModalStyle}
        onClick={(event) => handleClick(event)}
      >
        <div className={styles.ModalContent}>
          <div className={styles.Header}>
            <h1 className={styles.Title}>Bon de commande</h1>
            <img
              className={styles.Logo}
              src={props.shooting.visualIdentity?.logo}
              alt='logo'
            />
          </div>
          <div className={styles.Studio}>
            <h2>{ props.shooting.visualIdentity.name }</h2>
            <p>
              { props.shooting.visualIdentity.address }<br />
              { props.shooting.visualIdentity.email }<br />
              { props.shooting.visualIdentity.phone }
            </p>
          </div>
          <div className={styles.ClientInfo}>
          <p>
            { props.shooting.clientInfo?.infos.firstname } { props.shooting.clientInfo?.infos.lastname.toUpperCase() }<br />
            { props.shooting.clientInfo?.infos.address }
          </p>
          </div>
          <div className={styles.Orders}>
            <h2>Les commandes</h2>
            <Table
              pagination={false}
              columns={orderColumns}
              dataSource={props.shooting.orderList}
            />
          </div>
          <div className={styles.Extras}>
            <h2>Les extras</h2>
            {
              !props.shooting.extraList.length ?
                  <p>Pas d'extra</p>
                :
                  <Table
                    pagination={false}
                    columns={extraColumns}
                    dataSource={props.shooting.extraList}
                    />
                }
          </div>
          <div className={styles.PriceTag}>
            <h2>Total</h2>
            <p><b>{ getTotalPrice() } €</b></p>
            <div className={styles.PaiementDetail}>
              <p>Acompte :</p>
              <p>Reste à régler :</p>
              <p>[ . ] Réglé le :</p>
            </div>
            <p>Moyen de paiement : ESP / CB / CHEQUE</p>
          </div>
          <div className={styles.Signature}>
            <div>
              <p><b>Date</b></p>
              <p className={styles.DateTemplate}><span>/</span><span>/</span></p>
            </div>
            <div>
              <p><b>Signature</b></p>
              <p>.</p>
            </div>
          </div>
          <div className={styles.CGV}>
            <p><b>Conditions Générales de Vente</b></p>
            <p>{ profile.billing.cgv }</p>
          </div>
        </div>
      </div>
    </Card>
  )
};

export default PurchaseOrderPDF;
