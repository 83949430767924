import React, { useState, useEffect } from 'react';
import { THEME_COLOR } from 'configs/AppConfig';
import { PriceTagWrapper } from './PriceTag.module.css';

/**
 * Handle the Price Tag card's display
 * @param {Array} pictureList - List of Picture objects
 * @param {} totalPrice -
 */
const PriceTag = (props) => {
  const [scenePrice, setScenePrice] = useState(0);

  const computeScenePrice = () => {
    let price = 0;

    props.pictureList.forEach((picture) => {
      if (picture.show) {
        price += parseFloat(picture.frame.specs.dimensions[picture.frame.currentDimension].price.sell);
      }
    });
    setScenePrice(price.toFixed(2));
  };

  useEffect(() => {
    computeScenePrice();
  });

  return (
    <div className={PriceTagWrapper}>
      <p
        style={{
          color: THEME_COLOR.darkest,
          fontSize: 'large',
          fontWeight: 'bold'
        }}
      >
        Tarification
      </p>
      <div>
        <p
          style={{
            color: THEME_COLOR.darkest,
            marginBottom: '0'
          }}
        >
          Prix de la scène
        </p>
        <p
          style={{
            color: THEME_COLOR.darkest,
            fontSize: 'large',
            marginBottom: '0'
          }}
        >
          {scenePrice} €
        </p>
      </div>
    </div>
  );
};

export default PriceTag;
