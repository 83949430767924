import React, { useState } from 'react';
import { Card, Alert, Row, Col } from 'antd';
import { Form, Input } from 'antd';
import Button from 'components/Button';
import { THEME_COLOR } from 'configs/AppConfig';
import request from 'utils/request';

const backgroundStyle = {
  backgroundColor: THEME_COLOR.lightest,
};

const ForgottenPassword = (props) => {
  const [alert, setAlert] = useState({ show: false, type: 'info', message: '' });
  const alertStyle = { display: `${ alert.show ? '' : 'none' }` };

  const sendResetRequest = (data) => {
    setAlert({ show: true, type: 'success', message: 'Le mail de réinitialisation a bien été envoyé ! (pensez à vérifer vos spams)' });
    setTimeout(() => setAlert({ show: false, type: 'info', message: '' }), 10000);
    request.postData(`${request.API_ENDPOINT_URL}/auth/forgot-password`, data);
  };

  return (
    <div className='h-100' style={backgroundStyle}>
      <div className='container d-flex flex-column justify-content-center h-100'>
        <Row justify='center'>
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className='my-4'>
                <Alert message={alert.message} type={alert.type} showIcon style={alertStyle} />
                <div className='text-center'>
                  <img className='img-fluid' src='/img/logo.png' alt='' style={{ maxWidth: '100px' }} />
                  <p style={{ color: THEME_COLOR.default, cursor: 'pointer' }} onClick={props.onComeback}>Revenir à l'écran de connexion</p>
                </div>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Form layout='vertical' name='forget-form' onFinish={sendResetRequest}>
                      <Form.Item name='email' label='Email'>
                        <Input placeholder='prenom.nom@email.com' />
                      </Form.Item>
                      <Form.Item>
                        <Button type='primary' htmlType='submit' block>
                          Réinitialiser mon mot de passe
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgottenPassword;
